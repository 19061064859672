import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/parties.css"
import Swal from "sweetalert2";

function  Add_party() {
    const ID = Cookies.get("user_id");
    const navigate = useNavigate();
  
    var currentDate = new Date();
    var formattedDate = currentDate.toISOString().slice(0, 10);

    const [PartyName,  setPartyName] = useState("");
    const [date,  setDate] = useState(formattedDate);
  
    const [isCreditVisible, setIsCreditVisible] = useState(false);

    const [supplyState, setSupplyState] = useState("");
    const [gstIn, setGstin] = useState("");

    const [mobile, setMobile] = useState("");
 

    const [gstType, setGstType] = useState("");

    const [billingAddress, setBillingAddress] = useState("");
    const [email, setEmail] = useState("");
    const [oBal, setOBal] = useState(0);
    const [creditLimit, setCreditLimit] = useState(0);
 
    

  const handleSubmit = async (e, shouldRedirect = true) => {
      if (e) e.preventDefault();
    
   
      
  const partyNameWarning = document.getElementById("partyNameWarning");
  const phoneWarning = document.getElementById("phoneWarning");
  const gsttype_warning= document.getElementById("gsttype_warning");
  const gstinWarning = document.getElementById("gstinWarning");
  const emailWarning = document.getElementById("emailWarning");
  const supplyStateWarning = document.getElementById("supplyStateWarning");

  
  if (partyNameWarning) partyNameWarning.innerText = "";
  if (phoneWarning) phoneWarning.innerText = "";
  if (gsttype_warning) gsttype_warning.innerText = "";
  if (gstinWarning) gstinWarning.innerText = "";
  if (emailWarning) emailWarning.innerText = "";
  if (supplyStateWarning) supplyStateWarning.innerText = "";


 
  let isValid = true;

 
  if (!PartyName.trim()) {
    if (partyNameWarning) {
      partyNameWarning.innerText = "Party Name is required.";
      partyNameWarning.style.color = "red";
    }
    isValid = false;
  }
 
if (!email.trim()) {
  if (emailWarning) {
    emailWarning.innerText = "Email is required.";
    emailWarning.style.color = "red";
  }
  isValid = false; 
} else {
 
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!emailPattern.test(email.trim())) {
    if (emailWarning) {
      emailWarning.innerText = "Please match the email format!";
      emailWarning.style.color = "red";
    }
    isValid = false;
    return; 
  } else {
    
    if (emailWarning) {
      emailWarning.innerText = "";
    }
  }
}


  if (!gstType.trim()) {
    if (gsttype_warning) {
      gsttype_warning.innerText = "GST Type is required.";
      gsttype_warning.style.color = "red";
    }
    isValid = false;
  }
  

if (!mobile.trim()) {
  if (phoneWarning) {
    phoneWarning.innerText = "Phone Number is required.";
    phoneWarning.style.color = "red";
  }
  isValid = false; 
} else {
 
  if (mobile.trim().length !== 10 || !/^\d+$/.test(mobile.trim())) {
    if (phoneWarning) {
      phoneWarning.innerText = "Please provide a valid 10-digit number.";
      phoneWarning.style.color = "red";
    }
    isValid = false;
    return; 
  } else {
  
    if (phoneWarning) {
      phoneWarning.innerText = "";
    }
  }
}

  
  if (!supplyState.trim()) {
    console.log("Supply state is empty!");
    if (supplyStateWarning) {
      supplyStateWarning.innerText = "Supply State is required.";
      supplyStateWarning.style.color = "red";
    }
    isValid = false;
  }

  
  if (gstType !== "Unregistered/Consumers" && gstType !== "" && !gstIn.trim()) {
    if (gstinWarning) {
      gstinWarning.innerText = "GSTIN is required.";
      gstinWarning.style.color = "red";
    }
    isValid = false;
  }
  if (gstType !== "Unregistered/Consumers" && gstType !== "" && !gstIn.trim()) {
    if (gstinWarning) {
      gstinWarning.innerText = "GSTIN is required.";
      gstinWarning.style.color = "red";
    }
    isValid = false; 
  } else if (gstIn) { 
   
   const gstInPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]\d{1}Z\d{1}$/;
    if (!gstInPattern.test(gstIn.trim())) {
      console.log("Invalid GSTIN format.");
      if (gstinWarning) {
        gstinWarning.innerText = "Please match the GSTIN format!";
        gstinWarning.style.color = "red";
      }
      isValid = false;
      return; 
    } else {
      
      if (gstinWarning) {
        gstinWarning.innerText = "";
      }
    }
  }
  
 
  if (!isValid) {
    return;
  }

  

      var dt = {
        Id: ID,
        party_name:PartyName,
        email:email,
        phone_number:mobile,
        gst_type:gstType,
        gstin:gstIn,
        opening_balance:oBal,
        credit_limit:creditLimit,
        date:date,
        to_pay:toPay,
        to_recieve:toReceive,
        billing_address:billingAddress,
        state:supplyState


      };
  
      axios
        .post(`${config.base_url}/createNewparty/`, dt)
        .then((res) => {
          console.log("ITM RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "party Added",
            });

            if (shouldRedirect) {
              
              const newPartyId = res.data.id;
              navigate(`/view_party/${newPartyId}`);
            } else {
              resetForm();
            }
          
          }
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
  
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    const resetForm = () => {
      setPartyName("");
      setGstin("");
      setMobile("");
      setGstType("");
      setSupplyState("");
      setBillingAddress("");
      setEmail("");
      setCreditLimit(0);
      setDate(formattedDate);
      setOBal(0);
      setToReceive(false)
      setToPay(true)
      setIsCreditVisible(false);
    };

    function checkPartyName(PartyName) {
      const warningElement = document.getElementById("partyNameWarning");
    
  
      if (PartyName.trim() === "") {
        warningElement.innerText = ""; 
        return; 
      }
    
      const params = {
        Id: ID,
        partyname: PartyName.trim(),
      };
    
      axios
        .get(`${config.base_url}/party_namecheck/`, { params })
        .then((res) => {
          if (res.data.success === false) {
            console.log(res.data.message);
            warningElement.innerText = res.data.message;
            warningElement.style.color = "red"; 
          } else {
            console.log("Party name is valid.");
            warningElement.innerText = "Valid";
            warningElement.style.color = "green"; 
          }
        })
        .catch((error) => {
          console.error("Error validating party name:", error);
          warningElement.innerText =
            "An error occurred during validation. Please try again.";
          warningElement.style.color = "red"; 
        });
    }
    
    function checkPartyPhone(phone) {
      const phoneWarning = document.getElementById("phoneWarning");
    
     
      phoneWarning.innerText = "";
      phoneWarning.style.color = ""; 
    
   
      if (phone.trim() === "") {
        return; 
      }
    
 
      if (phone.trim().length !== 10 || !/^\d+$/.test(phone.trim())) {
        console.log("Invalid phone number length or format.");
        phoneWarning.innerText = "Please provide a valid 10-digit number.";
        phoneWarning.style.color = "red"; 
        return;
      }
    
      const params = {
        Id: ID, 
        phone: phone.trim(),
      };
    
      axios
        .get(`${config.base_url}/party_phonecheck/`, { params })
        .then((res) => {
          if (res.data.success === false) {
            console.log(res.data.message);
            phoneWarning.innerText = res.data.message;
            phoneWarning.style.color = "red"; 
          } else {
            console.log("Phone number is valid.");
            phoneWarning.innerText = "Valid";
            phoneWarning.style.color = "green"; 
          }
        })
        .catch((error) => {
          console.error("Error validating phone number:", error);
          phoneWarning.innerText =
            "An error occurred during validation. Please try again.";
          phoneWarning.style.color = "red"; 
        });
    }
    
    
    function checkPartyEmail(email) {
      const emailWarning = document.getElementById("emailWarning");
      if (email.trim() === "") {
        return; 
      }
    
    
      emailWarning.innerText = "";
      emailWarning.style.color = ""; 
    
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
      if (!emailPattern.test(email.trim())) {
        console.log("Invalid email format.");
        emailWarning.innerText = "Please match the email format!";
        emailWarning.style.color = "red"; 
        return;
      }
    
      const params = {
        Id: ID, 
        email: email.trim(),
      };
    
      axios
        .get(`${config.base_url}/party_emailcheck/`, { params })
        .then((res) => {
          if (res.data.success === false) {
            console.log(res.data.message);
            emailWarning.innerText = res.data.message;
            emailWarning.style.color = "red"; 
          } else {
            console.log("Email is valid.");
            emailWarning.innerText = "Valid";
            emailWarning.style.color = "green"; 
          }
        })
        .catch((error) => {
          console.error("Error validating email:", error);
          emailWarning.innerText =
            "An error occurred during validation. Please try again.";
          emailWarning.style.color = "red"; 
        });
    }
    
    
    function checkGSTIN(gstIn, gstType) {
      const gstinWarning = document.getElementById("gstinWarning");
    
   
      if (gstIn.trim() === "") {
        gstinWarning.innerText = ""; 
        gstinWarning.style.color = ""; 
        return; 
      }
    
  
      gstinWarning.innerText = "";
      gstinWarning.style.color = "";
    
      if (gstIn.trim().length < 15) {
        console.log("Invalid GSTIN format: Too short.");
        gstinWarning.innerText = "Invalid GSTIN format!";
        gstinWarning.style.color = "red";
        return;
      }
    
      
      const gstInPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]\d{1}Z\d{1}$/;
    
      if (!gstInPattern.test(gstIn.trim())) {
        console.log("Invalid GSTIN format.");
        gstinWarning.innerText = "Please match the GSTIN format!";
        gstinWarning.style.color = "red";
        return;
      }
    
      
      const params = {
        Id: ID,
        gstintype: gstType,
        gstin: gstIn.trim(),
      };
    
      axios
        .get(`${config.base_url}/party_gstcheck/`, { params })
        .then((res) => {
          if (res.data.success === false) {
            console.log(res.data.message);
            gstinWarning.innerText = res.data.message;
            gstinWarning.style.color = "red";
          } else {
            console.log("GSTIN is valid.");
            gstinWarning.innerText = "Valid";
            gstinWarning.style.color = "green";
          }
        })
        .catch((error) => {
          console.error("Error validating GSTIN:", error);
          gstinWarning.innerText =
            "An error occurred during validation. Please try again.";
          gstinWarning.style.color = "red";
        });
    }
    
  
    const handleNext = () => {
      setIsCreditVisible(true);
    };
  
    const handlePrevious = () => {
      setIsCreditVisible(false);
    };
    const [toPay, setToPay] = useState(true); 
    const [toReceive, setToReceive] = useState(false);
    
    const handleToPayChange = () => {
      setToPay(true);
      setToReceive(false);
    };
    
    const handleToReceiveChange = () => {
      setToPay(false);
      setToReceive(true); 
    };
    

      return (
        <div className="container-scroller " style={{ background: "rgb(130, 144, 199)" }}>
          <Navbar />
          <div className="container-fluid page-body-wrapper d-flex">
            <Sidebar />
            <div className="main-panel p-4 px-3 py-2" >
              <div className="content-wrapper">
                <div className="bs p-4 rounded mb-5" style={{ backgroundColor: "#3d4465" }}>
                  <h2 className="mt-3 text-white">ADD PARTY</h2>
                  <div 
                    id="main_form_div"
                    style={{ backgroundColor: "white", padding: "3%", borderRadius: "8px" }}
                  >
                   
                    <div className="row">
                      <div className="col-md-4 add-party-page mt-1">
                        <label htmlFor="partyname" style={{ color: "black" }}>Party Name</label>
                        <input
                          type="text"
                          className="form-control border-secondary"
                          id="partyname"
                          name="partyname"
                          value={PartyName}
                          onChange={(e) => setPartyName(e.target.value)}
                          onInput={(e) => checkPartyName(e.target.value)}
                          style={{ color: "black" }}
                          required
                        />
                         <div className="m-2" id="partyNameWarning"></div>
                      </div>
    
                      <div className="col-md-4 add-party-page mb-3">
                        <label className="col-form-label" style={{ color: "black" }}>GSTIN</label>
                        <input
                          type="text"
                          className="form-control border-secondary"
                          id="gstIn"
                          name="gstIn"
                          value={gstIn}
                          onChange={(e) => setGstin(e.target.value)}
                          onInput={(e) => checkGSTIN(e.target.value, gstType)}
                          style={{ color: "black" }}
                          pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                          placeholder="29APPCK7465F1Z1"
                          title="GST Number: 11AAAAA1111A1ZA"
                          required
                        />
                          <div className="m-2 " id="gstinWarning"></div>
                      </div>
    
                      <div className="col-md-4 add-party-page mb-3">
                        <label className="col-form-label" htmlFor="partyphno" style={{ color: "black" }}>Mobile</label>
                        <input
                          type="tel"
                          className="form-control border-secondary"
                          id="partyphno"
                          name="partyphno"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          onInput={(e) => checkPartyPhone(e.target.value)}
                          style={{ color: "black" }}
                          required
                        />
                      <div className="m-2" id="phoneWarning"></div>
                      </div>

                    </div>
    
                  
                    <div>
                      <hr style={{ padding: 0, margin: 0 }} />
                      <div style={{ backgroundColor: "rgb(120, 121, 196)" }} className="p-3 pb-0 mb-0">
                        <div className="row pb-0 mb-0">
                          <div
                            id="gsthead"
                            className={`col text-center pb-2 ${!isCreditVisible ? 'active-tab' : ''}` }
                            onClick={() => setIsCreditVisible(false)}
                            style={{
                              cursor: 'pointer',
                              borderBottom: !isCreditVisible ? '3px solid rgba(0, 0, 0, 0.548)' : 'none', 
                            }}
                          >
                            <a className="fw-bolder fs-4"  style={{color: "white",fontWeight: "bold",fontSize:"22px" ,color:  isCreditVisible ? "white" : "black" }}>
                              GST & ADDRESS</a>
                          </div>
                          <div
                            id="credithead"
                            className={`col text-center pb-2 ${isCreditVisible ? 'active-tab' : ''}`}
                            onClick={() => setIsCreditVisible(true)}
                            style={{
                              cursor: 'pointer',
                              borderBottom: isCreditVisible ? '3px solid rgba(0, 0, 0, 0.548)' : 'none',
                            }}
                          >
                            <a className="fw-bolder fs-4" style={{color: "white",fontWeight: "bold",fontSize:"22px" ,color:  !isCreditVisible ? "white" : "black"}}>CREDIT & BALANCES</a>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    
                    <div className="section" style={{ display: isCreditVisible ? "none" : "block" }}>                      
                      <div className="row">
                       
                        <div className="col-md-6 add-party-page "  >
                          <label className="col-form-label mt-4" style={{ color: "black" }}>GST Type</label>
                          <select
                            className="form-control border-secondary"
                            value={gstType}
                            onChange={(e) => setGstType(e.target.value)}
                            style={{ color: "black" }}
                            id="gstType"
                          >
                            <option value="">Select</option>
                            <option value="Registered Party">Registered Party</option>
                            <option value="Unregistered/Consumers">Unregistered or Consumer</option>
                            <option value="Registered Business or Composition">Registered Business or Composition</option>
                          </select>
                          <div className="m-2" id="gsttype_warning"></div>
    
                          <label className="col-form-label mt-4" style={{ color: "black" }}>Supply State</label>
                          <select
                            className="form-control border-secondary"
                            value={supplyState}
                            onChange={(e) => setSupplyState(e.target.value)}
                            style={{ color: "black" }}
                          >
                           
                            <option value="">Select</option>
                            <option value="Andaman and Nicobar Islads">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Andhra Pradhesh">Andhra Pradhesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman anad Diu">Daman anad Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Pondicherry">Pondicherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Other Territory">Other Territory</option>
                          </select>
                        <div className="m-2" id="supplyStateWarning"></div>
                        </div>
    
                        <div className="col-md-6 add-party-page">
                          <label className="col-form-label mt-4" style={{ color: "black" }}>Billing Address</label>
                          <textarea
                            className="form-control border-secondary"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                            style={{ color: "black" }}
                            rows="6"
                          />
                        </div>
                        <div className="col-md-6 add-party-page mb-3">
                        <label className="col-form-label" htmlFor="partyemail" style={{ color: "black" }}>Email</label>
                        <input
                          type="email"
                          className="form-control border-secondary"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onInput={(e) => checkPartyEmail(e.target.value)}
                          style={{ color: "black" }}
                          required
                        />
                      <div className="m-2" id="emailWarning"></div>
                      </div>
                    
                      <div className="col-md-12 text-start mt-3">
                      <button
                        className="btn "
                        style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" ,fontWeight: "bold",}}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                    </div>
                      </div>

                       <div className="section" style={{ display: isCreditVisible ? "block" : "none" }}>
                      <div className="row">
                      
                      <div className="col-md-6 add-party-page" >
                     <label htmlFor="openbalance" className="col-form-label" style={{ color: "black", marginRight: "10px" }}>
                     Opening Balance &nbsp;&nbsp; - &nbsp;
                    </label>

                      <div className="form-check form-check-inline" style={{ display: "inline-flex", alignItems: "center" }}>
                     <input
                       className="form-check-input"
                       type="radio"
                       name="payradio"
                       id="toPayRadio"
                       checked={toPay}
                       onChange={handleToPayChange}
                       />
                     <label className="form-check-label" htmlFor="toPayRadio" style={{ color: "black", marginLeft: "5px" }}>
                     &nbsp;&nbsp;To&nbsp;Pay
                   </label>
                    </div>

                  <div className="form-check form-check-inline" style={{ display: "inline-flex", alignItems: "center", marginLeft: "10px" }}>
                      <input
                   className="form-check-input"
                   type="radio"
                   name="payradio"
                   id="toReceiveRadio"
                   checked={toReceive}
                    onChange={handleToReceiveChange}
                   />
                  <label className="form-check-label" htmlFor="toReceiveRadio" style={{ color: "black", marginLeft: "5px" }}>
                  To&nbsp;Receive
                </label>
               </div>


                         <input
                       type="number"
                       className="form-control border-secondary"
                        id="openbalance"
                       name="openbalance"
                       value={oBal}
                      onChange={(e) => setOBal(e.target.value)}
                       style={{ color: "black", backgroundColor: "white" }}
                    defaultValue="0"
                    />
                    </div>
    
                        <div className="col-md-6 add-party-page">
                          <label className="col-form-label" style={{ color: "black" }}>Credit Limit</label>
                          <input
                            type="number"
                            className="form-control border-secondary"
                            value={creditLimit}
                            onChange={(e) => setCreditLimit(e.target.value)}
                            style={{ color: "black" }}
                            defaultValue="0"
                          />
                        </div>
    
                        <div className="col-md-6 mt-4 add-party-page">
                          <label className="col-form-label" style={{ color: "black" }}>Date</label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            style={{ color: "black" }}
                          />
                        </div>
                      <div className="col-md-12 text-start mt-5">
                     <div style={{ display: "inline-block", marginRight: "10px" }}>
                           <button onClick={handlePrevious} className="btn fw-bold" style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" ,fontWeight: "bold",}}
                              >
                        Previous
                       </button>
                   </div>
                    <div style={{ display: "inline-block", marginRight: "10px" }}>
                    <button
                   className="btn fw-bold"
                  style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" ,fontWeight: "bold",}}
                onClick={(e) => handleSubmit(e, true)} 
                  >
                 Save
                      </button>
                       </div>
                   <div style={{ display: "inline-block" }}>
                    <button className="btn fw-bold" style={{ backgroundColor: "rgb(61, 68, 101)", color: "white" ,fontWeight: "bold",}}
                   onClick={(e) => handleSubmit(e, false)} 
                      >
                 Save & New
                 </button>
                 </div>
                  </div>
                  </div>
                  </div>


                  

            
    
                   
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
export default Add_party;

