import React, { useState,useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import config from "../../functions/config";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 


function EditDebitNote() {
    const { id } = useParams(); 
  const [cardWidth, setCardWidth] = useState(window.innerWidth > 768 ? "50%" : "90%");
    
const [debitNoteData, setDebitNoteData] = useState({
  billno: '',
  bill_date: '',
  items: [],
  items_under_company:[],
  grandtotal:"" // Initialize as an empty array
});

  const [isPartySelected, setIsPartySelected] = useState(true);
  const [tableData, setTableData] = useState([]); ;

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 
   useEffect(() => {
    const fetchDebitNote = async () => {
      try {
        const response = await axios.get(`${config.base_url}/api/debitnote/${id}/`);
        console.log(response.data)
        setDebitNoteData(response.data); 
        setDebitNoteData({
          ...response.data,
          items_under_company: response.data.company_items,  // Set company_items to items_under_company
        }); // Set the data
        setLoading(false);  // Set loading to false after the data is fetched
      } catch (error) {
        console.error("Error fetching debit note", error);
        setLoading(false);  // Stop loading in case of an error
        alert("Failed to fetch debit note details.");
      }
    };

    fetchDebitNote();  // Fetch the data when component mounts
  }, [id]); 
   
  const handlePartyChange = (event) => {
    const selectedPartyName = event.target.value; // selected party name
    const selectedPartyData = debitNoteData.other_parties.find(party => party.party_name === selectedPartyName);

    if (selectedPartyData) {
        setDebitNoteData(prevData => ({
            ...prevData,
            party: selectedPartyName,  // Update the party name
            partymob: selectedPartyData.phone_number || "",  // Set phone number
            address: selectedPartyData.billing_address || "",  // Set address
            bill_numbers: selectedPartyData.bill_numbers || [],  // Set available invoice numbers
            billno: selectedPartyData.bill_numbers && selectedPartyData.bill_numbers.length > 0 
                ? selectedPartyData.bill_numbers[0] 
                : "", 
            party_id: selectedPartyData.party_id,
            opening_balance:selectedPartyData.opening_balance // Set the party_id
        }));
    } else {
        setDebitNoteData(prevData => ({
            ...prevData,
            party: selectedPartyName,
            partymob: "",  // Reset phone number
            address: "",  // Reset address
            bill_numbers: [],  // Reset invoice numbers
            billno: "",  // Reset invoice number
            party_id: ""  // Reset party_id if no match found
        }));
    }
};
const handleBillNumberChange = async (e) => {
  const selectedBillNumber = e.target.value;
   // Replace with the actual variable containing user ID
  setDebitNoteData({ ...debitNoteData, billno: selectedBillNumber });
  const userID = Cookies.get("user_id");
  if (selectedBillNumber) {
    try {
      // Pass userId as a query parameter in the URL
      const response = await axios.get(
        `${config.base_url}/api/debitnote/bill-details/${selectedBillNumber}/`,
        {
          params: { userid: userID }, // Query parameter for userId
        }
      );

      console.log("API Response:", response.data); // Debugging log

      setDebitNoteData((prevData) => ({
        ...prevData,
        bill_date: response.data.bill_date,
        items: response.data.items,
      }));

      const newSubtotal = calculateSubtotal(response.data.items);
      const newCGST = calculateCGST(response.data.items);
      const newSGST = calculateSGST(response.data.items);
      const newIGST = calculateIGST(response.data.items);

      setDebitNoteData((prevData) => ({
        ...prevData,
        subtotal: newSubtotal,
        cgst: newCGST, // Update CGST total
        sgst: newSGST, // Update SGST total
        igst: newIGST, // Update IGST total
      }));

    } catch (error) {
      console.error("Error fetching bill details", error);
      alert("Failed to fetch bill details.");
    }
  }
};

 
  const userID = Cookies.get("user_id");
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDebitNoteData({
      ...debitNoteData,
      [name]: value,
    });
  };

  const handlePartyToggle = () => {
    setIsPartySelected(!isPartySelected);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    for (const row of debitNoteData.items) {
      if (row.qty <= 0) {
        alert("Quantity cannot be zero!");
        return; // Prevent form submission if validation fails
      }
    }
    // Prepare the formData object
    const formData = {
      purchasedebit: {
        retrn_no: debitNoteData.retrn_no,
        party: debitNoteData.party_id,
        partymob: debitNoteData.partymob,
        billno: debitNoteData.billno,
        supplyplace: debitNoteData.supplyplace,
        subtotal: debitNoteData.subtotal,
        igst: debitNoteData.igst,
        cgst: debitNoteData.cgst,
        sgst: debitNoteData.sgst,
        adjust: debitNoteData.adjustment || 0,
        grandtotal: debitNoteData.grandtotal,
        bill_date: debitNoteData.bill_date,
        date: debitNoteData.date,
      },
      items: debitNoteData.items.map((row) => ({
        product: row.product__item_name,  // Ensure this matches your data structure
        qty: row.qty,
        price: row.product__item_at_price, // Ensure price field is correct
        tax: row.gst || row.igst,  // Tax can be gst or igst based on supply place
        discount: row.discount,
        hsn: row.product__item_hsn,
        total:row.total,
        product_id:row.product  // Ensure you're capturing HSN/SAC code
      }))
    };
    console.log('Form Data:', formData);
    // Make the API request to update the debit note
    axios.put(`${config.base_url}/api/edit-purchase-debit/${id}/`, formData)
      .then(response => {
        console.log('Update successful:', response.data);
        alert("Updated Successfully")
        navigate("/debitnote")
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  };
  
  
  const handleTableChange = (e, index, field) => {
    const value = e.target.value;
    const updatedTableData = [...debitNoteData.items];
    if (field === 'qty') {
      // Check for zero or invalid quantity
      if (value === 0) {
        alert("Quantity must be a positive number and cannot be zero!");
        return; // Prevent further updates if validation fails
      }
    }
    // Update the specific field of the selected row
    updatedTableData[index][field] = value;
  
    if (field === "product__item_name") {
      const selectedProduct = debitNoteData.items_under_company.find(
        (item) => item.item_name === value
      );
      if (field === "product__item_name") {
        const isProductExist = updatedTableData.some(
          (row, idx) => row.product__item_name === value && idx !== index
        );
    
        if (isProductExist) {
          alert("Item already exists in the table!");
          return; // Prevent updating the table if the product already exists
        }
      };
  
      if (selectedProduct) {
        updatedTableData[index] = {
          ...updatedTableData[index],
          product__item_name: selectedProduct.item_name,
          product__item_hsn: selectedProduct.item_hsn,
          product__item_at_price: selectedProduct.item_at_price,
          product__item_gst: selectedProduct.item_gst,
          product__item_igst: selectedProduct.item_igst,
          qty: 0,
          discount: 0,
          item_cgst:selectedProduct.item_cgst,
          product: selectedProduct.product, 
        };
      }
      console.log("Selected Product:", selectedProduct);
    }
  
    // If qty, price, tax, or discount changes, update total
    if (['qty', 'product__item_at_price', 'discount'].includes(field)) {
      const { qty, product__item_at_price, discount } = updatedTableData[index];
  
      // Calculate the total for this row
      const total = calculateTotal(qty, product__item_at_price, discount);
      updatedTableData[index].total = total;
    }

  // Update the state with the modified table data
  // setCreditNoteData((prevData) => ({
  //   ...prevData,
  //   items: updatedTableData
  // }));
  setDebitNoteData((prevData) => {
    const updatedData = { ...prevData, items: updatedTableData };
    // Recalculate the subtotal
   // Call calculateSubtotal after updating the table data
   updatedData.subtotal = calculateSubtotal(updatedTableData); // Call calculateSubtotal after updating the table data
      
   updatedData.cgst = calculateCGST(updatedTableData); // Recalculate CGST
   updatedData.sgst = calculateSGST(updatedTableData); // Recalculate SGST
   updatedData.igst = calculateIGST(updatedTableData); // Recalculate IGST
 

    return updatedData;
  });
};
// Add a new row to the table
  const calculateSubtotal = (items) => {
    // Sum up the 'total' field of all items in the table
    return items.reduce((accum, row) => accum + (parseFloat(row.total) || 0), 0);
  };

 
const calculateCGST = (tableData) => {
  return tableData.reduce((accum, row) => {
    const { qty, product__item_at_price, item_cgst } = row;

    // Parse values safely to avoid NaN
    const qtyValue = parseFloat(qty) || 0;
    const priceValue = parseFloat(product__item_at_price) || 0;
    const cgstRate = parseFloat(item_cgst) || 0;

    // Calculate CGST: (Quantity * Price * Rate) / 100
    const cgstAmount = (qtyValue * priceValue * cgstRate) / 100;
    return accum + cgstAmount;
  }, 0).toFixed(2); // Ensure the total CGST is displayed with 2 decimal places
};


const calculateSGST = (tableData) => {
  return tableData.reduce((accum, row) => {
    const { qty, product__item_at_price, item_sgst } = row;

    // Parse values safely to avoid NaN
    const qtyValue = parseFloat(qty) || 0;
    const priceValue = parseFloat(product__item_at_price) || 0;
    const sgstRate = parseFloat(item_sgst) || 0;

    // Calculate SGST: (Quantity * Price * SGST Rate) / 100
    const sgstAmount = (qtyValue * priceValue * sgstRate) / 100;
    return accum + sgstAmount;
  }, 0).toFixed(2); // Ensure the total SGST is displayed with 2 decimal places
};


const calculateIGST = (tableData) => {
  return tableData.reduce((accum, row) => {
    const { qty, product__item_at_price, item_igst } = row;

    // Parse values safely to avoid NaN
    const qtyValue = parseFloat(qty) || 0;
    const priceValue = parseFloat(product__item_at_price) || 0;
    const igstRate = parseFloat(item_igst) || 0;

    // Calculate IGST: (Quantity * Price * IGST Rate) / 100
    const igstAmount = (qtyValue * priceValue * igstRate) / 100;
    return accum + igstAmount;
  }, 0).toFixed(2); // Ensure the total IGST is displayed with 2 decimal places
};




  
const addRow = () => {
  const newRow = {
    sn: debitNoteData.items.length + 1,  // Automatically increment the SN based on current table length
    product__item_name: "",  // Default values for new row
    product__item_hsn: "",
    qty: "",
    product__item_at_price: "",
    gst: "",
    igst: "",
    discount: "",
    total: ""
  };

  // Update the debitNoteData.items state directly
  setDebitNoteData((prevData) => ({
    ...prevData,
    items: [...prevData.items, newRow]  // Add the new row to the existing items
  }));
};
const removeRow = (index) => {
  // Remove the row at the specified index
  const updatedTableData = debitNoteData.items.filter((_, rowIndex) => rowIndex !== index);

  // Update the debitNoteData.items state with the remaining rows
  setDebitNoteData((prevData) => ({
    ...prevData,
    items: updatedTableData
  }));
};
  // Calculate the total for a row based on qty, price, tax, and discount
const calculateTotal = (qty, price, discount) => {
  // Ensure all values are numbers before calculating
  const qtyValue = parseFloat(qty) || 0;
  const priceValue = parseFloat(price) || 0;
 
  const discountValue = parseFloat(discount) || 0;

  // Total formula: (Quantity * Price) + Tax - Discount
  return (qtyValue * priceValue) - discountValue;
};
const [partyModalData, setPartyModalData] = useState({
  partyName: "",
  gstin: "",
  mobile: "",
  gst: "",
  address: "",
  credit: "",
  balance: "",
});
  const [gstinError, setGstinError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setPartyModalData({
      ...partyModalData,
      [name]: value,
    });
     // Validate GSTIN
     if (name === 'gstin') {
      const gstinPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
      if (value && !gstinPattern.test(value)) {
        setGstinError('Invalid GSTIN. Please follow the format: 29APPCK7465F1Z1');
      } else {
        setGstinError('');
      }
    }
    if (name === 'mobile') {
      const mobilePattern = /^[0-9]{10}$/;
      if (value && !mobilePattern.test(value)) {
        setMobileError('Invalid mobile number. Please enter exactly 10 digits.');
      } else {
        setMobileError('');
      }
    }
  };
  const [showGST, setShowGST] = useState(true);

  // In the section where you toggle visibility
  const handleGSTSectionToggle = () => {
    setShowGST(!showGST); // This will toggle the visibility of the GST section
  };
  
  
    const handleAddPartyName = () => {
      // Open modal to add party details
      setIsModalOpen(true);
    };
  
    const handleSubmitModal = async () => {
      const userID = Cookies.get("user_id");  // Retrieve user_id from cookies
    
    if (!userID) {
      alert("User ID is missing. Please log in again.");
      return;  // If user_id is missing, stop the function.
    }
      const data = {
        party_name: partyModalData.partyname,
        phone_number: partyModalData.mobile,
        gstin: partyModalData.gstin,
        gst_type: partyModalData.gstType,
        billing_address: partyModalData.billingAddress,
        state: partyModalData.supplyState,
        email: partyModalData.email,
        opening_balance: partyModalData.openBalance,
        credit_limit: partyModalData.creditLimit,
        to_pay: partyModalData.toPay,
        to_recieve: partyModalData.toRecieve,
        date: partyModalData.date,
        company: debitNoteData.company,
        staff: debitNoteData.staff,
        user_id: userID,
      };
  
      console.log('Sending data to API:', data);
  
      try {
        const response = await fetch(`${config.base_url}/api/add_parties/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
    
        if (response.ok) {
          alert('Party added successfully!');
          setIsModalOpen(false);
          window.location.reload(); // Close the modal on success
        } else {
          const error = await response.json();
          alert('Error: ' + error.message);
          console.log('Error from API:', error);
        }
      } catch (error) {
        alert('Error: ' + error.message);
        console.log('Error:', error);
      }
  };


 const [isItemModalOpen,setIsItemModalOpen]=useState(false);
  const [itemModalData,setItemModalData]=useState({
   itemName:"",
   itemUnit:"",
   itemHsn:""
  })
  const [itemHsnError, setItemHsnError] = useState('');
  const handleItemModalChange=(e)=>{
    const{name,value}=e.target;
    setItemData({
      ...itemData,
      [name]:value,
    });
    if (name === 'item_hsn') {
      const hsnPattern = /^\d{6}$/; // Regex for exactly 6 digits
      if (value && !hsnPattern.test(value)) {
        setItemHsnError('Invalid HSN. Please enter exactly 6 digits.');
      } else {
        setItemHsnError('');
      }
    }
  }

  const handleAddItem=() =>{
    setIsItemModalOpen(true)
  }
const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);
const [newUnit, setNewUnit] = useState('');
  const [errorFeedback, setErrorFeedback] = useState('');
  const handleUnitSubmit = async (e) => {
    e.preventDefault();
  
    // Validate input
    if (!newUnit.trim()) {
      setErrorFeedback('Please enter a valid unit.');
      return;
    }
  
    try {
      const userID = Cookies.get("user_id"); // Retrieve the user ID from cookies
  
      // Send the new unit to the backend
      const response = await fetch(`${config.base_url}/add-unit/?user_id=${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ unit_name: newUnit }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // If the unit is successfully added, alert the user
        alert('Unit added successfully!');
  
        // Close the Add Unit Modal
        setIsAddUnitModalOpen(false);
  
        // Update the units list with the newly added unit
        setUnits((prevUnits) => [
          ...prevUnits,
          { id: data.newUnitId, unit_name: newUnit }, // Assuming the backend sends back the new unit's ID
        ]);
  
        // Reset the input field
        setNewUnit('');
  
        // Now, open the Item Modal
        setIsItemModalOpen(true); // This opens the Item Modal
      } else {
        setErrorFeedback(data.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error adding unit:', error);
      setErrorFeedback('An error occurred while submitting the form.');
    }
  };
  
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await fetch(`${config.base_url}/get-units/?user_id=${userID}`); // Replace with your API endpoint
        const data = await response.json();
        setUnits(data.units || []);
      } catch (error) {
        console.error('Error fetching units:', error);
      }
    };

    fetchUnits();
  }, []);



  const [itemData, setItemData] = useState({
    item_name: '',
    item_hsn: '',
    item_unit: '',
    item_type: '',
    item_taxable: '',
    item_gst: '',
    item_igst: '',
    item_sale_price: '',
    item_purchase_price: '',
    item_opening_stock: '',
    item_current_stock: '',
    item_stock_in_hand: '',
    item_at_price: '',
    item_date: '',
    item_min_stock_maintain: '',
    barcode: '',
  });

  const handleItemSubmitModal = async () => {
    const userID = Cookies.get("user_id");
  
    if (!userID) {
      alert("User ID is missing. Please log in again.");
      return;
    }
  
    // Ensure the required fields are set
    if (!itemData.item_name || !itemData.item_hsn || !itemData.item_unit || !itemData.item_type) {
      alert('Please fill out all required fields.');
      return;
    }
  
    // Check that itemData and debitNoteData are properly defined
    if (!itemData || !debitNoteData) {
      alert('Item or Debit Note data is missing.');
      return;
    }
  
    const data = {
      item_name: itemData.item_name,
      item_hsn: itemData.item_hsn,
      item_unit: itemData.item_unit,
      item_type: itemData.item_type,
      item_taxable: itemData.item_taxable,
      item_gst: itemData.item_gst,
      item_igst: itemData.item_igst,
      item_sale_price: itemData.item_sale_price,
      item_purchase_price: itemData.item_purchase_price,
      item_opening_stock: itemData.item_opening_stock,
      item_at_price: itemData.item_at_price,
      item_date: itemData.item_date,
      item_min_stock_maintain: itemData.item_min_stock_maintain,
      company: debitNoteData.company,
      staff: debitNoteData.staff,
    };
  
    console.log('Sending data to API:', data);
  
    try {
      const response = await fetch(`${config.base_url}/api/add-modal-item/?user_id=${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const error = await response.json();
        console.error('Error response:', error);  // Log the error response
        alert('Error: ' + (error.message || 'Unknown error occurred.'));
      } else {
        alert('Item added successfully!');
        setIsItemModalOpen(false);
        window.location.reload(); // Close the modal on success
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error: ' + error.message || 'Unknown error occurred.');
    }
  };
  
  const modalStyles = {
    content: {
      width: '50%',
      margin: 'auto',
      padding: '20px',
      backgroundColor: '#3d4465', // Set background color
      borderRadius: '8px',
      color: 'white', // White text for better contrast
      zIndex: '1050', // Ensure modal is above other content
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark semi-transparent background behind modal
      zIndex: '1040', // Ensure the overlay is below the modal content
    },
  };

  // Styles object for AddCreditNote component
  const styles = {
    outerContainer: {
      backgroundColor: "#8290c7",
      minHeight: "100vh",
    },
    container: {
      backgroundColor: "#8290c7",
    },
    mainPanel: {
      background: "#fff",
    },
    card: {
      boxShadow: "none",
    },
    cardBody: {
      backgroundColor: "white",
      padding: "30px",
    },
    heading: {
      backgroundColor: "#3d4465",
      color: "white",
      padding: "15px",
      margin: "0",
      textAlign: "left",
      width: "100%",
      zIndex: "1",
      marginBottom: "40px",
    },
    formSection: {
      marginTop: "40px",
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    formGroup: {
      flex: "3",
      marginBottom: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "2px",
      display: "block",
      textAlign: "left",
      position: "relative",
      zIndex: "3",
    },
    modallabel: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "2px",
      display: "block",
      textAlign: "left",
      position: "relative",
      zIndex: "3",
      marginTop: '1px',
    },
    input: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "45px",
      borderRadius: "5px",
      outline: "none",
    },
    centerButton: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    button: {
      backgroundColor: "rgb(218, 164, 48)",
      color: "white",
      padding: "10px 20px",
      borderRadius: "20px",
    },
    partySection: {
      display: "flex",
      alignItems: "center", 
      marginTop: "20px",
      marginBottom: "-5%",
    },
    partyLabel: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#333",
      marginRight: "10px", 
    },
    switchContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: '3%',
    },
    toggleSwitch: {
      width: "50px",
      height: "25px",
      position: "relative",
    },
    toggleInput: {
      opacity: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      cursor: "pointer",
    },
    toggleSlider: {
      position: "absolute",
      cursor: "pointer",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "#ccc",
      transition: "0.4s",
      borderRadius: "34px",
    },
    toggleSliderChecked: {
      backgroundColor: "#4c87af",
    },
    toggleSliderBefore: {
      content: '""',
      position: "absolute",
      height: "15px",
      width: "15px",
      borderRadius: "50%",
      left: "4px",
      bottom: "4px",
      backgroundColor: "white",
      transition: "0.4s",
    },
    toggleSliderBeforeChecked: {
      transform: "translateX(24px)",
    },
    partyFieldsRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    partyField: {
      flex: "3",
      marginBottom: "2px",
    },
    inputContainer: {
      display: "flex",
      alignItems: "center", // Vertically center the input and button
      gap: "8px", // Space between input and button
    },
    input: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "45px",
      borderRadius: "5px",
      outline: "none",
    },
   
    plusButton: {
      padding: "6px 12px", // Button padding
      backgroundColor: "#007bff", // Button background color
      color: "#fff", // Button text color
      border: "none", // No border for the button
      borderRadius: "4px", // Rounded corners for the button
      cursor: "pointer", // Pointer cursor on hover
      fontSize: "18px", // Font size of the plus sign
      display: "inline-flex", // Align button horizontally with the input
      alignItems: "center", // Vertically center the plus sign inside button
      justifyContent: "center", // Ensure plus sign is centered
      marginTop: "5px",
    },
    table: {
      width: "100%",
      marginTop: "30px",
      borderCollapse: "collapse",
    },
    th: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
      backgroundColor: "#3d4465",
      color: "white", 
    },
    td: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
    },
    addButton: {
      width: '40px',           // Set the width and height to make it square
      height: '40px',          // Equal to width for square shape
      borderRadius: '5px',     // Optional: Rounded corners, you can remove it if you want sharp corners
      backgroundColor: '#3d4465', // Your choice of background color
      color: 'white',          // Text color (for the plus sign)
      fontSize: '20px',        // Font size for the plus sign
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',      // Change the cursor to pointer on hover
      border: 'none',          // Remove border for a cleaner look
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
      marginTop: '2%',
    },
    plusSign: {
      fontSize: '24px',         // Ensure the plus sign fits well within the square
    },
    removeButton: {
      color: 'red',
      cursor: 'pointer',
      fontSize: '20px',
      textAlign: 'center',
      padding: '5px 10px',
    },
    
    rectangularButton: {
      padding: '10px 20px', // Padding inside each button
      border: 'none', // Remove default border
      borderRadius: '4px', // Optional: rounded corners for the buttons
      color: '#fff', // Text color for the buttons
      cursor: 'pointer', // Change cursor to pointer on hover
      fontSize: '16px', // Font size for better readability
      marginTop: '2%',
    },
    
    button: {
      // Your other button styles
      fontWeight: 'bold',
      padding: '10px 20px',
      marginRight: '10px',
    },
    plusButton: {
      padding: '6px 12px', // Button padding
      backgroundColor: '#3d4465', // Button background color
      color: '#fff', // Button text color
      border: 'none', // No border for the button
      borderRadius: '4px', // Rounded corners for the button
      cursor: 'pointer', // Pointer cursor on hover
      fontSize: '18px', // Font size of the plus sign
      display: 'inline-flex', // Align button horizontally with the input
      alignItems: 'center', // Vertically center the plus sign inside button
      justifyContent: 'center', // Ensure plus sign is centered
      marginTop: "5px",
    },
    heading: {
      backgroundColor: '#3d4465', // Background color for the heading
      color: 'white', // Text color for the heading (ensures text is white)
      padding: '15px', // Padding inside the heading
      margin: '0', // Remove default margin
      textAlign: 'left', // Align the heading text to the left
      width: '100%', // Full width
      zIndex: '1',
      marginBottom: '40px', // Space below the heading
      display: 'flex', // To allow both heading and button in the same row
      justifyContent: 'space-between', // Pushes the button to the right
      alignItems: 'center', // Vertically center the items
      fontWeight: 'bold', // Ensures the heading text is bold
    },
    headingText: {
      color: 'white', // Explicitly set text color to white
      margin: 0, // Remove margin
    },
    backButton: {
      backgroundColor: 'white', // White background for the button
      color: 'black', // Black text color for the button
      border: 'none', // Remove the border
      padding: '8px 16px', // Button padding
      cursor: 'pointer', // Pointer cursor on hover
      borderRadius: '4px', // Rounded corners for the button
      fontSize: '14px', // Font size for the button text
    },
    
  };
 
  const cardStyles = {
    backgroundColor: '#3d4465',  // Background color for the card (same as table heading)
    padding: '20px',
    width: cardWidth,  // Adjust the width of the card
    marginTop: '2%',
    borderRadius: '8px',  // Optional: Rounded corners for the card
    
  };
  
  const labelStyles = {
    color: 'white',  // White color for the label text
    textAlign: 'left',  // Align the label text to the left
    fontSize: '14px',  // Font size for the label text
    marginLeft: '2%',
    
  };
  
  const inputStyles = {
    textAlign: 'right',  // Align the input text to the right
    width: '70%',  // Input takes 70% of the width
    padding: '8px',  // Adjust padding for input fields
    borderRadius: '4px',  // Rounded corners for input fields
    border: '1px solid #ccc',  // Subtle border color
    fontSize: '14px',  // Font size for the input text
    marginLeft: '40%',
  };
  
  const formRowStyles = {
    display: 'flex',  // Use flexbox for horizontal alignment
    alignItems: 'center',  // Center the items vertically
    marginBottom: '10px',  // Space between rows
  };
  
  const formGroupStyles = {
    display: 'flex',  // Align label and input on the same row
    width: '100%',  // Full width for form group
    justifyContent: 'flex-start',  // Align items to the left
    marginBottom: '15px',  // Space between form fields
  };
  
  const textAreaStyles = {
    textAlign: 'right',  // Align the textarea text to the right
    width: '100%',  // Ensure textarea takes full width
    padding: '8px',  // Padding inside the textarea
    borderRadius: '4px',  // Rounded corners for textarea
    border: '1px solid #ccc',  // Subtle border
    fontSize: '14px',  // Font size for the text inside the textarea
  };
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");
    useEffect(() => {
      const handleResize = () => {
        // Adjust panel width based on screen size
        setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
  
        // Adjust card width based on screen size (desktop vs mobile)
        if (window.innerWidth > 768) {
          setCardWidth("50%");  // For desktop
        } else {
          setCardWidth("105%");  // For mobile
        }
      };
  
      // Initial resize handling
      handleResize();
  
      // Add event listener to handle resizing
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);  
 
    const handleBackClick = () => {
      // Navigate to the credit_note page when the back button is clicked
      navigate('/debitnote');
    };
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
      className="main-panel px-3 py-2"
      style={{ background: "#a9a9a961", width: panelWidth }}
    >
          <div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>
          <div className="card radius-15" >
                <div className="card-body">
                <div style={styles.heading}>
          {/* Heading with explicitly white text color */}
          <h3 style={styles.headingText}>
            Edit Debit Note
          </h3>
          <button
            style={styles.backButton}
            onClick={handleBackClick}
          >
           <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
          </button>
        </div>

                  {/* Party Section */}
                  <div style={styles.partySection}>
                    <span style={styles.partyLabel}>Party</span>
                    <div style={styles.switchContainer}>
                      <label style={styles.toggleSwitch}>
                        <input
                          type="checkbox"
                          style={styles.toggleInput}
                          checked={isPartySelected}
                          onChange={handlePartyToggle}
                        />
                        <span
                          style={{
                            ...styles.toggleSlider,
                            ...(isPartySelected && styles.toggleSliderChecked),
                          }}
                        >
                          <span
                            style={{
                              ...styles.toggleSliderBefore,
                              ...(isPartySelected && styles.toggleSliderBeforeChecked),
                            }}
                          ></span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                  {/* Form Section */}
                  <div className="my-5" style={styles.formSection}>
                    
                      {isPartySelected && (
                        <div className="form-row" >
                         <div className="form-group col-12 col-md-4" >

{/* <label htmlFor="partyName" style={styles.label}> */}
  <span style={{ fontWeight: '500' }}> Party Name</span>
{/* </label> */}

                            <div style={styles.inputContainer}>
                            <select
          id="partyName"
          name="partyName"
          className="form-control"
          // style={styles.input}
          value={debitNoteData.party_id}
          onChange={handlePartyChange}
          required
        >
           <option value="">Select Party</option>

{/* Display current selected party */}
{debitNoteData.party_id && (
  <option value={debitNoteData.party_id}>{debitNoteData.party}</option>
)}

{/* Show other party names under the same company */}
{debitNoteData.other_parties?.map((party, index) => (
  <option key={index} value={party.id}>
    {party.party_name}
  </option>
))}
        </select>

                            {/* + Button */}
                            <button
                              type="button"
                              onClick={handleAddPartyName}
                              style={styles.plusButton}
                            >
                              +
                            </button>
                          </div>
                         
  <div className="form-group col-12" style={{ display: 'flex', alignItems: 'center' ,marginBottom:'20px'}}>
  <span style={{ fontWeight: '400', marginRight: '10px' }}>Available Balance:</span>
    <div style={{ fontWeight: '500', color: 'red' }}>
      {debitNoteData.opening_balance} {/* Display the opening balance */}
    </div>
     {/* Show the opening balance of the selected party */}
  </div>

                          </div>
                          <div className="form-group col-12 col-md-4" >

                            {/* <label htmlFor="partyPhone" style={styles.label}> */}
                            <span style={{ fontWeight: '500' }}>Party Phone</span>
                            <input
                              type="text"
                              id="partymob"
                              name="partyPhone"
                              className="form-control"
                              // style={styles.input}
                              value={debitNoteData.partymob}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="form-group col-12 col-md-4" >

                            {/* <label htmlFor="partyPhone" style={styles.label}> */}
                            <span style={{ fontWeight: '500' }}>Billing Address</span>
                            <input
                              type="text"
                              id="billingAddress"
                              name="billingAddress"
                              className="form-control"
                              // style={styles.input}
                              value={debitNoteData.address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      )}
                    

                      {/* Input Fields for Return No, Invoice No, Invoice Date */}
                      <div className="form-row">
                        <div className="form-group col-12 col-md-4" >

                          {/* <label htmlFor="returnNumber" style={styles.label}> */}
                            <span style={{ fontWeight: '500' }}>Return No</span>
                          {/* </label> */}
                          <input
                            type="text"
                            id="returnNumber"
                            name="returnNumber"
                            className="form-control"
                            // style={styles.input}
                            value={debitNoteData.retrn_no}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                          <div className="form-group col-12 col-md-4">

{/* <label htmlFor="invoiceNumber" style={styles.label}> */}
   <span style={{ fontWeight: '500' }}>Bill Number</span>
  {debitNoteData.bill_numbers && debitNoteData.bill_numbers.length > 0 ? (
    <select
      id="billNumber"
      name="billNumber"
      className="form-control"
      // style={styles.input}
      value={debitNoteData.billno}
       onChange={handleBillNumberChange}
      required
    >
      <option value="">Select Bill</option>
      {debitNoteData.bill_numbers.map((billNo, index) => (
        <option key={index} value={billNo}>
          {billNo}
        </option>
      ))}
    </select>
  ) : (
    <input
      type="text"
      id="billNumber"
      name="billNumber"
      className="form-control"
      // style={styles.input}
      value={debitNoteData.billno}
      onChange={handleInputChange}
      required
    />
  )}
</div>


<div className="form-group col-12 col-md-4">

{/* <label htmlFor="invoiceNumber" style={styles.label}> */}
   <span style={{ fontWeight: '500' }}>Bill Date</span>
                          <input
                            type="date"
                            id="invoiceDate"
                            name="invoiceDate"
                            className="form-control"
                            // style={styles.input}
                            value={debitNoteData.bill_date}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      {/* Date and State of Supply */}
                      <div className="form-row" >
                        <div className="form-group col-12 col-md-6">

                          {/* <label htmlFor="date" style={styles.label}> */}
                            <span style={{  fontWeight: '500' }}>  Date </span>
                          <input
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                            // style={styles.input}
                            value={debitNoteData.date}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

  
                        <div className="form-group col-12 col-md-6">

                          {/* <label htmlFor="stateOfSupply" style={styles.label}> */}
                          <span style={{ fontWeight: '500' }}>State of Supply</span>

  <select
    id="stateOfSupply"
    name="supplyplace"  // Make sure the name attribute matches the state variable
    className="form-control"
    // style={styles.input}
    value={debitNoteData.supplyplace}  // Ensure this binds to the correct state variable
    onChange={handleInputChange}  // Trigger the change handler
    required
  >
    <option value="State">State</option>
    <option value="Other State">Other State</option>
  </select>
</div>

                      </div>

                      
                  
                  </div>

                  
                  <div className="table-responsive " style={{ marginTop: "-2vh" }}>
                  <table style={styles.table}>
                    <thead>
                      <tr>
                        <th style={styles.th}>#</th>
                        <th style={styles.th}>Product</th>
                        <th style={styles.th}>HSN/SAC</th>
                        <th style={styles.th}>Qty</th>
                        <th style={styles.th}>Price</th>
                        <th style={styles.th}>Tax</th>
                        <th style={styles.th}>Discount</th>
                        <th style={styles.th}>Total</th>
                        <th style={styles.th}>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
  {Array.isArray(debitNoteData.items) && debitNoteData.items.length > 0 ? (
    debitNoteData.items.map((row, index) => (
      <tr key={index}>
        <td style={styles.td}>{index + 1}</td>
        <td style={{ ...styles.td, display: 'flex', alignItems: 'center' }}>
  <select
    className="form-control"
    value={row.product__item_name || ""}  // Make sure to handle empty or undefined
    onChange={(e) => handleTableChange(e, index, 'product__item_name')}
  >
    <option value="">Select Product</option>
    {debitNoteData.items_under_company.map((item) => (
      <option key={item.id} value={item.item_name}>
        {item.item_name}
      </option>
    ))}
  </select>

  <button
    type="button"
    onClick={handleAddItem}
    style={{ ...styles.plusButton, marginLeft: '10px',marginTop:"0px"}} // Add margin to create space between the select and button
  >
    +
  </button>
</td>
<input
        type="hidden"
        name={`items[${index}].product`}
        value={row.product || ""}
      />
       <td style={styles.td}>
          <input
            type="text"
            className="form-control"
            value={row.product__item_hsn || ""}
            onChange={(e) => handleTableChange(e, index, "product__item_hsn")}
          />
        </td>
        <td style={styles.td}>
          <input
            type="number"
            className="form-control"
            value={row.qty || 0}
            onChange={(e) => handleTableChange(e, index, "qty")}
          />
        </td>
        <td style={styles.td}>
          <input
            type="number"
            className="form-control"
            value={row.product__item_at_price || 0}
            onChange={(e) => handleTableChange(e, index, "product__item_at_price")}
          />
        </td>
        <td style={styles.td}>
        
          {debitNoteData.supplyplace === "State" ? (
            <>
              <input
                type="text"
                value={row.product__item_gst}
                onChange={(e) => handleTableChange(e, index, "gst")}
                style={styles.tableinput}
              />
              <input
                type="hidden"
                value={row.item_cgst}
                onChange={(e) => handleTableChange(e, index, "item_cgst")}
                style={styles.tableinput}
              />
                           <input
                type="hidden"
                value={row.item_sgst}
                onChange={(e) => handleTableChange(e, index, "item_sgst")}
                style={styles.tableinput}
              />
            </>
          ) : (
            <>
            <input
              type="text"
              value={row.product__item_igst}
              onChange={(e) => handleTableChange(e, index, "igst")}
              style={styles.tableinput}
            />
                          <input
                type="hidden"
                value={row.item_igst}
                onChange={(e) => handleTableChange(e, index, "item_igst")}
                style={styles.tableinput}
              />

            </>
          )}
        </td>
        <td style={styles.td}>
          <input
            type="number"
            className="form-control"
            value={row.discount || 0}
            onChange={(e) => handleTableChange(e, index, "discount")}
          />
        </td>
        <td style={styles.td}>
          <input type="number" className="form-control" value={row.total || 0} readOnly />
        </td>
        <td style={styles.td}>
          <span style={styles.removeButton} onClick={() => removeRow(index)}>
            X
          </span>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9" style={styles.td}>
        No items available
      </td>
    </tr>
  )}
</tbody>
  </table>
  </div>
                  <button type="button" onClick={addRow} style={styles.addButton}>
                    <span style={styles.plusSign}>+</span>
                  </button>

                  <div style={cardStyles}>
  

                    {/* First Row */}
                    <div className="form-row" style={formRowStyles}>
                      <div className="form-group" style={formGroupStyles}>
                        <label htmlFor="subtotal" style={labelStyles}>Subtotal:</label>
                        <input
                          type="number"
                          id="subtotal"
                          name="subtotal"
                          className="form-control"
                          style={inputStyles}
                          value={debitNoteData.subtotal}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    {/* Second Row */}
                    {debitNoteData.supplyplace === "Other State" && (
  <div className="form-row" style={formRowStyles}>
    <div className="form-group" style={formGroupStyles}>
      <label htmlFor="totalIGST" style={labelStyles}>Total IGST:</label>
      <input
        type="number"
        id="totalIGST"
        name="totalIGST"
        className="form-control"
        style={inputStyles}
        value={debitNoteData.igst} 
        onChange={handleInputChange}// Call the function to get the total IGST
        readOnly
      />
    </div>
  </div>
)}

          {debitNoteData.supplyplace === "State" && (
  <>
    <div className="form-row" style={formRowStyles}>
      <div className="form-group" style={formGroupStyles}>
        <label htmlFor="cgst" style={labelStyles}>CGST:</label>
        <input
          type="number"
          id="cgst"
          name="cgst"
          className="form-control"
          style={inputStyles}
          value={debitNoteData.cgst}
          onChange={handleInputChange}
          readOnly
        />
      </div>
    </div>

    <div className="form-row" style={formRowStyles}>
      <div className="form-group" style={formGroupStyles}>
        <label htmlFor="sgst" style={labelStyles}>SGST:</label>
        <input
          type="number"
          id="sgst"
          name="sgst"
          className="form-control"
          style={inputStyles}
          value={debitNoteData.sgst}
          onChange={handleInputChange}
          readOnly
        />
      </div>
    </div>
  </>
)}
                    {/* Fourth Row */}
                    <div className="form-row" style={formRowStyles}>
  <div className="form-group" style={formGroupStyles}>
    <label htmlFor="taxAmount" style={labelStyles}>Tax Amount:</label>
    <input
      type="number"
      id="taxAmount"
      name="taxAmount"
      className="form-control"
      style={inputStyles}
      value={
        debitNoteData.supplyplace === "State"
          ? (parseFloat(debitNoteData.cgst || 0) + parseFloat(debitNoteData.sgst || 0))
          : debitNoteData.supplyplace === "Other State"
          ? parseFloat(debitNoteData.igst || 0)
          : 0
      }
      onChange={(e) => setDebitNoteData({ ...debitNoteData, taxamount: e.target.value })}
      readOnly
    />
  </div>
</div>

                    {/* Fifth Row */}
                    <div className="form-row" style={formRowStyles}>
  <div className="form-group" style={formGroupStyles}>
    <label htmlFor="adjustment" style={labelStyles}>Adjustment:</label>
    <input
      type="number"
      id="adjustment"
      name="adjustment"
      className="form-control"
      style={inputStyles}
      value={debitNoteData.adjust || 0}  // Default value is 0, but it's editable
      onChange={(e) => setDebitNoteData({ ...debitNoteData, adjust: e.target.value })}
    />
  </div>
</div>


                    {/* Sixth Row */}
                    <div className="form-row" style={formRowStyles}>
  <div className="form-group" style={formGroupStyles}>
    <label htmlFor="grandTotal" style={labelStyles}>Grand Total:</label>
    <input
      type="number"
      id="grandTotal"
      name="grandTotal"
      className="form-control"
      style={inputStyles}
      value={(
        debitNoteData.supplyplace === "State"
          ? (parseFloat(debitNoteData.cgst || 0) + parseFloat(debitNoteData.sgst || 0) + parseFloat(debitNoteData.subtotal || 0) + parseFloat(debitNoteData.adjust || 0))
          : debitNoteData.supplyplace === "Other State"
          ? (parseFloat(debitNoteData.igst || 0) + parseFloat(debitNoteData.subtotal || 0) + parseFloat(debitNoteData.adjust || 0))
          : 0
      ).toFixed(2)}
      onChange={(e) => setDebitNoteData({ ...debitNoteData, grandtotal: e.target.value })}
      readOnly
    />
  </div>
</div>


                    {/* Textarea Row */}
                  
                  </div>

                  <div style={styles.rectangularButtonContainer}>
  <button type="submit" 
    style={{ 
      ...styles.button, 
      backgroundColor: styles.th.backgroundColor, 
      ...styles.rectangularButton 
    }}
  >
  Update
  </button>

</div>
</form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
     isOpen={isModalOpen}
     onRequestClose={() => setIsModalOpen(false)}
     style={{
       content: {
         padding: '20px',
         marginLeft: '15%',
         borderRadius: '8px',
         backgroundColor: 'rgba(61, 68, 101)', // Modal content background color
         color: 'white', // Text color in modal
         zIndex: '9999', // Ensure it stays on top of other content
         marginTop: '10vh',
       },
       overlay: {
         backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background overlay color to darken the page behind the modal
         zIndex: '999', // Ensure the overlay stays below the modal
       },
     }}
   >
     <style>
    {`
      /* Default margin for larger screens */
      @media (max-width: 768px) {
        .ReactModal__Content {
          margin-left: 1% !important; /* Reduced left margin for mobile */
        }
      }

      /* Style for mobile views */
      @media (max-width: 767px) {
        .modal-content {
          padding: 10px;
        }

        /* Adjust form elements */
        .form-control {
          width: 100% !important;
        }

        /* Adjust radio buttons layout */
        .radio-group {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .radio-group label {
          flex: 1 0 45%; /* Make the radio buttons responsive */
        }

        .modal-header h3 {
          font-size: 18px;
        }

        .modal-footer {
          text-align: center;
        }
        .save-button, .previous-button {
          width: 100% !important; /* Full width buttons on mobile */
          padding: 12px 0 !important; /* Adjust button padding */
          margin-bottom: 10px; /* Add some space between buttons */
        }
      }
    `}
  </style>

   
     <div className="modal-header" style={{ color: 'white' }}>
       <h3 className="m-3" style={{ color: 'white' }}>ADD PARTY</h3>
       <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} />
     </div>
   
     <div className="bg-light bs p-4 rounded mb-5">
       <form>
         <div className="row" style={{ marginLeft: '-1vh' }}>
           <div className="col-md-4">
             <span style={{ color: 'black' }}>Party Name</span>
             <input
               type="text"
               className="form-control border-secondary"
               id="partyname"
               name="partyname"
               value={partyModalData.partyname}
               onChange={handleModalChange}
               style={{ color: 'black' }}
               required
             />
           </div>
           <div className="col-md-4">
             <span style={{ color: 'black' }}>GSTIN</span>
             <input
               type="text"
               className="form-control border-secondary"
               id="gstin"
               name="gstin"
               value={partyModalData.gstin}
               onChange={handleModalChange}
               placeholder="29APPCK7465F1Z1"
               style={{ color: 'black' }}
               required
             />
              {gstinError && <small style={{ color: 'red' }}>{gstinError}</small>}
           </div>
           <div className="col-md-4">
             <span style={{ color: 'black' }}>Mobile</span>
             <input
               type="number"
               className="form-control border-secondary"
               id="partyphno"
               name="mobile"
               value={partyModalData.mobile}
               onChange={handleModalChange}
               style={{ color: 'black' }}
               required
             />
                {mobileError && <small style={{ color: 'red' }}>{mobileError}</small>}
           </div>
         </div>
   
         <div style={{ marginTop: '20px' }} />
         <div style={{ backgroundColor: 'rgba(130, 144, 199)', padding: '10px', borderRadius: '8px' }}>
           <div className="row pb-0 mb-0">
             <div className="col text-center">
               <button
                 type="button"
                 style={{
                   color: showGST ? 'black' : 'white', // Change text color based on selection
                   fontWeight: 'bold',
                   textDecoration: showGST ? 'underline' : 'none', // Underline for selected button
                   backgroundColor: 'transparent',
                   border: 'none',
                   cursor: 'pointer',
                 }}
                 onClick={() => setShowGST(true)}
               >
                 GST & ADDRESS
               </button>
             </div>
             <div className="col text-center">
               <button
                 type="button"
                 style={{
                   color: !showGST ? 'black' : 'white', // Change text color based on selection
                   fontWeight: 'bold',
                   textDecoration: !showGST ? 'underline' : 'none', // Underline for selected button
                   border: 'none',
                   cursor: 'pointer',
                   backgroundColor: 'transparent',
                 }}
                 onClick={() => setShowGST(false)}
               >
                 CREDIT & BALANCE
               </button>
             </div>
           </div>
         </div>
   
         <div style={{ marginBottom: '20px' }} />
   
         {showGST ? (
           <div id="gsttemp">
             <div className="row">
               {/* GST Type */}
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>GST Type</span>
                 <select
                   className="form-control border-secondary"
                   id="gstType"
                   name="gstType"
                   value={partyModalData.gstType}
                   onChange={handleModalChange}
                   style={{ color: 'black', backgroundColor: 'white' }}
                 >
                   <option value="" hidden>Select</option>
                   <option value="Registered Party">Registered Party</option>
                   <option value="Unregistered or Consumer">Unregistered or Consumer</option>
                   <option value="Registered Business or Combosision">Registered Business or Combosision</option>
                 </select>
               </div>
   
               {/* Supply State */}
               <div className="col-md-6">
     <span style={{ color: 'black' }}>Supply State</span>
     <select
       className="form-control border-secondary"
       name="supplyState"
       id="supplyState"
       value={partyModalData.supplyState}
       onChange={handleModalChange}
       style={{
         color: 'black',
         backgroundColor: 'white',
         position: 'relative',
         zIndex: 10,
       }}
     >
       <option value="" hidden>Select</option>
       <option value="Andhra Pradesh">Andhra Pradesh</option>
       <option value="Arunachal Pradesh">Arunachal Pradesh</option>
       <option value="Assam">Assam</option>
       <option value="Bihar">Bihar</option>
       <option value="Chhattisgarh">Chhattisgarh</option>
       <option value="Goa">Goa</option>
       <option value="Gujarat">Gujarat</option>
       <option value="Haryana">Haryana</option>
       <option value="Himachal Pradesh">Himachal Pradesh</option>
       <option value="Jharkhand">Jharkhand</option>
       <option value="Karnataka">Karnataka</option>
       <option value="Kerala">Kerala</option>
       <option value="Madhya Pradesh">Madhya Pradesh</option>
       <option value="Maharashtra">Maharashtra</option>
       <option value="Manipur">Manipur</option>
       <option value="Meghalaya">Meghalaya</option>
       <option value="Mizoram">Mizoram</option>
       <option value="Nagaland">Nagaland</option>
       <option value="Odisha">Odisha</option>
       <option value="Punjab">Punjab</option>
       <option value="Rajasthan">Rajasthan</option>
       <option value="Sikkim">Sikkim</option>
       <option value="Tamil Nadu">Tamil Nadu</option>
       <option value="Telangana">Telangana</option>
       <option value="Tripura">Tripura</option>
       <option value="Uttar Pradesh">Uttar Pradesh</option>
       <option value="Uttarakhand">Uttarakhand</option>
       <option value="West Bengal">West Bengal</option>
       <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
       <option value="Chandigarh">Chandigarh</option>
       <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
       <option value="Delhi">Delhi</option>
       <option value="Jammu and Kashmir">Jammu and Kashmir</option>
       <option value="Ladakh">Ladakh</option>
       <option value="Lakshadweep">Lakshadweep</option>
       <option value="Puducherry">Puducherry</option>
     </select>
   </div>
   
             </div>
   
             <div className="row">
               {/* Email Field */}
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Email</span>
                 <input
                   type="email"
                   className="form-control border-secondary"
                   id="email"
                   name="email"
                   value={partyModalData.email}
                   onChange={handleModalChange}
                   style={{ color: 'black', backgroundColor: 'white' }}
                   required
                 />
               </div>
   
               {/* Billing Address (Right side) */}
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Billing Address</span>
                 <textarea
                   className="form-control border-secondary"
                   id="billingAddress"
                   name="billingAddress"
                   value={partyModalData.billingAddress}
                   onChange={handleModalChange}
                   rows="4"
                   style={{ color: 'black', backgroundColor: 'white' }}
                 ></textarea>
               </div>
             </div>
           </div>
         ) : (
           <div id="credittemp" style={{ backgroundColor: 'rgba(144, 211, 117, 0.098)' }}>
             <div className="row">
             <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                 
                 <div className="d-flex flex-wrap align-items-center" style={{ whiteSpace: 'nowrap', justifyContent: 'flex-start' }}>
    
  <span style={{ color: 'black', marginRight: '10px' }}>Opening Balance</span> -
  <div className="d-flex align-items-center ms-2 me-4" style={{ marginRight: '10px' }}>
    <input
      type="radio"
      id="taxable"
      name="item_taxable"
      // checked={itemData.item_taxable === 'Taxable'}
      // onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Taxable' } })}
    />
    <span style={{ color: 'black', marginLeft: '8px' }}>To Pay</span>
  </div>
  <div className="d-flex align-items-center">
    <input
      type="radio"
      id="nonTaxable"
      name="item_taxable"
      // checked={itemData.item_taxable === 'Non-Taxable'}
      // onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Non-Taxable' } })}
    />
    <span style={{ color: 'black', marginLeft: '8px' }}>To Receive</span>
  </div>
</div>

                 <input
                   type="number"
                   className="form-control border-secondary"
                   id="openbalance"
                   name="openBalance"
                   value={partyModalData.openBalance}
                   onChange={handleModalChange}
                   style={{ color: 'black', backgroundColor: 'white' }}
                 />
               </div>
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Credit Limit</span>
                 <input
                   type="number"
                   className="form-control border-secondary"
                   id="creditLimit"
                   name="creditLimit"
                   value={partyModalData.creditLimit}
                   onChange={handleModalChange}
                   style={{ color: 'black', backgroundColor: 'white' }}
                 />
               </div>
             </div>
   
             <div className="row">
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Date</span>
                 <input
                   type="date"
                   className="form-control border-secondary"
                   id="partydate"
                   name="date"
                   value={partyModalData.date}
                   onChange={handleModalChange}
                   style={{ color: 'black', backgroundColor: 'white' }}
                 />
               </div>
             </div>
           </div>
         )}
   
         <div style={{ marginTop: '20px', textAlign: 'center' }}>
           {showGST ? (
             <button
               type="button"
               style={{
                 backgroundColor: 'rgb(61, 68, 101)',
                 color: 'white',
                 padding: '10px 20px',
                 borderRadius: '5px',
               }}
               onClick={() => setShowGST(false)}
             >
               NEXT
             </button>
           ) : (
             <>
               <button
                 type="button"
                 style={{
                   backgroundColor: 'rgb(61, 68, 101)',
                   color: 'white',
                   padding: '10px 20px',
                   borderRadius: '5px',
                   marginRight: '10px',
                 }}
                 onClick={() => setShowGST(true)}
               >
                 PREVIOUS
               </button>
               <button
                 type="button"
                 style={{
                   backgroundColor: 'rgb(61, 68, 101)',
                   color: 'white',
                   padding: '10px 20px',
                   borderRadius: '5px',
                 }}
                 onClick={handleSubmitModal}
               >
                 SAVE
               </button>
             </>
           )}
         </div>
       </form>
     </div>
   </Modal>
   
   <Modal
     isOpen={isItemModalOpen}
     onRequestClose={() => setIsItemModalOpen(false)}
     style={{
       content: {
         padding: '20px',
         marginLeft: '15%',
         borderRadius: '8px',
         backgroundColor: 'rgba(61, 68, 101)', // Modal content background color
         color: 'white', // Text color in modal
         zIndex: '9999', // Ensure it stays on top of other content
         marginTop: '10vh',
       },
       overlay: {
         backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background overlay color to darken the page behind the modal
         zIndex: '999', // Ensure the overlay stays below the modal
       },
     }}
   >
     <style>
    {`
      /* Default behavior for desktop view */
      @media (min-width: 768px) {
        .modal-content {
          max-width: 80vw;
          margin: auto;
        }
      }

      /* Full-width modal for mobile view */
      @media (max-width: 767px) {
        .modal-content {
          max-width: 100vw;
          margin: 0;
          padding: 10px;
        }
          .ReactModal__Content {
          margin-left: 1% !important; /* Reduce left margin for mobile view */
        }
           .save-button, .previous-button {
          width: 100% !important; /* Full width buttons on mobile */
          padding: 12px 0 !important; /* Adjust button padding */
          margin-bottom: 10px; /* Add some space between buttons */
        }
      }
    `}
  </style>
     <div className="modal-header" style={{ color: 'white' }}>
       <h3 className="m-3" style={{ color: 'white' }}>ADD ITEM</h3>
       <button type="button" className="btn-close" onClick={() => setIsItemModalOpen(false)} />
     </div>
   
     <div className="bg-light bs p-4 rounded mb-5">
       <form>
         <div className="row" style={{ marginLeft: '-1vh' }}>
           <div className="col-md-3">
             <span style={{ color: 'black' }}>Item Type</span>
             <select
               style={{ color: 'black' }}
               className="form-control border-secondary"
               name="item_type"
               value={itemData.item_type}
               onChange={handleItemModalChange}
               required
             >
               <option value="" hidden>Select Type</option>
               <option value="Goods">Products</option>
               <option value="Services">Services</option>
             </select>
           </div>
           <div className="col-md-3">
             <span style={{ color: 'black' }}>Item Name:</span>
             <input
               type="text"
               className="form-control border-secondary"
               name="item_name"
               value={itemData.item_name}
               onChange={handleItemModalChange}
               placeholder="Item Name"
               style={{ color: 'black' }}
               required
             />
           </div>
           <div className="col-md-3">
             <span style={{ color: 'black' }}>Item HSN</span>
             <input
               type="text"
               className="form-control border-secondary"
               name="item_hsn"
               value={itemData.item_hsn}
               onChange={handleItemModalChange}
               placeholder="ITEM HSN"
               style={{ color: 'black' }}
               required
             />
             {itemHsnError && <small style={{ color: 'red' }}>{itemHsnError}</small>}
           </div>
           <div className="col-md-3">
             <span style={{ color: 'black' }}>Unit</span>
             <div className="d-flex align-items-center">
               <select
                 style={{ color: 'black' }}
                 className="form-control border-secondary"
                 name="item_unit"
                 value={itemData.item_unit}
                 onChange={handleItemModalChange}
                 required
               >
                 <option value="" hidden>Select Unit</option>
                 {units.map((unit) => (
                   <option key={unit.id} value={unit.unit_name}>
                     {unit.unit_name}
                   </option>
                 ))}
               </select>
               <button
                 type="button"
                 className="btn"
                 name="unit-btn"
                 onClick={() => {
                   setIsAddUnitModalOpen(true);
                   setIsItemModalOpen(false);
                 }}
                 style={{
                   backgroundColor: 'rgb(60, 61, 101)',
                   marginLeft: '10px', // space between select and button
                   color: 'white',
                 }}
               >
                 +
               </button>
             </div>
           </div>
         </div>
   
         <div style={{ marginTop: '20px' }} />
         <div style={{ backgroundColor: 'rgba(130, 144, 199)', padding: '10px', borderRadius: '8px' }}>
           <div className="row pb-0 mb-0">
             <div className="col text-center">
               <button
                 type="button"
                 style={{
                   color: showGST ? 'black' : 'white', // Change text color based on selection
                   fontWeight: 'bold',
                   textDecoration: showGST ? 'underline' : 'none', // Underline for selected button
                   backgroundColor: 'transparent',
                   border: 'none',
                   cursor: 'pointer',
                 }}
                 onClick={() => setShowGST(true)}
               >
                 PRICING
               </button>
             </div>
             <div className="col text-center">
               <button
                 type="button"
                 style={{
                   color: !showGST ? 'black' : 'white', // Change text color based on selection
                   fontWeight: 'bold',
                   textDecoration: !showGST ? 'underline' : 'none', // Underline for selected button
                   border: 'none',
                   cursor: 'pointer',
                   backgroundColor: 'transparent',
                 }}
                 onClick={() => setShowGST(false)}
               >
                 STOCKS
               </button>
             </div>
           </div>
         </div>
   
         <div style={{ marginBottom: '20px' }} />
   
         {showGST ? (
           <div className="row">
            
            <div className="col-md-12 d-flex align-items-center">
               <div className="d-flex align-items-center me-4">
                 <input
                   type="radio"
                   id="taxable"
                   name="item_taxable"
                   checked={itemData.item_taxable === 'Taxable'}
                   onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Taxable' } })}
                 />
                 <span style={{ color: 'black', marginLeft: '8px',marginRight:'10px' }}>Taxable</span>
               </div>
               <div className="d-flex align-items-center"  style={{marginLeft:"10px"}}>
                 <input
                   type="radio"
                   id="nonTaxable"
                   name="item_taxable"
                   checked={itemData.item_taxable === 'Non-Taxable'}
                   onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Non-Taxable' } })}
                 />
                 <span style={{ color: 'black', marginLeft: '8px' }}>NonTaxable</span>
               </div>
             </div>
             {itemData.item_taxable === 'Taxable' ? (
               <>
                 <div className="col-md-6 mb-5">
                   <span style={{ color: 'black' }}>IGST</span>
                   <select
                     className="form-control border-secondary"
                     name="item_igst"
                     value={itemData.item_igst}
                     onChange={handleItemModalChange}
                   >
                     <option value="IGST0[0%]">IGST0[0%]</option>
                     <option value="IGST3[3%]">IGST3[3%]</option>
                     <option value="IGST5[5%]">IGST5[5%]</option>
                     <option value="IGST12[12%]">IGST12[12%]</option>
                     <option value="IGST18[18%]">IGST18[18%]</option>
                     <option value="IGST28[28%]">IGST28[28%]</option>
                   </select>
                 </div>
                 <div className="col-md-6 mb-5">
                   <span style={{ color: 'black' }}>GST</span>
                   <select
                     className="form-control border-secondary"
                     name="item_gst"
                     value={itemData.item_gst}
                     onChange={handleItemModalChange}
                   >
                     <option value="GST0[0%]">GST0[0%]</option>
                     <option value="GST3[3%]">GST3[3%]</option>
                     <option value="GST5[5%]">GST5[5%]</option>
                     <option value="GST12[12%]">GST12[12%]</option>
                     <option value="GST18[18%]">GST18[18%]</option>
                     <option value="GST28[28%]">GST28[28%]</option>
                   </select>
                 </div>
                 <div className="col-md-6">
                   <span style={{ color: 'black' }}>Sales Price</span>
                   <input
                     type="number"
                     className="form-control border-secondary"
                     name="item_sale_price"
                     value={itemData.item_sale_price}
                     onChange={handleItemModalChange}
                   />
                 </div>
                 <div className="col-md-6">
                   <span style={{ color: 'black' }}>Purchase Price</span>
                   <input
                     type="number"
                     className="form-control border-secondary"
                     name="item_purchase_price"
                     value={itemData.item_purchase_price}
                     onChange={handleItemModalChange}
                   />
                 </div>
               </>
             ) : (
               <>
                 <div className="col-md-6">
                   <span style={{ color: 'black' }}>Sales Price</span>
                   <input
                     type="number"
                     className="form-control border-secondary"
                     name="item_sale_price"
                     value={itemData.item_sale_price}
                     onChange={handleItemModalChange}
                   />
                 </div>
                 <div className="col-md-6">
                   <span style={{ color: 'black' }}>Purchase Price</span>
                   <input
                     type="number"
                     className="form-control border-secondary"
                     name="item_purchase_price"
                     value={itemData.item_purchase_price}
                     onChange={handleItemModalChange}
                   />
                 </div>
               </>
             )}
           </div>
         ) : (
           <div id="credittemp">
             <div className="row">
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Opening Stock</span>
                 <input
                   type="number"
                   className="form-control border-secondary"
                   name="item_opening_stock"
                   value={itemData.item_opening_stock}
                   onChange={handleItemModalChange}
                 />
               </div>
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>At Price</span>
                 <input
                   type="number"
                   className="form-control border-secondary"
                   name="item_at_price"
                   value={itemData.item_at_price}
                   onChange={handleItemModalChange}
                 />
               </div>
             </div>
   
             <div className="row">
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Date</span>
                 <input
                   type="date"
                   className="form-control border-secondary"
                   name="item_date"
                   value={itemData.item_date}
                   onChange={handleItemModalChange}
                 />
               </div>
               <div className="col-md-6">
                 <span style={{ color: 'black' }}>Minimum Stocks to Maintain</span>
                 <input
                   type="text"
                   className="form-control border-secondary"
                   name="item_min_stock_maintain"
                   value={itemData.item_min_stock_maintain}
                   onChange={handleItemModalChange}
                 />
               </div>
             </div>
           </div>
         )}
   
         <div style={{ marginTop: '20px', textAlign: 'center' }}>
           {showGST ? (
             <button
               type="button"
               style={{
                 backgroundColor: 'rgb(61, 68, 101)',
                 color: 'white',
                 padding: '10px 20px',
                 borderRadius: '5px',
               }}
               onClick={() => setShowGST(false)}
             >
               NEXT
             </button>
           ) : (
             <>
               <button
                 type="button"
                 style={{
                   backgroundColor: 'rgb(61, 68, 101)',
                   color: 'white',
                   padding: '10px 20px',
                   borderRadius: '5px',
                   marginRight: '10px',
                 }}
                 onClick={() => setShowGST(true)}
               >
                 PREVIOUS
               </button>
               <button
                 type="button"
                 style={{
                   backgroundColor: 'rgb(61, 68, 101)',
                   color: 'white',
                   padding: '10px 20px',
                   borderRadius: '5px',
                 }}
                 onClick={handleItemSubmitModal}
               >
                 SAVE
               </button>
             </>
           )}
         </div>
       </form>
     </div>
   </Modal>
   <Modal
  isOpen={isAddUnitModalOpen}
  onRequestClose={() => setIsAddUnitModalOpen(false)}
  style={{
    content: {
      maxWidth: '50%',  // Reduced the width to 60%
      width: '500px',  // Adjusted width
      height: '300px', // Reduced height of the modal
      padding: '20px', // Adjust padding for smaller modal
      margin: '10vh auto', // Center horizontally and vertically
      borderRadius: '8px',
      backgroundColor: 'white',
      color: 'black',
      zIndex: '9999',
      overflow: 'hidden', // Prevent overflow of the modal itself
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: '999',
    },
  }}
>
  <style>
    {`
      /* Default behavior for desktop view */
      @media (min-width: 768px) {
        .modal-content {
          max-width: 80vw;
          margin: auto;
        }
      }

      /* Full-width modal for mobile view */
      @media (max-width: 767px) {
        .modal-content {
          max-width: 100vw;
          margin: 0;
          padding: 10px;
        }
      }
    `}
  </style>

  <div className="modal-header" style={{ color: 'white' }}>
    <h3 className="m-3" style={{ color: 'rgb(60, 61, 101)' }}>ADD UNIT</h3>
    <button type="button" onClick={() => setIsAddUnitModalOpen(false)}>
      <i className="bi bi-x"></i>
    </button>
  </div>

  <div className="bs p-4 rounded" style={{ paddingBottom: '10px', overflow: 'auto' }}> 
    <form id="addUnitForm" onSubmit={handleUnitSubmit} style={{ maxHeight: 'calc(100% - 60px)', overflowY: 'auto' }}>
      <span style={{ color: 'black' }}>New Unit</span>
      <input
        type="text"
        className="form-control"
        name="newUnit"
        id="newUnit"
        placeholder="Enter new unit"
        value={newUnit}
        onChange={(e) => {
          setNewUnit(e.target.value);
          setErrorFeedback(''); // Clear error on change
        }}
        required
        style={{ marginBottom: '10px' }} // Add a small margin between input and button
      />
      {errorFeedback && (
        <div className="text-danger m-2" id="errorFeedback">
          {errorFeedback}
        </div>
      )}
      <div className="mt-3" style={{ marginTop: '10px' }}>
        <button
          type="submit"
          className="btn"
          style={{
            backgroundColor: "rgb(60, 61, 101)",
            color: "white",
            padding: "8px 16px",
            borderRadius: "4px",
          }}
        >
          Add
        </button>
      </div>
    </form>
  </div>
</Modal>
    </div>
  );
}

export default EditDebitNote;
