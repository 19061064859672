import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/AdminStyles.css";
import "./styles/Layout.css";
import config from "../../functions/config";

function DebitNoteHistory() {
  const { id } = useParams();  // Get the debit note ID from the URL parameters
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");
  const [transactionHistory, setTransactionHistory] = useState([]);  // State to hold the transaction history
  const [loading, setLoading] = useState(true);  // To handle loading state

  // Function to fetch the transaction history for the given debit note ID
  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const response = await axios.get(`${config.base_url}/api/debit-transaction-history/${id}/`);
        setTransactionHistory(response.data);
        setLoading(false);  // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        setLoading(false);
      }
    };

    fetchTransactionHistory();
  }, [id]);

  useEffect(() => {
    const handleResize = () => setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container-scroller" style={{ background: "#8290c7" }}>
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2" style={{ width: panelWidth ,marginTop:'5%'}}>
          <div className="p-4" style={{ width: '70%', margin: '0 auto' }}>
            {/* Row for Credit Note History title and Back Button */}
            <div className="row" style={{ backgroundColor: 'rgb(61, 68, 101)' }}>
              <div className="col-md-6">
                <h3 className="mb-3 mt-3 text-white">Debit Note History</h3>
              </div>
              {/* Adjusted this div to ensure the back button is on the right */}
              <div className="col-md-6 d-flex justify-content-end pt-3 pb-3">
                <a href="javascript:window.history.back();" className="btn button-hover" style={{ backgroundColor: "white" }}>
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                </a>
              </div>
            </div>

            {/* Table displaying transaction history */}
            <div className="tabledivs table-responsive" style={{ 
              width: '104%', 
              paddingLeft: 0, 
              paddingRight: 0, 
              marginLeft: '-2%', 
              marginRight: 'auto', 
              minHeight: '25rem',  // Use minHeight to ensure the table grows
              overflowY: 'auto',    // Enable vertical scrolling if content overflows
              background: 'white'
            }}>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <table className="table" id="billtable" style={{ 
                  width: '100%', 
                  textAlign: 'center', 
                  border: '1px solid #3d4465', 
                }}>
                  <thead style={{ backgroundColor: 'rgb(61, 68, 101)' }}>
                    <tr>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>#</th>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>DATE</th>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>BILL NUMBER</th>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>NAME</th>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>AMOUNT</th>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>BY</th>
                      <th className="text-white" style={{ backgroundColor: '#3d4465' }}>ACTION</th>
                    </tr>
                  </thead>
                  <tbody className="table-bordered" style={{ backgroundColor: "white" }}>
  {transactionHistory.map((transaction, index) => (
    <tr key={transaction.id}>
       {/* Serial number */}
       <td>{index + 1}</td> {/* Adding 1 to index to start from 1 instead of 0 */}
       
       {/* Transaction Details */}
       <td style={{ color: 'black' }}>{transaction.transactiondate}</td>
       <td style={{ color: 'black' }}>{transaction.debitnote__billno}</td> {/* Bill Number */}
       <td style={{ color: 'black' }}>{transaction.debitnote__party__party_name}</td> {/* Party Name */}
       <td style={{ color: 'black' }}>{transaction.debitnote__grandtotal}</td> {/* Amount (Grand Total) */}
       <td style={{ color: 'black' }}>{transaction.company__company_name}</td> {/* Company Name */}
       <td style={{ color: transaction.action === "Created" ? "red" : "#154c79" }}>
         {transaction.action} {/* Action */}
       </td>
    </tr>
  ))}
</tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add the style directly within JSX for mobile responsiveness */}
      <style>
        {`
          /* For mobile devices */
          @media (max-width: 768px) {
            .tabledivs {
              width: 117% !important;  /* Ensure the table is 100% width on mobile */
              margin-left: -8% !important;  /* Remove negative margin on mobile */
              padding-left: 0 !important;
              padding-right: 0 !important;
              overflow-x: auto; /* Allow horizontal scrolling */
            }

            /* Adjust table layout */
            table {
              width: 100% !important;  /* Make the table full width on mobile */
              table-layout: auto !important;
            }

            th, td {
              padding: 8px !important;  /* Add padding for better readability */
              text-align: center !important;
            }

            /* Optionally, adjust the font size for mobile */
            th, td {
              font-size: 12px !important;
            }
          }
        `}
      </style>
    </div>
  );
}



export default DebitNoteHistory;
