import React, { useState,useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import config from "../../functions/config";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 


function EditCreditNote() {
  const { id } = useParams(); 
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");
  const [cardWidth, setCardWidth] = useState(window.innerWidth > 768 ? "50%" : "90%");
  const [creditNoteData, setCreditNoteData] = useState({
    party: "", // selected party
    partymob: "", // party phone
    address: "", // billing address
    other_parties: [], // array of other parties
    invoiceno: "", 
    items_under_company: [],
    party: "",               // Party Name
  partymob: "",            // Party Phone
  address: "",             // Billing Address
  retrn_no: "",            // Return Number
  invoiceno: "",           // Invoice Number
  invoice_date: "",        // Invoice Date
  date: "",                // Date
  supplyplace: "State",    // State of Supply
  subtotal: "",            // Subtotal
  cgst: "",                // CGST
  sgst: "",                // SGST
  igst: "",                // IGST
  taxamount: "",           // Tax Amount
  adjust: "",              // Adjustment
  grandtotal: "",          // Grand Total
  items: [],               // List of items
  other_parties: [],       // List of other parties
  invoice_numbers: [],     // List of invoice numbers
  items_under_company: [], // List of items under company
    
  })

  
  const [isPartySelected, setIsPartySelected] = useState(true);
  const [tableData, setTableData] = useState([
    { sn: 1, product: "", hsnSac: "", qty: "", price: "", tax: "", discount: "", total: "" },
    { sn: 2, product: "", hsnSac: "", qty: "", price: "", tax: "", discount: "", total: "" }
  ]);


  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCreditNote = async () => {
      try {
        const response = await axios.get(`${config.base_url}/api/creditnote/${id}/`);
        console.log(response.data)
        setCreditNoteData(response.data);  // Set the data
        setCreditNoteData({
          ...response.data,
          items_under_company: response.data.company_items,  // Set company_items to items_under_company
          opening_balance: response.data.opening_balance,
        });
        setLoading(false);  // Set loading to false after the data is fetched
      } catch (error) {
        console.error("Error fetching credit note", error);
        setLoading(false);  // Stop loading in case of an error
        alert("Failed to fetch credit note details.");
      }
    };

    fetchCreditNote();  // Fetch the data when component mounts
  }, [id]); 
  console.log("Items under company:", creditNoteData.items_under_company);

  const handlePartyChange = (event) => {
    const selectedParty = event.target.value;
    const selectedPartyData = creditNoteData.other_parties.find(party => party.party_name === selectedParty);

    if (selectedPartyData) {
        setCreditNoteData(prevData => ({
            ...prevData,
            party: selectedParty,
            partymob: selectedPartyData.phone_number,  // Set phone number
            address: selectedPartyData.billing_address,  // Set address
            opening_balance: selectedPartyData.opening_balance || 0, // Set opening balance
            invoice_numbers: selectedPartyData.invoice_numbers || [],  // Set available invoice numbers
           
            invoiceno: selectedPartyData.invoice_numbers && selectedPartyData.invoice_numbers.length > 0 
                ? selectedPartyData.invoice_numbers[0] 
                : "", 
                party_id: selectedPartyData.party_id,

        }));
    } else {
        setCreditNoteData(prevData => ({
            ...prevData,
            party: selectedParty,
            partymob: "",  // Reset phone number
            address: "",  // Reset address
            invoice_numbers: [],  // Reset invoice numbers
            opening_balance: 0, 
            invoiceno: "",  // Reset invoice number
            party_id: "" ,
        }));
    }
};

  


const handleInvoiceNumberChange = async (e) => {
  const selectedInvoiceNumber = e.target.value;
  setCreditNoteData({ ...creditNoteData, invoiceno: selectedInvoiceNumber });

  console.log("Invoice number selected:", selectedInvoiceNumber);
  const userID = Cookies.get("user_id");


  if (selectedInvoiceNumber) {
    try {
      // const response = await axios.get(`${config.base_url}/api/creditnote/invoice-details/${selectedInvoiceNumber}/`);
      const response = await axios.get(
        `${config.base_url}/api/creditnote/invoice-details/${selectedInvoiceNumber}/`,
        {
          params: { userid: userID }, // Query parameter for userId
        }
      );

      console.log("API Response:", response.data);

      setCreditNoteData((prevData) => ({
        ...prevData,
        invoiceno: selectedInvoiceNumber,  // Set invoice number
        invoice_date: response.data.invoice_date,  // Set invoice date
        items: response.data.items,  // Set items (if necessary)
      }));
       // Recalculate the subtotal after fetching the new items for the selected invoice
       const newSubtotal = calculateSubtotal(response.data.items);
       const newCGST = calculateTotalCGST(response.data.items);
      const newSGST = calculateTotalSGST(response.data.items);
      const newIGST = calculateTotalIGST(response.data.items);

      // Update the credit note data with new values
      setCreditNoteData((prevData) => {
        return {
          ...prevData,
          subtotal: newSubtotal,  // Update subtotal
          cgst: newCGST,          // Update CGST total
          sgst: newSGST,          // Update SGST total
          igst: newIGST,          // Update IGST total
        };
      });
 
    } catch (error) {
      console.error("Error fetching invoice details", error);
      alert("Failed to fetch invoice details.");
    }
  }
};

  const userID = Cookies.get("user_id");
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCreditNoteData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePartyToggle = () => {
    setIsPartySelected(!isPartySelected);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const staffIds = creditNoteData.transaction_history.map(transaction => transaction.staff);

    const formData = {
        id: creditNoteData.id,
        user:creditNoteData.user,
        
        retrn_no: creditNoteData.retrn_no,
        companyid: creditNoteData.company,
        party: creditNoteData.party_id,
        partymob: creditNoteData.partymob,
        invoiceno: creditNoteData.invoiceno,
        supplyplace: creditNoteData.supplyplace,
        
        subtotal:creditNoteData.subtotal,
        igst: creditNoteData.igst,
        cgst: creditNoteData.cgst,
        sgst: creditNoteData.sgst,
        taxamount: creditNoteData.taxamount,
        grandtotal: creditNoteData.grandtotal,
        adjust: creditNoteData.adjust || 0,
        invoice_date: creditNoteData.invoice_date,
        date: creditNoteData.date,
        items: creditNoteData.items.map(row => ({
          
          product_id: row.product__id || null,
          product: row.product__item_name || "",  // Ensure product field is filled
          qty: row.qty || 0,                     // Ensure quantity is filled
          total: row.total || 0,                 // Ensure total is calculated
          tax: row.gst || row.igst || 0,          // Ensure tax field is filled
          discount: row.discount || 0,           // Ensure discount is filled
        })),
        transaction_history: creditNoteData.transaction_history.map(transaction => ({
          action: transaction.action,
          transactiondate: transaction.transactiondate,
          staff: transaction.staff || null,  // Include staff ID if available
      })),
    };

    try {
        console.log("Form Data to Submit:", formData);

        const response = await fetch(`${config.base_url}/api/creditnoteedit/${creditNoteData.id}/`, {
            method: 'PUT',
            headers: {
              
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        // Check if the response is successful
        if (response.ok) {
            const data = await response.json();  // Parse response only once
            alert("Credit Note Updated Successfully");
            navigate("/credit_note")
            console.log('Credit Note Updated:', data);
        } else {
            const errorText = await response.text();  // Raw text error message
            console.log("Error response:", errorText);
            alert('Failed to update the credit note');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('There was an error saving the data. Please try again.');
    }
};


  
  const handleTableChange = (e, index, field) => {
    const value = e.target.value;
    const updatedTableData = [...creditNoteData.items]; // Make a copy of the items
  
    // Update the specific field in the row
    updatedTableData[index][field] = value;
  
    // If the field being changed is 'product__item_name', update related fields like hsnSac, price, etc.
    if (field === "product__item_name") {
      const selectedProduct = creditNoteData.items_under_company.find(
        (item) => item.item_name === value
      );
  
      if (selectedProduct) {
        updatedTableData[index] = {
          ...updatedTableData[index],
          product__item_name: selectedProduct.item_name,
          product__item_hsn: selectedProduct.item_hsn,
          product__item_at_price: selectedProduct.item_at_price,
          product__item_gst: selectedProduct.item_gst,  // Add other fields as needed
          product__item_igst: selectedProduct.item_igst,
          item_cgst:selectedProduct.item_cgst,
          qty: "",
          discount: 0, 
        };
      }
    }
       // If qty, price, tax, or discount changes, update total
       if (['qty', 'product__item_at_price', 'discount'].includes(field)) {
        const { qty, product__item_at_price, discount } = updatedTableData[index];
    
        // Calculate the total for this row
        const total = calculateTotal(qty, product__item_at_price, discount);
        updatedTableData[index].total = total;
      }
  
    // Update the state with the modified table data
    // setCreditNoteData((prevData) => ({
    //   ...prevData,
    //   items: updatedTableData
    // }));
    setCreditNoteData((prevData) => {
      const updatedData = { ...prevData, items: updatedTableData };
      // Recalculate the subtotal
      updatedData.subtotal = calculateSubtotal(updatedTableData); // Call calculateSubtotal after updating the table data
      
        updatedData.cgst = calculateTotalCGST(updatedTableData); // Recalculate CGST
        updatedData.sgst = calculateTotalSGST(updatedTableData); // Recalculate SGST
        updatedData.igst = calculateTotalIGST(updatedTableData); // Recalculate IGST
      
  
      return updatedData;
    });
  };
  
  // Calculate the total for a row based on qty, price, tax, and discount
const calculateTotal = (qty, price, discount) => {
  // Ensure all values are numbers before calculating
  const qtyValue = parseFloat(qty) || 0;
  const priceValue = parseFloat(price) || 0;
 
  const discountValue = parseFloat(discount) || 0;

  // Total formula: (Quantity * Price) + Tax - Discount
  return (qtyValue * priceValue) - discountValue;
};
const calculateSubtotal = (items) => {
  // Sum up the 'total' field of all items in the table
  return items.reduce((accum, row) => accum + (parseFloat(row.total) || 0), 0);
};

const calculateTotalCGST = (tableData) => {
  return tableData.reduce((accum, row) => {
    const { qty, product__item_at_price, item_cgst } = row;

    // Parse values safely to avoid NaN
    const qtyValue = parseFloat(qty) || 0;
    const priceValue = parseFloat(product__item_at_price) || 0;
    const cgstRate = parseFloat(item_cgst) || 0;

    // Calculate CGST: (Quantity * Price * Rate) / 100
    const cgstAmount = (qtyValue * priceValue * cgstRate) / 100;
    return accum + cgstAmount;
  }, 0).toFixed(2); // Ensure the total CGST is displayed with 2 decimal places
};


const calculateTotalSGST = (tableData) => {
  return tableData.reduce((accum, row) => {
    const { qty, product__item_at_price, item_sgst } = row;

    // Parse values safely to avoid NaN
    const qtyValue = parseFloat(qty) || 0;
    const priceValue = parseFloat(product__item_at_price) || 0;
    const sgstRate = parseFloat(item_sgst) || 0;

    // Calculate SGST: (Quantity * Price * SGST Rate) / 100
    const sgstAmount = (qtyValue * priceValue * sgstRate) / 100;
    return accum + sgstAmount;
  }, 0).toFixed(2); // Ensure the total SGST is displayed with 2 decimal places
};


const calculateTotalIGST = (tableData) => {
  return tableData.reduce((accum, row) => {
    const { qty, product__item_at_price, item_igst } = row;

    // Parse values safely to avoid NaN
    const qtyValue = parseFloat(qty) || 0;
    const priceValue = parseFloat(product__item_at_price) || 0;
    const igstRate = parseFloat(item_igst) || 0;

    // Calculate IGST: (Quantity * Price * IGST Rate) / 100
    const igstAmount = (qtyValue * priceValue * igstRate) / 100;
    return accum + igstAmount;
  }, 0).toFixed(2); // Ensure the total IGST is displayed with 2 decimal places
};




  
 // Add a new row to the table
const addRow = () => {
  const newRow = {
    sn: creditNoteData.items.length + 1,  // Automatically increment the SN based on current table length
    product__item_name: "",  // Default values for new row
    product__item_hsn: "",
    qty: "",
    product__item_at_price: "",
    gst: "",
    igst: "",
    discount: "",
    total: ""
  };

  // Update the creditNoteData.items state directly
  setCreditNoteData((prevData) => ({
    ...prevData,
    items: [...prevData.items, newRow]  // Add the new row to the existing items
  }));
};
const removeRow = (index) => {
  // Remove the row at the specified index
  const updatedTableData = creditNoteData.items.filter((_, rowIndex) => rowIndex !== index);

  // Update the creditNoteData.items state with the remaining rows
  setCreditNoteData((prevData) => ({
    ...prevData,
    items: updatedTableData
  }));
};


const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [partyModalData, setPartyModalData] = useState({
    partyname: "",
    gstin: "",
    mobile: "",
    gst: "",
    address: "",
    credit: "",
    balance: "",
  });
  const [showGST, setShowGST] = useState(true); // Show GST and Address section first by default
  
  
  // In the section where you toggle visibility
  const handleGSTSectionToggle = () => {
    setShowGST(!showGST); // This will toggle the visibility of the GST section
  };
  
  
    const handleAddPartyName = () => {
      // Open modal to add party details
      setIsModalOpen(true);
    };
    const handleSubmitModal = async () => {
      const userID = Cookies.get("user_id");  // Retrieve user_id from cookies
    
    if (!userID) {
      alert("User ID is missing. Please log in again.");
      return;  // If user_id is missing, stop the function.
    }
      const data = {
        party_name: partyModalData.partyname,
        phone_number: partyModalData.mobile,
        gstin: partyModalData.gstin,
        gst_type: partyModalData.gstType,
        billing_address: partyModalData.billingAddress,
        state: partyModalData.supplyState,
        email: partyModalData.email,
        opening_balance: partyModalData.openBalance,
        credit_limit: partyModalData.creditLimit,
        to_pay: partyModalData.toPay,
        to_recieve: partyModalData.toRecieve,
        date: partyModalData.date,
        company: creditNoteData.company,
        staff: creditNoteData.staff,
        user_id: userID,
      };
  
      console.log('Sending data to API:', data);
  
      try {
        // const response = await fetch('http://localhost:8000/api/parties/', {
          const response = await fetch(`${config.base_url}/api/parties/`, { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
    
        if (response.ok) {
          alert('Party added successfully!');
          setIsModalOpen(false); // Close the modal on success
          window.location.reload();
        } else {
          const error = await response.json();
          alert('Error: ' + error.message);
          console.log('Error from API:', error);
        }
      } catch (error) {
        alert('Error: ' + error.message);
        console.log('Error:', error);
      }
  };
  const [gstinError, setGstinError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const handleModalChange = (e) => {
      const { name, value } = e.target;
      setPartyModalData({
        ...partyModalData,
        [name]: value,
      });
       // Validate GSTIN
       if (name === 'gstin') {
        const gstinPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
        if (value && !gstinPattern.test(value)) {
          setGstinError('Invalid GSTIN. Please follow the format: 29APPCK7465F1Z1');
        } else {
          setGstinError('');
        }
      }
      if (name === 'mobile') {
        const mobilePattern = /^[0-9]{10}$/;
        if (value && !mobilePattern.test(value)) {
          setMobileError('Invalid mobile number. Please enter exactly 10 digits.');
        } else {
          setMobileError('');
        }
      }
    };
    
  const [isItemModalOpen,setIsItemModalOpen]=useState(false);
  const [itemModalData,setItemModalData]=useState({
   itemName:"",
   itemUnit:"",
   itemHsn:""
  })
  
  const handleAddItem=() =>{
    setIsItemModalOpen(true)
  }
  const [isTaxable, setIsTaxable] = useState(true);
  const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);

  const [newUnit, setNewUnit] = useState('');
  const [errorFeedback, setErrorFeedback] = useState('');
  const handleUnitSubmit = async (e) => {
    e.preventDefault();

    // Validate input
    if (!newUnit.trim()) {
      setErrorFeedback('Please enter a valid unit.');
      return;
    }

    try {
      const userID = Cookies.get("user_id"); // Retrieve the user ID from cookies


      
      const response = await fetch(`${config.base_url}/create-unit/?user_id=${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ unit_name: newUnit }),
      });

      const data = await response.json();

      if (response.ok) {
        alert('Unit added successfully!');
        setIsAddUnitModalOpen(false);
        setUnits((prevUnits) => [
          ...prevUnits,
          { id: data.newUnitId, unit_name: newUnit }, // Assuming the backend sends back the new unit's ID
        ]);

        setNewUnit(''); // Clear input field
        setIsItemModalOpen(true);
      } else {
        setErrorFeedback(data.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error adding unit:', error);
      setErrorFeedback('An error occurred while submitting the form.');
    }
  };
  
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await fetch(`${config.base_url}/get-units/?user_id=${userID}`);

        const data = await response.json();
        setUnits(data.units || []);
      } catch (error) {
        console.error('Error fetching units:', error);
      }
    };

    fetchUnits();
  }, []);


  const [itemData, setItemData] = useState({
    item_name: '',
    item_hsn: '',
    item_unit: '',
    item_type: '',
    item_taxable: '',
    item_gst: '',
    item_igst: '',
    item_sale_price: '',
    item_purchase_price: '',
    item_opening_stock: '',
    item_current_stock: '',
    item_stock_in_hand: '',
    item_at_price: '',
    item_date: '',
    item_min_stock_maintain: '',
    barcode: '',
  });
  
  const [itemHsnError, setItemHsnError] = useState('');
  
  
  const handleItemModalChange = (e) => {
    const { name, value } = e.target;
    setItemData({ ...itemData, [name]: value });
    if (name === 'item_hsn') {
      const hsnPattern = /^\d{6}$/; // Regex for exactly 6 digits
      if (value && !hsnPattern.test(value)) {
        setItemHsnError('Invalid HSN. Please enter exactly 6 digits.');
      } else {
        setItemHsnError('');
      }
    }
  };
  const handleItemSubmitModal = async () => {
    const userID = Cookies.get("user_id");
  
    if (!userID) {
      alert("User ID is missing. Please log in again.");
      return;
    }
  
    // Ensure the required fields are set
    if (!itemData.item_name || !itemData.item_hsn || !itemData.item_unit || !itemData.item_type) {
      alert('Please fill out all required fields.');
      return;
    }
  
    const data = {
      item_name: itemData.item_name,
      item_hsn: itemData.item_hsn,
      item_unit: itemData.item_unit,
      item_type: itemData.item_type,
      item_taxable: itemData.item_taxable,
      item_gst: itemData.item_gst,
      item_igst: itemData.item_igst,
      item_sale_price: itemData.item_sale_price,
      item_purchase_price: itemData.item_purchase_price,
      item_opening_stock: itemData.item_opening_stock,
      
      item_at_price: itemData.item_at_price,
      item_date: itemData.item_date,
      item_min_stock_maintain: itemData.item_min_stock_maintain,
      company: creditNoteData.company,
      staff: creditNoteData.staff,
      user_id: userID,
    };
  
    console.log('Sending data to API:', data);
  
    try {
      // const response = await fetch('http://localhost:8000/api/add-item/', {
      const response = await fetch(`${config.base_url}/api/add-item/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const error = await response.json();
        console.log('Error response:', error);  // Log the error response
        alert('Error: ' + (error.message || 'Unknown error occurred.'));
      } else {
        const responseData = await response.json();
        console.log('item after add',responseData)
        alert('Item added successfully!');
        
        setCreditNoteData(prevData => {
          return {
            ...prevData,
            items: [
              ...prevData.items,
              { 
                sn: prevData.items.length + 1, 
                product__item_name: responseData.item_name, 
                // Add other properties as needed from responseData
              }
            ],
            items_under_company: [
              ...prevData.items_under_company,
              {
                item_name: responseData.item_name,
                // Add other properties as needed
              }
            ]
          };
        });
        

        
        setIsItemModalOpen(false); // Close the modal on success
        window.location.reload();
      }
    } catch (error) {
      alert('Error: ' + error.message);
      console.log('Error:', error);
    }
  };
  

  const modalStyles = {
    content: {
      width: '50%',
      margin: 'auto',
      padding: '20px',
      backgroundColor: '#3d4465', // Set background color
      borderRadius: '8px',
      color: 'white', // White text for better contrast
      zIndex: '1050', // Ensure modal is above other content
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark semi-transparent background behind modal
      zIndex: '1040', // Ensure the overlay is below the modal content
    },
  };

  // Styles object for AddCreditNote component
  const styles = {
    outerContainer: {
      backgroundColor: "#8290c7",
      minHeight: "100vh",
    },
    container: {
      backgroundColor: "#8290c7",
    },
    mainPanel: {
      background: "#fff",
    },
    card: {
      boxShadow: "none",
      width: '90%', // Default width for mobile
      margin: '0 auto', // Center the card
    },
    cardBody: {
      backgroundColor: "white",
      padding: "30px",
    },
    heading: {
      backgroundColor: '#3d4465', // Background color for the heading
      color: 'white', // Text color for the heading (ensures text is white)
      padding: '15px', // Padding inside the heading
      margin: '0', // Remove default margin
      textAlign: 'left', // Align the heading text to the left
      width: '100%', // Full width
      zIndex: '1',
      marginBottom: '40px', // Space below the heading
      display: 'flex', // To allow both heading and button in the same row
      justifyContent: 'space-between', // Pushes the button to the right
      alignItems: 'center', // Vertically center the items
      fontWeight: 'bold', // Ensures the heading text is bold
    },
    headingText: {
      color: 'white', // Explicitly set text color to white
      margin: 0, // Remove margin
    },
    backButton: {
      backgroundColor: 'white', // White background for the button
      color: 'black', // Black text color for the button
      border: 'none', // Remove the border
      padding: '8px 16px', // Button padding
      cursor: 'pointer', // Pointer cursor on hover
      borderRadius: '4px', // Rounded corners for the button
      fontSize: '14px', // Font size for the button text
    },
    formSection: {
      marginTop: "40px",
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    formGroup: {
      flex: "3",
      marginBottom: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "2px",
      display: "block",
      textAlign: "left",
      position: "relative",
      zIndex: "3",
    },
    modallabel: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "2px",
      display: "block",
      textAlign: "left",
      position: "relative",
      zIndex: "3",
      marginTop: '1px',
    },
    input: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "45px",
      borderRadius: "5px",
      outline: "none",
    },
    select: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "55px",
      borderRadius: "5px",
      outline: "none",
    },
    centerButton: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    button: {
      backgroundColor: "rgb(218, 164, 48)",
      color: "white",
      padding: "10px 20px",
      borderRadius: "20px",
    },
    partySection: {
      display: "flex",
      alignItems: "center", 
      marginTop: "20px",
      marginBottom: "-5%",
    },
    partyLabel: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#333",
      marginRight: "10px", 
    },
    switchContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: '3%',
    },
    toggleSwitch: {
      width: "50px",
      height: "25px",
      position: "relative",
    },
    toggleInput: {
      opacity: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      cursor: "pointer",
    },
    toggleSlider: {
      position: "absolute",
      cursor: "pointer",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "#ccc",
      transition: "0.4s",
      borderRadius: "34px",
    },
    toggleSliderChecked: {
      backgroundColor: "#4c87af",
    },
    toggleSliderBefore: {
      content: '""',
      position: "absolute",
      height: "15px",
      width: "15px",
      borderRadius: "50%",
      left: "4px",
      bottom: "4px",
      backgroundColor: "white",
      transition: "0.4s",
    },
    toggleSliderBeforeChecked: {
      transform: "translateX(24px)",
    },
    partyFieldsRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    partyField: {
      flex: "3",
      marginBottom: "2px",
    },
    inputContainer: {
      display: "flex",
      alignItems: "center", // Vertically center the input and button
      gap: "8px", // Space between input and button
    },
    input: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "45px",
      borderRadius: "5px",
      outline: "none",
    },
   
    plusButton: {
      padding: "6px 12px", // Button padding
      backgroundColor: "#007bff", // Button background color
      color: "#fff", // Button text color
      border: "none", // No border for the button
      borderRadius: "4px", // Rounded corners for the button
      cursor: "pointer", // Pointer cursor on hover
      fontSize: "18px", // Font size of the plus sign
      display: "inline-flex", // Align button horizontally with the input
      alignItems: "center", // Vertically center the plus sign inside button
      justifyContent: "center", // Ensure plus sign is centered
      marginTop: "5px",
    },
    table: {
      width: "100%",
      marginTop: "30px",
      borderCollapse: "collapse",
    },
    th: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
      backgroundColor: "#3d4465",
      color: "white", 
    },
    tableinput: {
      width: "90%", // Input width is 90% of the cell width
      padding: "6px", // Add padding inside the input field
      margin: "0 auto", // Center the input within the cell
      border: "1px solid #ddd", // Border around the input
      borderRadius: "4px", // Rounded corners for the input
      boxSizing: "border-box", // Ensures padding is included within the width/height
      textAlign: "center", // Center-align the input text
      fontSize: "14px", // Set font size for inputs
    },
    td: {
      border: "1px solid #ddd", // Border for table cells
      padding: "8px", // Add padding inside cells
      textAlign: "center", // Center-align the cell content
      verticalAlign: "middle", // Vertically center-align the content
      boxSizing: "border-box", // Ensures padding does not affect the total width
    },
    addButton: {
      width: '40px',           // Set the width and height to make it square
      height: '40px',          // Equal to width for square shape
      borderRadius: '5px',     // Optional: Rounded corners, you can remove it if you want sharp corners
      backgroundColor: '#3d4465', // Your choice of background color
      color: 'white',          // Text color (for the plus sign)
      fontSize: '20px',        // Font size for the plus sign
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',      // Change the cursor to pointer on hover
      border: 'none',          // Remove border for a cleaner look
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
      marginTop: '2%',
    },
    plusSign: {
      fontSize: '24px',         // Ensure the plus sign fits well within the square
    },
    removeButton: {
      color: 'red',
      cursor: 'pointer',
      fontSize: '20px',
      textAlign: 'center',
      padding: '5px 10px',
    },
    
    rectangularButton: {
      padding: '10px 20px', // Padding inside each button
      border: 'none', // Remove default border
      borderRadius: '4px', // Optional: rounded corners for the buttons
      color: '#fff', // Text color for the buttons
      cursor: 'pointer', // Change cursor to pointer on hover
      fontSize: '16px', // Font size for better readability
      marginTop: '2%',
    },
    
    button: {
      // Your other button styles
      fontWeight: 'bold',
      padding: '10px 20px',
      marginRight: '10px',
    },
    plusButton: {
      padding: '6px 12px', // Button padding
      backgroundColor: '#3d4465', // Button background color
      color: '#fff', // Button text color
      border: 'none', // No border for the button
      borderRadius: '4px', // Rounded corners for the button
      cursor: 'pointer', // Pointer cursor on hover
      fontSize: '18px', // Font size of the plus sign
      display: 'inline-flex', // Align button horizontally with the input
      alignItems: 'center', // Vertically center the plus sign inside button
      justifyContent: 'center', // Ensure plus sign is centered
      
    },
    
  };
 
  const cardStyles = {
    backgroundColor: '#3d4465',  // Background color for the card (same as table heading)
    padding: '20px',
    width: cardWidth, 
    marginTop: '2%',
    borderRadius: '8px',  // Optional: Rounded corners for the card
    
  };
  
  const labelStyles = {
    color: 'white',  // White color for the label text
    textAlign: 'left',  // Align the label text to the left
    fontSize: '14px',  // Font size for the label text
    marginLeft: '2%',
    
  };
  
  const inputStyles = {
    textAlign: 'right',  // Align the input text to the right
    width: '70%',  // Input takes 70% of the width
    padding: '8px',  // Adjust padding for input fields
    borderRadius: '4px',  // Rounded corners for input fields
    border: '1px solid #ccc',  // Subtle border color
    fontSize: '14px',  // Font size for the input text
    marginLeft: '40%',
  };
  
  const formRowStyles = {
    display: 'flex',  // Use flexbox for horizontal alignment
    alignItems: 'center',  // Center the items vertically
    marginBottom: '10px',  // Space between rows
    
  };
  
  const formGroupStyles = {
    display: 'flex',  // Align label and input on the same row
    width: '100%',  // Full width for form group
    justifyContent: 'flex-start',  // Align items to the left
    marginBottom: '15px',  // Space between form fields
  };
  
  const textAreaStyles = {
    textAlign: 'right',  // Align the textarea text to the right
    width: '100%',  // Ensure textarea takes full width
    padding: '8px',  // Padding inside the textarea
    borderRadius: '4px',  // Rounded corners for textarea
    border: '1px solid #ccc',  // Subtle border
    fontSize: '14px',  // Font size for the text inside the textarea
  };
   useEffect(() => {
      const handleResize = () => {
        // Adjust panel width based on screen size
        setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
  
        // Adjust card width based on screen size (desktop vs mobile)
        if (window.innerWidth > 768) {
          setCardWidth("50%");  // For desktop
        } else {
          setCardWidth("105%");  // For mobile
        }
      };
  
      // Initial resize handling
      handleResize();
  
      // Add event listener to handle resizing
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);  
  

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", width: panelWidth }}
        >

<div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>
          <div className="card radius-15" >
          <div className="card-body" >
                  <h3 style={styles.heading}>
                    <b>Edit Credit Note</b>
                  </h3>

                  {/* Party Section */}
                  <div style={styles.partySection}>
                    <span style={styles.partyLabel}>Party</span>
                    <div style={styles.switchContainer}>
                      <label style={styles.toggleSwitch}>
                        <input
                          type="checkbox"
                          style={styles.toggleInput}
                          checked={isPartySelected}
                          onChange={handlePartyToggle}
                        />
                        <span
                          style={{
                            ...styles.toggleSlider,
                            ...(isPartySelected && styles.toggleSliderChecked),
                          }}
                        >
                          <span
                            style={{
                              ...styles.toggleSliderBefore,
                              ...(isPartySelected && styles.toggleSliderBeforeChecked),
                            }}
                          ></span>
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* Form Section */}
                  <form onSubmit={handleSubmit}>
                  <div className="my-5" style={styles.formSection}>
                    
                      {isPartySelected && (
                        <div className="form-row" >
                         <div className="form-group col-12 col-md-4" >

                         <span style={{ fontWeight: '500' }}> Party Name</span>
                            {/* <label htmlFor="partyName" style={styles.label}> */}
                              {/* Party Name */}
                            {/* </label> */}

                            <div style={styles.inputContainer}>
                            <select
                              id="partyName"
                              name="partyName"
                              className="form-control"
                              // style={styles.input}
                              value={creditNoteData.party}
                              // value={creditNoteData.party_id}
                              onChange={handlePartyChange}
                              required
                            >
                              <option value="">Select Party</option>

                              {/* <option value={creditNoteData.party}>{creditNoteData.party}</option>
                              {creditNoteData.other_parties.map((party, index) => (
                                <option key={index} value={party.party_name}>
                                  {party.party_name}
                                </option>
                              ))} */}



                              
{/* Display current selected party */}
{creditNoteData.party_id && (
  <option value={creditNoteData.party_id}>{creditNoteData.party}</option>
)}

{/* Show other party names under the same company */}
{creditNoteData.other_parties?.map((party, index) => (
  <option key={index} value={party.id}>
    {party.party_name}
  </option>
))}

                            </select>
                            {/* + Button */}
                            <button
                              type="button"
                              onClick={handleAddPartyName}
                              style={styles.plusButton}
                            >
                              +
                            </button>
                          </div>
                            
                          </div>
                          <div className="form-group col-12 col-md-4" >
                            {/* <label htmlFor="partyPhone" style={styles.label}>
                              Party Phone
                            </label> */}
                            <span style={{ fontWeight: '500' }}>Party Phone</span>
                            <input
                              type="text"
                              id="partyPhone"
                              name="partyPhone"
                              className="form-control"
                              // style={styles.input}
                              value={creditNoteData.partymob}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          {/* <div className="form-group" style={styles.partyField}>
                            <label htmlFor="billingAddress" style={styles.label}>
                              Billing Address
                            </label> */}
                            <div className="form-group col-12 col-md-4" >
                          <span style={{ fontWeight: '500' }}> Billing Address</span>
   
                            <input
                              type="text"
                              id="billingAddress"
                              name="billingAddress"
                              className="form-control"
                              // style={styles.input}
                              value={creditNoteData.address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          {creditNoteData.party && (
  <div className="form-group col-12" style={{ display: 'flex', alignItems: 'center',marginBottom:'20px'}}>
    <span style={{ fontWeight: '400', marginRight: '10px' }}>Available Balance:</span>
    <div style={{ fontWeight: '500', color: 'red' }}>
      {creditNoteData.opening_balance} {/* Display the opening balance */}
    </div>
  </div>
)}

                        </div>
                      )}

                      {/* Input Fields for Return No, Invoice No, Invoice Date */}
                      <div className="form-row">
                        <div className="form-group col-12 col-md-4" >
                          {/* <label htmlFor="returnNumber" style={styles.label}>
                            Return No
                          </label> */}
                           <span style={{ fontWeight: '500' }}>Return No</span>
                          <input
                            type="text"
                            id="returnNumber"
                            name="returnNumber"
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.retrn_no}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="form-group col-12 col-md-4">
                            {/* <label htmlFor="invoiceNumber" style={styles.label}>
                            Invoice Number
                            </label> */}
                            <span style={{ fontWeight: '500' }}>Invoice No</span>
                            {creditNoteData.invoice_numbers && creditNoteData.invoice_numbers.length > 0 ? (
                              <select
                              id="invoiceNumber"
                              name="invoiceNumber"
                              className="form-control"
                              // style={styles.input}
                              value={creditNoteData.invoiceno}
                              onClick={handleInvoiceNumberChange}  // This should be connected to the handler
                              required
                            >
                              <option value="">Select Invoice</option>
                              {creditNoteData.invoice_numbers.map((invoiceNo, index) => (
                                <option key={index} value={invoiceNo}>
                                  {invoiceNo}
                                </option>
                              ))}
                            </select>
                            
                            ) : (
                              <input
                                type="text"
                                id="invoiceNumber"
                                name="invoiceNumber"
                                className="form-control"
                                style={styles.input}
                                value={creditNoteData.invoiceno}
                                onChange={handleInputChange}
                                required
                              />
                            )}
                          </div>

                          <div className="form-group col-12 col-md-4">
                          {/* <label htmlFor="invoiceDate" style={styles.label}>
                            Invoice Date
                          </label> */}
                          <span style={{  fontWeight: '500' }}> Invoice Date </span>
                          <input
                            type="date"
                            id="invoiceDate"
                            name="invoiceDate"
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.invoice_date}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      {/* Date and State of Supply */}
                      <div className="form-row" >
                        <div className="form-group col-12 col-md-6">
                        <span style={{  fontWeight: '500' }}>  Date </span>
                          <input
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.date}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="form-group col-12 col-md-6">
                          {/* <label htmlFor="stateOfSupply" style={styles.label}>
                            State of Supply
                          </label> */}
                          <span style={{ fontWeight: '500' }}>State of Supply</span>
                          <select
                            id="stateOfSupply"
                            name="supplyplace"  // Make sure the name attribute matches the state variable
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.supplyplace}  // Ensure this binds to the correct state variable
                            onChange={handleInputChange}  // Trigger the change handler
                            required
                          >
                            <option value="State">State</option>
                            <option value="Other State">Other State</option>
                          </select>
                      </div>

                      </div>
                  {/* Table for Product Data */}
                  <div className="table-responsive " style={{ marginTop: "0px" }}>
                  <table style={styles.table}>
                    <thead>
                      <tr>
                        <th style={styles.th}>#</th>
                        <th style={styles.th}>Product</th>
                        <th style={styles.th}>HSN/SAC</th>
                        <th style={styles.th}>Qty</th>
                        <th style={styles.th}>Price</th>
                        <th style={styles.th}>Tax</th>
                        <th style={styles.th}>Discount</th>
                        <th style={styles.th}>Total</th>
                        <th style={styles.th}>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                            {Array.isArray(creditNoteData.items) && creditNoteData.items.length > 0 ? (
                              creditNoteData.items.map((row, index) => (
                                <tr key={index}>
                                  <td style={styles.td}>{row.sn}</td>
                             
                                  <td style={{ ...styles.td, display: 'flex', alignItems: 'center' }}>
                            <select
                              className="form-control"
                              value={row.product__item_name || ""}  // Make sure to handle empty or undefined
                              onChange={(e) => handleTableChange(e, index, 'product__item_name')}
                            >
                              <option value="">Select Product</option>
                              {creditNoteData.items_under_company.map((item) => (
                                <option key={item.item_name} value={item.item_name}>
                                  {item.item_name}
                                </option>
                              ))}
                            </select>
                            <button
    type="button"
    onClick={handleAddItem}
    style={{ ...styles.plusButton, marginLeft: '10px',marginTop:"0px"}} // Add margin to create space between the select and button
  >
    +
  </button>
                          </td>

                                  <td style={styles.td}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={row.product__item_hsn}
                                      onChange={(e) => handleTableChange(e, index, 'hsnSac')}
                                    />
                                  </td>
                                  <td style={styles.td}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={row.qty}
                                      onChange={(e) => handleTableChange(e, index, 'qty')}
                                    />
                                  </td>
                                  <td style={styles.td}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={row.product__item_at_price}
                                      onChange={(e) => handleTableChange(e, index, 'price')}
                                    />
                                  </td>
                              
                                <td style={styles.td}>
                                  
                                  {creditNoteData.supplyplace === "State" ? (
                                    <>
                                      <input
                                        type="text"
                                        value={row.product__item_gst}
                                        className="form-control"
                                        onChange={(e) => handleTableChange(e, index, "gst")}
                                        style={styles.tableinput}
                                      />
                                      <input
                                        type="hidden"
                                        value={row.item_cgst}
                                        onChange={(e) => handleTableChange(e, index, "item_cgst")}
                                        style={styles.tableinput}
                                      />
                                                  <input
                                        type="hidden"
                                        value={row.item_sgst}
                                        onChange={(e) => handleTableChange(e, index, "item_sgst")}
                                        style={styles.tableinput}
                                      />
                                    </>
                                  ) : (
                                    <>
                                    <input
                                      type="text"
                                      value={row.product__item_igst}
                                      className="form-control"
                                      onChange={(e) => handleTableChange(e, index, "igst")}
                                      style={styles.tableinput}
                                    />
                                    <input
                                        type="hidden"
                                        value={row.item_igst}
                                        onChange={(e) => handleTableChange(e, index, "item_igst")}
                                        style={styles.tableinput}
                                      />

                                    </>
                                  )}
                                </td>
                                  <td style={styles.td}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={row.discount}
                                      onChange={(e) => handleTableChange(e, index, 'discount')}
                                    />
                                  </td>
                                  <td style={styles.td}>
                                    <input type="number" className="form-control" value={row.total} />
                                  </td>
                                  <td style={styles.td}>
                                    <span style={styles.removeButton} onClick={() => removeRow(index)}>
                                      X
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" style={styles.td}>No items available</td>
                              </tr>
                            )}
                          </tbody>

                  </table></div>
                  <button type="button" onClick={addRow} style={styles.addButton}>
                    <span style={styles.plusSign}>+</span>
                  </button>

                  <div style={cardStyles}>
  

                    {/* First Row */}
                    <div className="form-row" style={formRowStyles}>
                      <div className="form-group" style={formGroupStyles}>
                        <label htmlFor="subtotal" style={labelStyles}>Subtotal:</label>
                        <input
                          type="number"
                          id="subtotal"
                          name="subtotal"
                          className="form-control"
                          style={{ ...inputStyles, textAlign: 'left' }}
                          
                          value={creditNoteData.subtotal}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                    </div>

                     {/* Second Row */}
                     {creditNoteData.supplyplace === "Other State" && (
  <div className="form-row" style={formRowStyles}>
    <div className="form-group" style={formGroupStyles}>
      <label htmlFor="totalIGST" style={labelStyles}>Total IGST:</label>
      <input
        type="number"
        id="totalIGST"
        name="totalIGST"
        className="form-control"
        style={{ ...inputStyles, textAlign: 'left' }}
        value={creditNoteData.igst} 
        onChange={handleInputChange}// Call the function to get the total IGST
        readOnly
      />
    </div>
  </div>
)}

          {creditNoteData.supplyplace === "State" && (
  <>
    <div className="form-row" style={formRowStyles}>
      <div className="form-group" style={formGroupStyles}>
        <label htmlFor="cgst" style={labelStyles}>CGST:</label>
        <input
          type="number"
          id="cgst"
          name="cgst"
          className="form-control"
          style={{ ...inputStyles, textAlign: 'left' }}
          value={creditNoteData.cgst}
          onChange={handleInputChange}
          readOnly
        />
      </div>
    </div>

    <div className="form-row" style={formRowStyles}>
      <div className="form-group" style={formGroupStyles}>
        <label htmlFor="sgst" style={labelStyles}>SGST:</label>
        <input
          type="number"
          id="sgst"
          name="sgst"
          className="form-control"
          style={{ ...inputStyles, textAlign: 'left' }}
          value={creditNoteData.sgst}
          onChange={handleInputChange}
          readOnly
        />
      </div>
    </div>
  </>
)}

                   
                     {/* Fourth Row */}
                     <div className="form-row" style={formRowStyles}>
  <div className="form-group" style={formGroupStyles}>
    <label htmlFor="taxAmount" style={labelStyles}>Tax Amount:</label>
    <input
      type="number"
      id="taxAmount"
      name="taxAmount"
      className="form-control"
      style={{ ...inputStyles, textAlign: 'left' }}
      value={
        creditNoteData.supplyplace === "State"
          ? (parseFloat(creditNoteData.cgst || 0) + parseFloat(creditNoteData.sgst || 0))
          : creditNoteData.supplyplace === "Other State"
          ? parseFloat(creditNoteData.igst || 0)
          : 0
      }
      onChange={(e) => setCreditNoteData({ ...creditNoteData, taxamount: e.target.value })}
      readOnly
    />
  </div>
</div>

                    {/* Fifth Row */}
                    <div className="form-row" style={formRowStyles}>
  <div className="form-group" style={formGroupStyles}>
    <label htmlFor="adjustment" style={labelStyles}>Adjustment:</label>
    <input
      type="number"
      id="adjustment"
      name="adjustment"
      className="form-control"
      style={{ ...inputStyles, textAlign: 'left' }}
      value={creditNoteData.adjust || 0}  // Default value is 0, but it's editable
      onChange={(e) => setCreditNoteData({ ...creditNoteData, adjust: e.target.value })}
    />
  </div>
</div>


                    {/* Sixth Row */}
                    <div className="form-row" style={formRowStyles}>
  <div className="form-group" style={formGroupStyles}>
    <label htmlFor="grandTotal" style={labelStyles}>Grand Total:</label>
    <input
      type="number"
      id="grandTotal"
      name="grandTotal"
      className="form-control"
      style={{ ...inputStyles, textAlign: 'left' }}
      value={(
        creditNoteData.supplyplace === "State"
          ? (parseFloat(creditNoteData.cgst || 0) + parseFloat(creditNoteData.sgst || 0) + parseFloat(creditNoteData.subtotal || 0) + parseFloat(creditNoteData.adjust || 0))
          : creditNoteData.supplyplace === "Other State"
          ? (parseFloat(creditNoteData.igst || 0) + parseFloat(creditNoteData.subtotal || 0) + parseFloat(creditNoteData.adjust || 0))
          : 0
      ).toFixed(2)}
      onChange={(e) => setCreditNoteData({ ...creditNoteData, grandtotal: e.target.value })}
      readOnly
    />
  </div>
</div>
                  </div>

                    <div style={styles.rectangularButtonContainer}>
                        <button   type="submit"
                          style={{ 
                            ...styles.button, 
                            backgroundColor: styles.th.backgroundColor, 
                            ...styles.rectangularButton 
                          }}
                        >
                          Save
                        </button>
                    </div>
         
                </div>
              </form>
              </div>
             
            </div>
          </div>
        </div>
        </div>
        </div>
      
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={{
        content: {
          padding: '20px',
          marginLeft: '15%',
          borderRadius: '8px',
          backgroundColor: 'rgba(61, 68, 101)', // Modal content background color
          color: 'white', // Text color in modal
          zIndex: '9999', // Ensure it stays on top of other content
          marginTop: '10vh',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background overlay color to darken the page behind the modal
          zIndex: '999', // Ensure the overlay stays below the modal
        },
      }}
    > <style>
        {`
          /* Default margin for larger screens */
          @media (max-width: 768px) {
            .ReactModal__Content {
              margin-left: 1% !important; /* Reduced left margin for mobile */
            }
          }
    
          /* Style for mobile views */
          @media (max-width: 767px) {
            .modal-content {
              padding: 10px;
            }
    
            /* Adjust form elements */
            .form-control {
              width: 100% !important;
            }
    
            /* Adjust radio buttons layout */
            .radio-group {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
            }
    
            .radio-group label {
              flex: 1 0 45%; /* Make the radio buttons responsive */
            }
    
            .modal-header h3 {
              font-size: 18px;
            }
    
            .modal-footer {
              text-align: center;
            }
            .save-button, .previous-button {
              width: 100% !important; /* Full width buttons on mobile */
              padding: 12px 0 !important; /* Adjust button padding */
              margin-bottom: 10px; /* Add some space between buttons */
            }
          }
        `}
      </style>
    
      <div className="modal-header" style={{ color: 'white' }}>
        <h3 className="m-3" style={{ color: 'white' }}>ADD PARTY</h3>
        <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} />
      </div>
    
      <div className="bg-light bs p-4 rounded mb-5">
        <form>
          <div className="row" style={{ marginLeft: '-1vh' }}>
            <div className="col-md-4">
              <span style={{ color: 'black' }}>Party Name</span>
              <input
                type="text"
                className="form-control border-secondary"
                id="partyname"
                name="partyname"
                value={partyModalData.partyname}
                onChange={handleModalChange}
                style={{ color: 'black' }}
                required
              />
            </div>
            {/* <div className="col-md-4">
              <span style={{ color: 'black' }}>GSTIN</span>
              <input
                type="text"
                className="form-control border-secondary"
                id="gstin"
                name="gstin"
                value={partyModalData.gstin}
                onChange={handleModalChange}
                placeholder="29APPCK7465F1Z1"
                style={{ color: 'black' }}
                required
              />
            </div> */}
            <div className="col-md-4">
              <span style={{ color: 'black' }}>GSTIN</span>
              <input
                type="text"
                className="form-control border-secondary"
                id="gstin"
                name="gstin"
                value={partyModalData.gstin}
                onChange={handleModalChange}
                placeholder="29APPCK7465F1Z1"
                style={{ color: 'black' }}
                required
              />
               {gstinError && <small style={{ color: 'red' }}>{gstinError}</small>}
            </div>
            <div className="col-md-4">
              <span style={{ color: 'black' }}>Mobile</span>
              <input
                type="number"
                className="form-control border-secondary"
                id="partyphno"
                name="mobile"
                value={partyModalData.mobile}
                onChange={handleModalChange}
                style={{ color: 'black' }}
                required
              />
                 {mobileError && <small style={{ color: 'red' }}>{mobileError}</small>}
            </div>
          
          </div>
    
          <div style={{ marginTop: '20px' }} />
          <div style={{ backgroundColor: 'rgba(130, 144, 199)', padding: '10px', borderRadius: '8px' }}>
            <div className="row pb-0 mb-0">
              <div className="col text-center">
                <button
                  type="button"
                  style={{
                    color: showGST ? 'black' : 'white', // Change text color based on selection
                    fontWeight: 'bold',
                    textDecoration: showGST ? 'underline' : 'none', // Underline for selected button
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowGST(true)}
                >
                  GST & ADDRESS
                </button>
              </div>
              <div className="col text-center">
                <button
                  type="button"
                  style={{
                    color: !showGST ? 'black' : 'white', // Change text color based on selection
                    fontWeight: 'bold',
                    textDecoration: !showGST ? 'underline' : 'none', // Underline for selected button
                    border: 'none',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => setShowGST(false)}
                >
                  CREDIT & BALANCE
                </button>
              </div>
            </div>
          </div>
    
          <div style={{ marginBottom: '20px' }} />
    
          {showGST ? (
            <div id="gsttemp">
              <div className="row">
                {/* GST Type */}
                <div className="col-md-6">
                  <span style={{ color: 'black' }}>GST Type</span>
                  <select
                    className="form-control border-secondary"
                    id="gstType"
                    name="gstType"
                    value={partyModalData.gstType}
                    onChange={handleModalChange}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  >
                    <option value="" hidden>Select</option>
                    <option value="Registered Party">Registered Party</option>
                    <option value="Unregistered or Consumer">Unregistered or Consumer</option>
                    <option value="Registered Business or Combosision">Registered Business or Combosision</option>
                  </select>
                </div>
    
                {/* Supply State */}
                <div className="col-md-6">
                      <span style={{ color: 'black' }}>Supply State</span>
                      <select
                        className="form-control border-secondary"
                        name="supplyState"
                        id="supplyState"
                        value={partyModalData.supplyState}
                        onChange={handleModalChange}
                        style={{ color: 'black', backgroundColor: 'white' }}
                      >
                        <option value="" hidden>Select</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                      </select>
                    </div>
              </div>
    
              <div className="row">
                {/* Email Field */}
                <div className="col-md-6">
                  <span style={{ color: 'black' }}>Email</span>
                  <input
                    type="email"
                    className="form-control border-secondary"
                    id="email"
                    name="email"
                    value={partyModalData.email}
                    onChange={handleModalChange}
                    style={{ color: 'black', backgroundColor: 'white' }}
                    required
                  />
                </div>
    
                {/* Billing Address (Right side) */}
                <div className="col-md-6">
                  <span style={{ color: 'black' }}>Billing Address</span>
                  <textarea
                    className="form-control border-secondary"
                    id="billingAddress"
                    name="billingAddress"
                    value={partyModalData.billingAddress}
                    onChange={handleModalChange}
                    rows="4"
                    style={{ color: 'black', backgroundColor: 'white' }}
                  ></textarea>
                </div>
              </div>
            </div>
          ) : (
            <div id="credittemp" style={{ backgroundColor: 'rgba(144, 211, 117, 0.098)' }}>
              <div className="row">
              <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                   
                {/* <div className="col-md-6 d-flex " style={{ whiteSpace: 'nowrap', justifyContent: 'flex-start' }}> */}
                <div className="d-flex flex-wrap align-items-center" style={{ whiteSpace: 'nowrap', justifyContent: 'flex-start' }}>
                      <span style={{ color: 'black', marginRight: '10px', marginLeft: '1px', textAlign: 'left' }}>Opening Balance</span> -
                      <div className="d-flex align-items-center ms-2 me-4" style={{ marginRight: '10px' }}>
                        <input
                          type="radio"
                          id="taxable"
                          name="item_taxable"
                          // checked={itemData.item_taxable === 'Taxable'}
                          // onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Taxable' } })}
                        />
                        <span style={{ color: 'black', marginLeft: '8px' }}>To Pay</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          id="nonTaxable"
                          name="item_taxable"
                          // checked={itemData.item_taxable === 'Non-Taxable'}
                          // onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Non-Taxable' } })}
                        />
                        <span style={{ color: 'black', marginLeft: '8px' }}>To Receive</span>
                      </div>
                  </div>
    
    
    
    
                  
                  <input
                    type="number"
                    className="form-control border-secondary"
                    id="openbalance"
                    name="openBalance"
                    value={partyModalData.openBalance}
                    onChange={handleModalChange}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  />
                </div>
                <div className="col-md-6">
                  <span style={{ color: 'black' }}>Credit Limit</span>
                  <input
                    type="number"
                    className="form-control border-secondary"
                    id="creditLimit"
                    name="creditLimit"
                    value={partyModalData.creditLimit}
                    onChange={handleModalChange}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  />
                </div>
              </div>
    
              <div className="row">
                <div className="col-md-6">
                  <span style={{ color: 'black' }}>Date</span>
                  <input
                    type="date"
                    className="form-control border-secondary"
                    id="partydate"
                    name="date"
                    value={partyModalData.date}
                    onChange={handleModalChange}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  />
                </div>
              </div>
            </div>
          )}
    
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {showGST ? (
              <button
                type="button"
                style={{
                  backgroundColor: 'rgb(61, 68, 101)',
                  color: 'white',
                  width: '150px',
                  padding: '10px 20px',
                  borderRadius: '5px',
                }}
                onClick={() => setShowGST(false)}
              >
                NEXT
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="previous-button"
                  style={{
                    backgroundColor: 'rgb(61, 68, 101)',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    width: '150px',
                    marginRight: '10px',
                  }}
                  onClick={() => setShowGST(true)}
                >
                  PREVIOUS
                </button>
                <button
                  type="button"
                   className="save-button"
                  style={{
                    backgroundColor: 'rgb(61, 68, 101)',
                    color: 'white',
                    padding: '10px 20px',
                    width: '150px',
                    borderRadius: '5px',
                  }}
                  onClick={handleSubmitModal}
                >
                  SAVE
                </button>
              </>
              
            )}
          </div>
        </form>
      </div>
    </Modal>
    
    
      
      
      
      <Modal
        isOpen={isItemModalOpen}
        onRequestClose={() => setIsItemModalOpen(false)}
        style={{
          content: {
            padding: '20px',
            marginLeft: '15%',
            borderRadius: '8px',
            backgroundColor: 'rgba(61, 68, 101)', // Modal content background color
            color: 'white', // Text color in modal
            zIndex: '9999', // Ensure it stays on top of other content
            marginTop: '10vh',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background overlay color to darken the page behind the modal
            zIndex: '999', // Ensure the overlay stays below the modal
          },
        }}
      >
        <style>
          {`
            /* Default behavior for desktop view */
            @media (min-width: 768px) {
              .modal-content {
                max-width: 80vw;
                margin: auto;
              }
            }
      
            /* Full-width modal for mobile view */
            @media (max-width: 767px) {
              .modal-content {
                max-width: 100vw;
                margin: 0;
                padding: 10px;
              }
                .ReactModal__Content {
                margin-left: 1% !important; /* Reduce left margin for mobile view */
              }
                 .save-button, .previous-button {
                width: 100% !important; /* Full width buttons on mobile */
                padding: 12px 0 !important; /* Adjust button padding */
                margin-bottom: 10px; /* Add some space between buttons */
              }
            }
          `}
        </style>
        <div className="modal-header" style={{ color: 'white' }}>
          <h3 className="m-3" style={{ color: 'white' }}>ADD ITEM</h3>
          <button type="button" className="btn-close" onClick={() => setIsItemModalOpen(false)} />
        </div>
      
        <div className="bg-light bs p-4 rounded mb-5">
          <form>
            <div className="row" style={{ marginLeft: '-1vh' }}>
              <div className="col-md-3">
                <span style={{ color: 'black' }}>Item Type</span>
                <select
                  style={{ color: 'black' }}
                  className="form-control border-secondary"
                  name="item_type"
                  value={itemData.item_type}
                  onChange={handleItemModalChange}
                  required
                >
                  <option value="" hidden>Select Type</option>
                  <option value="Goods">Products</option>
                  <option value="Services">Services</option>
                </select>
              </div>
              <div className="col-md-3">
                <span style={{ color: 'black' }}>Item Name:</span>
                <input
                  type="text"
                  className="form-control border-secondary"
                  name="item_name"
                  value={itemData.item_name}
                  onChange={handleItemModalChange}
                  placeholder="Item Name"
                  style={{ color: 'black' }}
                  required
                />
              </div>
              <div className="col-md-3">
                <span style={{ color: 'black' }}>Item HSN</span>
                <input
                  type="text"
                  className="form-control border-secondary"
                  name="item_hsn"
                  value={itemData.item_hsn}
                  onChange={handleItemModalChange}
                  placeholder="ITEM HSN"
                  style={{ color: 'black' }}
                  required
                />
                 {itemHsnError && <small style={{ color: 'red' }}>{itemHsnError}</small>}
              </div>
              <div className="col-md-3">
                <span style={{ color: 'black' }}>Unit</span>
                <div className="d-flex align-items-center">
                  <select
                    style={{ color: 'black' }}
                    className="form-control border-secondary"
                    name="item_unit"
                    value={itemData.item_unit}
                    onChange={handleItemModalChange}
                    required
                  >
                    <option value="" hidden>Select Unit</option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.unit_name}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    className="btn"
                    name="unit-btn"
                    onClick={() => {
                      setIsAddUnitModalOpen(true);
                      setIsItemModalOpen(false);
                    }}
                    style={{
                      backgroundColor: 'rgb(60, 61, 101)',
                      marginLeft: '10px', // space between select and button
                      color: 'white',
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
      
            <div style={{ marginTop: '20px' }} />
            <div style={{ backgroundColor: 'rgba(130, 144, 199)', padding: '10px', borderRadius: '8px' }}>
              <div className="row pb-0 mb-0">
                <div className="col text-center">
                  <button
                    type="button"
                    style={{
                      color: showGST ? 'black' : 'white', // Change text color based on selection
                      fontWeight: 'bold',
                      textDecoration: showGST ? 'underline' : 'none', // Underline for selected button
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowGST(true)}
                  >
                    PRICING
                  </button>
                </div>
                <div className="col text-center">
                  <button
                    type="button"
                    style={{
                      color: !showGST ? 'black' : 'white', // Change text color based on selection
                      fontWeight: 'bold',
                      textDecoration: !showGST ? 'underline' : 'none', // Underline for selected button
                      border: 'none',
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => setShowGST(false)}
                  >
                    STOCKS
                  </button>
                </div>
              </div>
            </div>
      
            <div style={{ marginBottom: '20px' }} />
      
            {showGST ? (
              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="d-flex align-items-center me-4">
                    <input
                      type="radio"
                      id="taxable"
                      name="item_taxable"
                      checked={itemData.item_taxable === 'Taxable'}
                      onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Taxable' } })}
                    />
                    <span style={{ color: 'black', marginLeft: '8px' }}>Taxable</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="nonTaxable"
                      name="item_taxable"
                      checked={itemData.item_taxable === 'Non-Taxable'}
                      onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Non-Taxable' } })}
                      style={{ marginLeft: '10px' }}
                    />
                    <span style={{ color: 'black', marginLeft: '10px' }}>NonTaxable</span>
                  </div>
                </div>
                {itemData.item_taxable === 'Taxable' ? (
                  <>
                    <div className="col-md-6 mb-5">
                      <span style={{ color: 'black' }}>IGST</span>
                      <select
                        className="form-control border-secondary"
                        name="item_igst"
                        value={itemData.item_igst}
                        onChange={handleItemModalChange}
                      >
                        <option value="IGST0[0%]">IGST0[0%]</option>
                        <option value="IGST3[3%]">IGST3[3%]</option>
                        <option value="IGST5[5%]">IGST5[5%]</option>
                        <option value="IGST12[12%]">IGST12[12%]</option>
                        <option value="IGST18[18%]">IGST18[18%]</option>
                        <option value="IGST28[28%]">IGST28[28%]</option>
                      </select>
                    </div>
                    <div className="col-md-6 mb-5">
                      <span style={{ color: 'black' }}>GST</span>
                      <select
                        className="form-control border-secondary"
                        name="item_gst"
                        value={itemData.item_gst}
                        onChange={handleItemModalChange}
                      >
                        <option value="GST0[0%]">GST0[0%]</option>
                        <option value="GST3[3%]">GST3[3%]</option>
                        <option value="GST5[5%]">GST5[5%]</option>
                        <option value="GST12[12%]">GST12[12%]</option>
                        <option value="GST18[18%]">GST18[18%]</option>
                        <option value="GST28[28%]">GST28[28%]</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <span style={{ color: 'black' }}>Sales Price</span>
                      <input
                        type="number"
                        className="form-control border-secondary"
                        name="item_sale_price"
                        value={itemData.item_sale_price}
                        onChange={handleItemModalChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <span style={{ color: 'black' }}>Purchase Price</span>
                      <input
                        type="number"
                        className="form-control border-secondary"
                        name="item_purchase_price"
                        value={itemData.item_purchase_price}
                        onChange={handleItemModalChange}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-6">
                      <span style={{ color: 'black' }}>Sales Price</span>
                      <input
                        type="number"
                        className="form-control border-secondary"
                        name="item_sale_price"
                        value={itemData.item_sale_price}
                        onChange={handleItemModalChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <span style={{ color: 'black' }}>Purchase Price</span>
                      <input
                        type="number"
                        className="form-control border-secondary"
                        name="item_purchase_price"
                        value={itemData.item_purchase_price}
                        onChange={handleItemModalChange}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div id="credittemp">
                <div className="row">
                  <div className="col-md-6">
                    <span style={{ color: 'black' }}>Opening Stock</span>
                    <input
                      type="number"
                      className="form-control border-secondary"
                      name="item_opening_stock"
                      value={itemData.item_opening_stock}
                      onChange={handleItemModalChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <span style={{ color: 'black' }}>At Price</span>
                    <input
                      type="number"
                      className="form-control border-secondary"
                      name="item_at_price"
                      value={itemData.item_at_price}
                      onChange={handleItemModalChange}
                    />
                  </div>
                </div>
      
                <div className="row">
                  <div className="col-md-6">
                    <span style={{ color: 'black' }}>Date</span>
                    <input
                      type="date"
                      className="form-control border-secondary"
                      name="item_date"
                      value={itemData.item_date}
                      onChange={handleItemModalChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <span style={{ color: 'black' }}>Minimum Stocks to Maintain</span>
                    <input
                      type="text"
                      className="form-control border-secondary"
                      name="item_min_stock_maintain"
                      value={itemData.item_min_stock_maintain}
                      onChange={handleItemModalChange}
                    />
                  </div>
                </div>
              </div>
            )}
      
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              {showGST ? (
                <button
                  type="button"
                  style={{
                    backgroundColor: 'rgb(61, 68, 101)',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                  }}
                  onClick={() => setShowGST(false)}
                >
                  NEXT
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="previous-button"
                    style={{
                      backgroundColor: 'rgb(61, 68, 101)',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      marginRight: '10px',
                      width: '150px',
                    }}
                    onClick={() => setShowGST(true)}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                     className="save-button"
                    style={{
                      backgroundColor: 'rgb(61, 68, 101)',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      width: '150px',
                    }}
                    onClick={handleItemSubmitModal}
                  >
                    SAVE
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </Modal>
      
      <Modal
        isOpen={isAddUnitModalOpen}
        onRequestClose={() => setIsAddUnitModalOpen(false)}
        style={{
          content: {
            maxWidth: '50%', // Increase the width for a larger modal
            width: '500px', // Default width
            height: '280px', // Reduced height of the modal
            padding: '20px', // Adjust padding for smaller modal
            margin: '10vh auto', // Center horizontally and vertically
            borderRadius: '8px',
            backgroundColor: 'white',
            color: 'black',
            zIndex: '9999',
            overflow: 'hidden', // Prevent overflow of the modal itself
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: '999',
          },
        }}
      >
        <style>
          {`
            /* Default behavior for desktop view */
            @media (min-width: 768px) {
              .modal-content {
                max-width: 80vw;
                margin: auto;
              }
            }
      
            /* Full-width modal for mobile view */
            @media (max-width: 767px) {
              .modal-content {
                max-width: 100vw;
                margin: 0;
                padding: 10px;
              }
            }
          `}
        </style>
      
        <div className="modal-header" style={{ color: 'white' }}>
          <h3 className="m-2" style={{ color: 'rgb(60, 61, 101)' }}>ADD UNIT</h3>
          <button type="button" onClick={() => setIsAddUnitModalOpen(false)}>
            <i className="bi bi-x"></i>
          </button>
        </div>
      
        <div className="bs p-4 rounded" style={{ paddingBottom: '10px', overflow: 'auto' }}> 
          <form id="addUnitForm" onSubmit={handleUnitSubmit} style={{ maxHeight: 'calc(100% - 60px)', overflowY: 'auto' }}>
            <span style={{ color: 'black' }}>New Unit</span>
            <input
              type="text"
              className="form-control"
              name="newUnit"
              id="newUnit"
              placeholder="Enter new unit"
              value={newUnit}
              onChange={(e) => {
                setNewUnit(e.target.value);
                setErrorFeedback(''); // Clear error on change
              }}
              required
              style={{ marginBottom: '10px' }} // Add a small margin between input and button
            />
            {errorFeedback && (
              <div className="text-danger m-2" id="errorFeedback">
                {errorFeedback}
              </div>
            )}
            <div className="mt-3" style={{ marginTop: '10px' }}>
              <button
                type="submit"
                className="btn"
                style={{
                  backgroundColor: "rgb(60, 61, 101)",
                  color: "white",
                  padding: "8px 16px",
                  borderRadius: "4px",
                }}
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
      

</div>

    
  );
}

export default EditCreditNote;
