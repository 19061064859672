import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/AdminStyles.css";
import "./styles/Layout.css";
import { useParams } from 'react-router-dom';
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";

function ViewDebitNote() {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");
  const [dropdownVisible, setDropdownVisible] = useState(false); // Manage dropdown visibility
  const [activeTable, setActiveTable] = useState("template"); // State to toggle between template and slip tables
  const divBackgroundColor = "#003366";
  const [debitNote, setDebitNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [templateSelected, setTemplateSelected] = useState(true); 
  const totalItems = 100; // example, replace with your actual data
  const itemsPerPage = 10;
   
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  
  // Calculate total pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // State for current page
  const [currentPage, setCurrentPage] = useState(1);
  // Handle pagination
  const handlePagination = (action) => {
    if (action === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (typeof action === 'number') {
      setCurrentPage(action);
    }
  };

  const getPageNumbers = () => {
    let start = Math.max(currentPage - 1, 1); // Start from current page - 1 but not less than 1
    let end = Math.min(currentPage + 1, totalPages); // End at current page + 1 but not greater than total pages
  
    // Adjust if near the beginning or end
    if (currentPage === 1) {
      end = Math.min(3, totalPages); // Show pages 1, 2, 3 if on the first page
    } else if (currentPage === totalPages) {
      start = Math.max(totalPages - 2, 1); // Show last three pages if on the last page
    }
  
    // Generate page numbers
    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
  
    return pages;
  };


  useEffect(() => {
    const handleResize = () => setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to toggle the visibility of the dropdown
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Handle switching between template and slip tables
  const handleTableSwitch = (tableName) => {
    setActiveTable(tableName);
  };

  // Function to get styles for buttons
  const getButtonStyle = (buttonType) => {
    return buttonType === activeTable
      ? {
          backgroundColor: "white", // Active button gets white background
          color: divBackgroundColor, // Text color is same as div background
          border: `1px solid ${divBackgroundColor}`, // Border is the same color as the div
        }
      : {
          backgroundColor: divBackgroundColor, // Inactive button background same as div
          color: "white", // Text color is white for inactive buttons
          border: `1px solid white`, // Border white for inactive buttons
        };
  };

  // Function to get styles for the section containing buttons (changing background based on active button)
  const getSectionBackgroundColor = () => {
    return activeTable === "template" ? "#e6f0ff" : "#f2f2f2"; // Change section background color based on active button
  };
  useEffect(() => {
    // Fetch debit note details
    axios
      .get(`${config.base_url}/api/view-debit-note/${id}/`)
      .then((response) => {
        setDebitNote(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching debit note details:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!debitNote) {
    return <div>No data available.</div>;
  }

  // Function to handle the Email modal (you can customize this)
  const toggleEmailModal = () => {
    alert("Email functionality not implemented yet.");
  };
  // Function to toggle between TEMPLATE and SLIP
  const handleTabClick = (tab) => {
    setTemplateSelected(tab === 'template');
  };

  const styles = {
    templatediv: {
      borderRadius: "2vh", 
      padding: "20px",
      width: "80%",  // Reduced width to 80% of the parent container
      margin: "auto" 
    },
    header: {
      background: "#3d4465", 
      color: "white", 
      textAlign: "center", 
      padding: "10px", 
      width: "100%"
    },
    topContent: {
      display: "flex", 
      justifyContent: "space-between", 
      padding: "0", 
      flexWrap: "wrap"
    },
    topLeft: {
      flex: "1", 
      padding: "10px"
    },
    section: {
      marginTop: "5px", 
      padding: "0"
    },
    table: {
      width: "100%",
      fontSize: "14px",
      borderCollapse: "collapse"
    },
    tableHeader: {
      background: "#3d4465",    // Use !important to ensure the background color is applied
      color: "white !important",
    },
    tableRow: {
      textAlign: "left", 
      borderBottom: "1px solid #ddd"
    },
    tableCell: {
      padding: "8px"
    },
    paymentInfo: {
      display: "flex", 
      justifyContent: "flex-end", 
      padding: "0"
    },
    column: {
      padding: "10px"
    },
    bold: {
      fontWeight: "bold"
    },
    grandTotal: {
      fontWeight: "bold",
      fontSize: "16px"
    },
    // Responsive Styles
    responsiveTable: {
      overflowX: "auto",
      display: "block",
      '-webkit-overflow-scrolling': "touch"
    },
    responsiveText: {
      fontSize: "12px"
    }
  };
  const labelStyle = {
    fontSize: '14px',
    fontWeight: '600',
    color: 'black',
    marginBottom: '5px',
    display: 'block'
  };
  function reportPDF() {
  
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Debit Note" ,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  } 
  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");
  
    // Add CSS for hiding buttons on print
    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
      <style>
          @media print {
        /* Hide elements when printing */
        .sidebar, .navbar, .button-hover, .tab-selection {
          display: none !important;
        }

        /* Display only the content for printing */
        .print-only {
          display: block !important;
        }

        /* Adjust layout for printing */
        .main-panel {
          margin-left: 0 !important;
        }
        
        .no-print {
          display: none !important;
        }

      </style>
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  function handleShareEmail(e) {
    e.preventDefault();
  
    var emailsString = emailIds.trim();
  
    var emails = emailsString.split(",").map(function (email) {
      return email.trim();
    });
  
    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  
    var invalidEmails = [];
    if (emailsString === "") {
      alert("Enter valid email addresses.");
    } else {
      for (var i = 0; i < emails.length; i++) {
        var currentEmail = emails[i];
  
        if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
          invalidEmails.push(currentEmail);
        }
      }
  
      if (invalidEmails.length > 0) {
        alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      } else {
        var em = {
        
          email_ids: emailIds,
          email_message: emailMessage,
        };
  
        axios
          .post(`${config.base_url}/share_debit_note_via_email/${id}`, em)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (err.response && err.response.data && !err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  }
  
  
  return (
    <div className="container-scroller">
      {/* Navbar */}
      <Navbar />

      <div className="container-fluid page-body-wrapper d-flex">
        {/* Sidebar */}
        <Sidebar />
        
        {/* Main Content */}
        <div
      className="main-panel px-3 py-2"
      style={{ background: "#a9a9a961", width: panelWidth }}
    >
          <div className="content-wrapper py-3" >
          <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>
              <div className="card shadow-sm border-0">
                <div className="card-body" style={{ backgroundColor: "#3d4465", color: "white" }}>
                  <div className="row mt-3">
                    <div className="col-md-7" style={{ position: "relative", bottom: "10px" }}>
                      <h3 className="mb-4 text-white">DEBIT NOTE DETAILS</h3>
                    </div>

                    <div className="col-md-4" style={{ color: "#3d4465", position: "relative", bottom: "10px" }}>
                      {/* Button Container */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {/* Print Button */}
                        <button
                          className="btn ml-1 button-hover"
                          style={{
                            backgroundColor: "white",
                            color: "#3d4465",
                            border: "1px solid #3d4465",
                            display: "flex",
                            alignItems: "center",
                            padding: "8px 16px",
                          }}
                          
                          onClick={printSection}
                        >
                          Print
                          
                          <i className="bi bi-printer" style={{ marginLeft: "8px" }}  aria-hidden="true"></i>
                        </button>
                        
                        {/* PDF Button */}
                        <button
                          className="btn ml-1 button-hover"
                          style={{
                            backgroundColor: "white",
                            color: "#3d4465",
                            border: "1px solid #3d4465",
                            display: "flex",
                            alignItems: "center",
                            padding: "8px 16px",
                          }}
                          id="pdflink"
                          onClick={reportPDF}
                        >
                          Pdf
                          
                          <i className="bi bi-file-pdf"  style={{ marginLeft: "8px" }} aria-hidden="true"></i>
                        </button>

                        <button
  className="btn ml-1 button-hover"
  style={{
    backgroundColor: "white",
    color: "#3d4465",
    border: "1px solid #3d4465",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  }}
  onClick={toggleDropdown}
>
  Share
  <i className="bi bi-share" style={{ marginLeft: "8px" }} aria-hidden="true"></i>
</button>

{/* Dropdown Menu */}
{dropdownVisible && (
  <div className="dropdown" style={{ position: "relative" }}>
    <div
      className="dropdown-menu show"
      aria-labelledby="dropdownMenuButton"
      style={{
        position: "absolute",
        top: "100%",  // Position dropdown directly below the button
        left: "0",    // Align dropdown to the left of the button
        zIndex: 1000  // Ensure the dropdown appears above other content
      }}
    >
      <a
        className="dropdown-item"
        href="https://api.whatsapp.com/send?text=https%3A//multiuserindia.infoxtechnologies.com/template1/25"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#3d4465" }}
      >
        WhatsApp
      </a>
      <a
        className="dropdown-item"
        data-toggle="modal" data-target="#shareToEmail"
        style={{ color: "#3d4465" }}
      >
        Email
      </a>
    </div>
  </div>
)}
<a
                      className="ml-1 text-white"
                      onClick={() => navigate("/debitnote")}
                     style={{textAlign:"right",fontSize:"large",color:"white"}}
                    >
                      <i class="fa fa-times mt-2" aria-hidden="true"></i>
                    </a>
</div>

                    </div>
                    <br />
                      {/* Tab Selection */}
                <div className="card-body" >
                  <div className="row pb-0 mb-0 mt-1"  >
                    <div
                      id="templates"
                      className={`col text-center pb-1 pt-3 tab ${templateSelected ? 'selected-column' : ''}`}
                      style={{ borderBottom: "none",cursor: 'pointer',marginTop:"-2vh", fontWeight: 'bold',backgroundColor: templateSelected ? 'white' : 'transparent', }}
                      onClick={() => handleTabClick('template')}
                    >
                      <a className="fw-bolder fs-5" style={{ color: "black" }}>TEMPLATE</a>
                    </div>
                    <br /><br /><br />
                    <div
                      id="slip"
                      className={`col text-center pb-1 pt-3 ${!templateSelected ? 'selected-column' : ''}`}
                      style={{ borderBottom: "none", cursor: 'pointer',marginTop:"-2vh", fontWeight: 'bold',backgroundColor: templateSelected ? 'transparent' : 'white', }}
                      onClick={() => handleTabClick('slip')}
                    >
                      <a className="fw-bolder fs-5" style={{ color: "black" }}>DEBIT SLIP</a>
                    </div>
                    <br /><br /><br />
                  </div>
                  
                  </div>
                  
                </div>

              

                  <div className="card shadow-lg mt-3" style={{ maxWidth: '100%', margin: '0 auto', padding: '10px' }}>

                  {/* Template Section */}
                {/* Template Section */}
{templateSelected && (
   <div className="card shadow-lg mt-0">
                   
         {currentPage === 1 && (
  <div id="templatediv" style={{borderRadius:"2vh"}} >
  <div id="whatToPrint" className="print-only">
    <div id="whatToPrint2" className="printTemplates template2">
      <div className="my-5 page" size="A4" style={{display:"block",margin:"0 auto",position:"relative",boxShadow:"2px 2px 10px 3px rgba(0, 0, 0, 0.397)",overflow:"hidden"}}>
      <style jsx>{`
  @media (min-width: 1024px) {
    .page {
      width: 700px;
    }
  }

  @media (max-width: 1023px) {
    .page {
      width: 100%; /* Adjust as needed for smaller devices */
    }
  }
`}</style>
        <div 
          className="p-4" 
          id="printReport" 
          style={{
            margin: "auto", 
            padding: "0px", 
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            overflowX: "auto"
          }}
        >
          <h2 style={styles.header}>DebitNote</h2>

          {/* Top Content Section */}
          <section className="top-content bb" >
            <div className="top-left" >
            <div
                                  className="position-relative"
                                  style={{textAlign:"left"}}
                                >
              <p>Debit Note</p>
              <p>Debit Note No. <span>{debitNote.debit_note.retrn_no}</span></p>
              <p>Debit Note Date: <span>{debitNote.debit_note.debit_date}</span></p>
            </div>
            </div>
          </section>

          {/* Store and User Info Section */}
          <section className="store-user mt-5" style={styles.section}>
            <div className="row bb pb-3" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-12 col-md-7" style={styles.column}>
                <p>FROM,</p>
                <h5>{debitNote.debit_note.company}</h5>
                <p className="address">{debitNote.debit_note.address}</p>
              </div>
              <div className="col-12 col-md-5" style={styles.column}>
                <p>TO,</p>
                <h5>{debitNote.debit_note.party}</h5>
                <p className="address">{debitNote.debit_note.billing_address}</p>
              </div>
            </div>
          </section>

          {/* Product Area Section */}
          <section className="product-area mt-4 " style={styles.section}>
            <div style={{overflowX:"auto"}}>
            <table className="table table-hover table-bordered border-dark" style={styles.table}>
              <thead style={styles.tableHeader}>
                <tr>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>Items</th>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>HSN/SAC</th>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>Price</th>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>Quantity</th>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>Tax</th>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>Discount</th>
                  <th style={{backgroundColor:"#3d4465",color:"white"}}>Total</th>
                </tr>
              </thead>
              <tbody>
                {debitNote.items.map((item, index) => (
                  <tr key={index} style={styles.tableRow}>
                    <td style={styles.tableCell}>{item.product_name}</td>
                    <td style={styles.tableCell}>{item.product_hsn}</td>
                    <td style={styles.tableCell}>{item.product_price}</td>
                    <td style={styles.tableCell}>{item.qty}</td>
                    <td style={styles.tableCell}>{item.tax}%</td>
                    <td style={styles.tableCell}>{item.discount}</td>
                    <td style={styles.tableCell}>{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </section>

          {/* Payment Info Section */}
          <section className="balance-info">
  <div className="row mt-3">
    <div className="col-11 d-flex justify-content-end"> 
      <div className="balance-table-container">
        <br /><br />
  <table className="table w-100 table-borderless">
    <tbody>
      <tr>
        <td className="text-start" style={{ ...styles.bold, padding: "10px 10px" }}>Subtotal:</td>
        <td className="text-end" style={{ padding: "10px 10px" }}>₹ {debitNote.debit_note.subtotal}</td>
      </tr>
                

      <tr>
        <td className="text-start" style={{ ...styles.bold, padding: "10px 10px" }}>Tax Amount:</td>
        <td className="text-end" style={{ padding: "10px 10px" }}>₹ {debitNote.debit_note.tax_amount}</td>
      </tr>
      <tr>
        <td className="text-start" style={{ ...styles.bold, padding: "10px 10px" }}>Adjustment:</td>
        <td className="text-end" style={{ padding: "10px 10px" }}>₹ {debitNote.debit_note.adjust}</td>
      </tr>
      <tr>
        <td className="text-start" style={{ ...styles.grandTotal, padding: "10px 10px" }}>Grand Total:</td>
        <td className="text-end" style={{ ...styles.grandTotal, padding: "10px 10px" }}>₹ {debitNote.debit_note.grandtotal}</td>
      </tr>
    </tbody>
  </table>
  </div>
    </div>
  </div>
</section>

        </div>
      </div>
    </div>
  </div>
 
</div>
 )}
  {currentPage === 2 && ( 
                       <div id="templatediv" style={{borderRadius:"2vh"}} >
                       <div id="whatToPrint" className="print-only">
                         <div id="whatToPrint2" className="printTemplates template2">
                           <div className="my-5 page" size="A4" style={{display:"block",margin:"0 auto",position:"relative",boxShadow:"2px 2px 10px 3px rgba(0, 0, 0, 0.397)",overflow:"hidden"}}>
                           <style jsx>{`
                       @media (min-width: 1024px) {
                         .page {
                           width: 700px;
                         }
                       }
                     
                       @media (max-width: 1023px) {
                         .page {
                           width: 100%; /* Adjust as needed for smaller devices */
                         }
                       }
                     `}</style>
                           <div 
          
          id="printReport" 
          
        >
                                        <div className="row px-5 py-4" style={{ backgroundColor: "#3d4465" }}>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-center">
                                            <center className="h3 text-white"><b>DEBIT NOTE</b></center>
                                        </div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                            <div className="text-white">
                                            <p className="mb-0 mt-2 text-white">Debit Note No: <b>{debitNote.debit_note.retrn_no}</b></p>
                                            <p className="mb-0 mt-2 text-white">Date: <b>{debitNote.debit_note.debit_date}</b></p>
                                            </div>
                                        </div>
                                        </div>
                                       
                                        <div className="px-3 py-2">
  <section className="store-user">
    <br />
    <br />
    <div className="col-12">
      <div className="row pb-2 mt-2">

        {/* Billing Address - Label first, then details below */}
        <div className="col-4 pl-0">
          {/* Billing Address Label */}
          <div
            className="text-white p-1 mb-1"
            style={{
              backgroundColor: "#3d4465",
              width: "fit-content",
              borderTopRightRadius: "4vh",
              borderBottomRightRadius: "4vh",
            }}
          >
            <b>BILLING ADDRESS</b>
          </div>

          {/* Billing Address Details */}
          <h5 className="text-secondary" style={{ fontWeight: "bold" }}>
          {debitNote.debit_note.company}
          </h5>
          <p
            className="address"
            style={{
              fontWeight: "bold",
              color: "#000",
              height: "fit-content",
              width: "fit-content",
            }}
          >
           {debitNote.debit_note.address}
          </p>
        </div>

        {/* Shipping Address - Label first, then details below */}
        <div className="col-4"></div>
        <div className="col-4 pr-5">
          {/* Shipping Address Label */}
          <div
            className="text-white p-1 mb-1"
            style={{
              backgroundColor: "#3d4465",
              width: "fit-content",
              borderTopRightRadius: "4vh",
              borderBottomRightRadius: "4vh",
            }}
          >
            <b>SHIPPING ADDRESS</b>
          </div>

          {/* Shipping Address Details */}
          <h5 className="text-secondary" style={{ fontWeight: "bold" }}>
          {debitNote.debit_note.party}
          </h5>
          <p
            className="address"
            style={{
              fontWeight: "bold",
              color: "#000",
              height: "fit-content",
              width: "fit-content",
            }}
          >
            {debitNote.debit_note.billing_address}
            <br />
            {debitNote.debit_note.partymob}
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

                                        {/* Product Table */}
<section className="product-area mt-2" style={{ overflowX: "auto", paddingLeft: "10px", paddingRight: "10px" }}>
  <table
    className="table table-hover table-bordered"
    id="table2"
    style={{ width: "92.5%", marginLeft: "auto", marginRight: "auto" }} // Adjusted width and auto margin for centering
  >
    <thead style={{ backgroundColor: "#3d4465"}} >
      <tr className="template3tablehead">
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Items</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>HSN</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Quantity</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Rate</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Tax</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Discount</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Total</th>
      </tr>
    </thead>
    
    <tbody>
                {debitNote.items.map((item, index) => (
                  <tr key={index} style={styles.tableRow}>
                    <td style={styles.tableCell}>{item.product_name}</td>
                    <td style={styles.tableCell}>{item.product_hsn}</td>
                    <td style={styles.tableCell}>{item.product_price}</td>
                    <td style={styles.tableCell}>{item.qty}</td>
                    <td style={styles.tableCell}>{item.tax}%</td>
                    <td style={styles.tableCell}>{item.discount}</td>
                    <td style={styles.tableCell}>{item.total}</td>
                  </tr>
                ))}
              </tbody>
  </table>
</section>


<section className="balance-info">
  <div className="row mt-3">
    <div className="col-11 d-flex justify-content-end"> 
      <div className="balance-table-container">
        <br /><br />
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td style={{ color: "#000" }}>Sub Total</td>
              <td style={{ color: "#000" }}>:</td>
              <td className="text-left" style={{ color: "#000" }}>{debitNote.debit_note.subtotal}</td>
            </tr>
            <tr>
              <td style={{ color: "#000" }}>Tax Amount</td>
              <td style={{ color: "#000" }}>:</td>
              <td className="text-left" style={{ color: "#000" }}>{debitNote.debit_note.tax_amount}</td>
            </tr>
          </tbody>
        </table>
        <hr style={{ backgroundColor: "#000000" }} />
        <table className="table table-borderless">
          <tbody>
            <tr>
              <th style={{ color: "#000" }}>Grand Total</th>
              <th style={{ color: "#000" }}>:</th>
              <th style={{ color: "#000", textAlign: "right" }}>{debitNote.debit_note.grandtotal}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                    )}
                    {currentPage === 3 && (
                      <div id="templatediv" style={{borderRadius:"2vh"}} >
                      <div id="whatToPrint" className="print-only">
                        <div id="whatToPrint3" className="printTemplates template3"  >
                          <div className="my-5 page" size="A4" style={{display:"block",margin:"0 auto",position:"relative",boxShadow:"2px 2px 10px 3px rgba(0, 0, 0, 0.397)",overflow:"hidden"}}>
                          <style jsx>{`
                       @media (min-width: 1024px) {
                         .page {
                           width: 700px;
                         }
                       }
                     
                       @media (max-width: 1023px) {
                         .page {
                           width: 100%; /* Adjust as needed for smaller devices */
                         }
                       }
                     `}</style>
                          <div id="printReport" >
                            {/* <div id="printdiv2"> */}
                                        <div className="row ">
                                        
  <div
                              className="col-sm-12"
                              style={{backgroundColor:" #3d4465",color:"white"}}
                            >
                              <br />
    <center className="h3 text-white">
      <b>DEBIT NOTE</b>
    </center>

    {/* Company Name and Address */}
    <div className="text-white text-center mt-2">
      <p className="mb-0 mt-2 text-white">
        <b>{debitNote.debit_note.company}</b>
      </p>
      <p className="mb-0 mt-2 text-white">
        <b>{debitNote.debit_note.address}</b>
      </p>
    </div>
  </div>
                                        </div>
                                       
                                        <div className="px-3 py-1">
                                        <section className="store-user">
  <br />
  <br />
  <div className="col-12">
    <div className="row pb-2 mt-2">
      <div className="col-md-4 col-12 pl-0">
        <div className="p-1 mb-1" style={{ color: "black" }}>
          <b>To,</b>
          <p style={{ color: "black" }}>{debitNote.debit_note.party}</p>
          <p style={{ color: "black" }}>{debitNote.debit_note.address}</p>
        </div>
      </div>

      <div className="col-md-4 d-none d-md-block"></div> {/* Spacer for larger screens */}
      
      <div className="col-md-4 col-12 pr-0 mt-2 mt-md-0">
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td style={{ padding: '5px', color: 'white' }}>
                <h6>DebitNote No:</h6>
              </td>
              <td style={{ padding: '5px', color: 'white' }}>
                <h6><b>{debitNote.debit_note.retrn_no}</b></h6>
              </td>
            </tr>
            <tr>
              <td style={{ padding: '5px', color: 'white' }}>
                <h6>DebitNote Date:</h6>
              </td>
              <td style={{ padding: '5px', color: 'white' }}>
                <h6><b>{debitNote.debit_note.debit_date}</b></h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

</div>

                                        {/* Product Table */}
<section className="product-area mt-2" style={{ overflowX: "auto", paddingLeft: "10px", paddingRight: "10px" }}>
  <table
    className="table table-hover table-bordered"
    id="table2"
    style={{ width: "92.5%", marginLeft: "auto", marginRight: "auto" }} // Adjusted width and auto margin for centering
  >
    <thead style={{ backgroundColor: "#3d4465"}} >
      <tr className="template3tablehead">
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Items</th>
        <th className="text-center"style={{backgroundColor:"#3d4465",color:"white"}}>HSN</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Quantity</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Rate</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Tax</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}}>Discount</th>
        <th className="text-center" style={{backgroundColor:"#3d4465",color:"white"}} >Total</th>
      </tr>
    </thead>
    
   
    <tbody>
                {debitNote.items.map((item, index) => (
                  <tr key={index} style={styles.tableRow}>
                    <td style={styles.tableCell}>{item.product_name}</td>
                    <td style={styles.tableCell}>{item.product_hsn}</td>
                    <td style={styles.tableCell}>{item.product_price}</td>
                    <td style={styles.tableCell}>{item.qty}</td>
                    <td style={styles.tableCell}>{item.tax}%</td>
                    <td style={styles.tableCell}>{item.discount}</td>
                    <td style={styles.tableCell}>{item.total}</td>
                  </tr>
                ))}
              </tbody>
  </table>
</section>


<section className="balance-info">
  <div className="row mt-3">
    <div className="col-11 d-flex justify-content-end"> 
      <div className="balance-table-container">
        <br /><br />
        <table className="table table-borderless">
          <tbody>
            <tr>
            <td style={{ color: "#000" }}><b>Sub Total</b></td>

              <td style={{ color: "#000" }}>:</td>
              <td className="text-left" style={{ color: "#000" }}>{debitNote.debit_note.subtotal}</td>
            </tr>
            <tr>
              <td style={{ color: "#000" }}><b>Tax Amount</b></td>
              <td style={{ color: "#000" }}>:</td>
              <td className="text-left" style={{ color: "#000" }}>{debitNote.debit_note.tax_amount}</td>
            </tr>
          </tbody>
        </table>
        <hr style={{ backgroundColor: "#000000" }} />
        <table className="table table-borderless">
          <tbody>
            <tr>
              <th style={{ color: "#000" }}>Grand Total</th>
              <th style={{ color: "#000" }}>:</th>
              <th style={{ color: "#000", textAlign: "right" }}>{debitNote.debit_note.grandtotal}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                    )}



                    
 </div>
)}

                  {templateSelected && (
  <div className="pagination-controls" style={{ textAlign: 'center', marginTop: '20px' }}>
    {/* Show the first 3 page numbers */}
    {[1, 2, 3].map((pageNumber) => (
      <button
        key={pageNumber}
        className={`pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
        onClick={() => handlePagination(pageNumber)}
        style={{
          margin: '0 5px',
          padding: '5px 10px',
          border: '1px solid #ddd',
          backgroundColor: pageNumber === currentPage ? '#3d4465' : 'white',
          color: pageNumber === currentPage ? 'white' : '#3d4465',
        }}
      >
        {pageNumber}
      </button>
    ))}
  </div>
)}
   
                  {/* Slip Section */}
                  <div className="card shadow-lg mt-3" id="printReport" style={{ maxWidth: '80%', margin: '0 auto', padding: '10px' }}>
  {/* Slip Section */}
  {!templateSelected && (
    <div id="slipContent">
        <h5 className="font-weight-bold text-center text-dark">
                          {debitNote.debit_note.company}
                        </h5>
                        <div className="address text-center">
                          <p>{debitNote.debit_note.address}</p>
                          <p>
                            {debitNote.debit_note.state}, {debitNote.debit_note.country}
                          </p>
                          
                        </div>

      <div className="row">
        <div className="col-12">
          <p><strong>Credit Note No: </strong>{debitNote.debit_note.retrn_no}</p>
          <p><strong>Customer Name: </strong>{debitNote.debit_note.party}</p>
          <p><strong>Date: </strong>{debitNote.debit_note.debit_date}</p>
        </div>
      </div>

      <div className="table-responsive">
  <table className="table table-bordered table-sm">
    <thead>
      <tr>
        <th>Item</th>
        <th>HSN</th>
        <th>Qty</th>
        <th>Price</th>
        <th>Tax</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      {/* Iterating through debitNoteItems and displaying them in a row-wise format */}
      {debitNote.items.map((item, index) => (
        <tr key={index} style={{ color: "black", fontSize: "small" }}>
          <td>{item.product_name}</td>
          <td>{item.product_hsn}</td>
          <td>{item.qty}</td>
          <td>{item.product_price}</td>
          <td>{item.tax}%</td>
          <td>{item.total}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      <div className="row mt-4">
        <div className="col-12">
          <p><strong>Subtotal: </strong>{debitNote.debit_note.subtotal}</p>
         <p><strong>Tax Amount</strong>{debitNote.debit_note.tax_amount}</p>
          <p><strong>Adjustment: </strong>{debitNote.debit_note.adjust}</p>
          <hr className="text-dark" />
          <p className="text-dark"><strong>Total: </strong>{debitNote.debit_note.grandtotal}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <p ><strong>Created By: </strong>{debitNote.debit_note.company}</p>
        </div>
      </div>
    </div>
  )}
</div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                   {/* <!-- Share To Email Modal --> */}
<div className="modal fade"  id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#394d70" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                 onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                  <div className="email-label" style={labelStyle}>Email IDs</div>

                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                  <div className="email-label" style={labelStyle}>Message(Optional)</div>

                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
     







  );
};

export default ViewDebitNote;
