import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import './ViewSalesInvoice.css'; // External CSS for styling
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import {  useEffect } from "react";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import  { useRef } from "react";
import Cookies from "js-cookie";
import config from "../../../functions/config";
// import api from 'api';
// import { sendEmail } from "api";
// import { sendEmail } from "../api"; // Adjust the relative path
// Adjust the path as needed

import { format } from "date-fns";

// const currentDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");
const ID = Cookies.get("user_id");
const user = Cookies.get("role");

export default function ViewSalesInvoice() {
  const [activeTab, setActiveTab] = useState('template'); // State to track active tab
  const summaryRowStyle = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
    color:"black"
  };
  const [email,setEmail]=useState(false)
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeTemplate, setActiveTemplate] = useState("template1");
  const location = useLocation();
    const invoiceData = location.state?.invoice;
    const [invoices, setInvoices] = useState([]);
    const [subtotal, setSubtotal] = useState(0); // State for subtotal
    const [totaltax, setTax] = useState(0);
  //   useEffect(() => {
  //     // `${config.base_url}/view_invoices/`
  //     console.log("invoice data",invoiceData)
  //     fetch( `${config.base_url}view_invoices/`) // Replace with the correct endpoint
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("Fetched Data:///", data); // Log fetched data to verify
  //         const filteredData = data.filter(
  //           (invoice) => invoice.salesinvoice_id === invoiceData.id
  //         );
  //         console.log("Filtered Data:///", filteredData); // Log filtered data to verify
  //         const subtotal = filteredData.reduce(
  //           (sum, invoice) => sum + Number(invoice.totalamount || 0),
  //           0
  //         );
  //         const totaltax = filteredData.reduce((sum, invoice) => {
  //           const totalAmount = Number(invoice.totalamount || 0); // Ensure totalamount is a number
  //           const taxRate = Number(invoice.tax || 0) / 100; // Convert tax rate to decimal
  //           const itemTax = totalAmount * taxRate; // Calculate tax for this item
  //           return sum + itemTax; // Add this item's tax to the total tax sum
  //         }, 0);
          
  // // console.log(filteredData)
  //         setInvoices(filteredData);
  //         setSubtotal(subtotal);
  //         setTax(totaltax);
  //         console.log(filteredData)
  //       })
  //       .catch((error) => console.error("Error fetching data:", error));
  //   }, []);
   // Empty dependency array ensures this runs only once when the component mounts
  
    // Format the current date and time
    // const [currentDateTime, setCurrentDateTime] = useState(new Date());
    useEffect(() => {
      console.log("Invoice Data:", invoiceData);
    
      fetch(`${config.base_url}view_invoices/`) // Ensure URL is correct
        .then((response) => response.json())
        .then((data) => {
          console.log("Fetched Data:", data);
    
          // Filter data based on invoiceData.id (handle type mismatch)
          const filteredData = data.filter(
            (invoice) => Number(invoice.salesinvoice_id) === Number(invoiceData.id) // Ensure comparison works
          );
    
          console.log("Filtered Data:", filteredData);
    
          // Calculate subtotal and tax
          const subtotal = filteredData.reduce(
            (sum, invoice) => sum + Number(invoice.totalamount || 0),
            0
          );
          const totaltax = filteredData.reduce((sum, invoice) => {
            const totalAmount = Number(invoice.totalamount || 0);
            const match = invoice.tax.match(/(?:GST|IGST)(\d+)\[/);
            // const taxRate = match ? Number(match[1]) : 0; 
            const taxRate1 = match ? Number(match[1]) : 0;
            console.log("dfghjk",match)
            const taxRate = Number(taxRate1|| 0) / 100;
            console.log("dfghjk",taxRate)
            return sum + totalAmount * taxRate;
          }, 0);
    
          // Set data into states
          setInvoices(filteredData);
          setSubtotal(subtotal);
          setTax(totaltax);
        })
        .catch((error) => console.error("Error fetching data:", error));
    }, [invoiceData]); // Added invoiceData as dependency
    
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date()); // Update the current time every second
      }, 1000);
  
      // Cleanup function to clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, []); 
    const formattedDateTime = currentDateTime.toLocaleString();
 
    const invoiceRef = useRef(null);
    // const handlePrint = () => {
    //   if (!invoiceRef.current) {
    //     console.error("Invoice section not found.");
    //     return;
    //   }
    //   const invoiceContent = invoiceRef.current.innerHTML;
  
    //   const printWindow = window.open("", "_blank");
    //   printWindow.document.open();
    //   printWindow.document.write(`
    //     <html>
    //       <head>
    //         <title>Print Invoice</title>
    //         <style>
    //           body { font-family: Arial, sans-serif; }
    //           table { width: 100%; border-collapse: collapse; }
    //           th, td { border: 1px solid #ddd; padding: 8px; }
    //         </style>
    //       </head>
    //       <body>
    //         ${invoiceContent}
    //       </body>
    //     </html>
    //   `);
    //   printWindow.document.close();
    //   printWindow.print();
    // };
  
    const handlePrint = () => {
      // Check if invoiceRef exists
      if (!invoiceRef.current) {
        console.error("Invoice section not found.");
        return;
      }
    
      // Get the invoice content only
      const invoiceContent = invoiceRef.current.innerHTML;
    
      // Open a new print window
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
    
      // Check if it's slip-style or template-style
      if (activeTab === 'slip') {
        // Slip-style print
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Invoice - Slip</title>
              <style>
                @page {
                  size: 80mm auto; /* Set slip width */
                  margin: 0; /* No margins */
                }
                body {
                  font-family: Arial, sans-serif;
                  -webkit-print-color-adjust: exact; /* Print colors correctly */
                  margin: 0;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .print-container {
                  width: 90mm; /* Slip width */
                  padding: 10px;
                  border: 1px dotted black;
                  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.397);
                  font-size: 10px;
                  color: black;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-top: 10px;
                }
                th, td {
                  padding: 5px;
                  text-align: left;
                  border-bottom: 1px solid gray;
                }
               .header, .footer {
            text-align: center;
            margin-bottom: 10px;
          }
          .row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
          }
          .total-row {
            font-weight: bold;
            font-size: larger;
            padding-top: 5px;
          }
          .divider {
            border-top: 1px solid black;
            margin: 5px 0;
          }
              </style>
            </head>
            <body>
              <div class="print-container">
                ${invoiceContent}
              </div>
            </body>
          </html>
        `);
      } else {
        // Template-style print
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Invoice - Template</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #ddd; padding: 15px; }
              </style>
            </head>
            <body>
              ${invoiceContent}
            </body>
          </html>
        `);
      }
    
      // Close the print window and trigger printing
      printWindow.document.close();
      printWindow.print();
    };
    
        const generatePDF = () => {
          const input = document.getElementById("invoice-box"); // Your invoice container ID
          html2canvas(input).then((canvas) => {
            const pdf = new jsPDF();
            const imgData = canvas.toDataURL("image/png");
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size page
            pdf.save("SalesInvoice.pdf");
          });
        };
          
  const toggleFilterBox = (filterName) => {
    setActiveFilter((prev) => (prev === filterName ? null : filterName));
  };
  const [showOptions, setShowOptions] = useState(false);

  // Function to toggle the visibility of the options
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  // Share via WhatsApp
  const shareViaWhatsApp = () => {
    const message = "Check out this invoice!"; // Add your custom message here
    const phoneNumber = ""; // Optional: you can specify a phone number
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  const dropdownRef = useRef(null);
  const [showFilterBox, setShowFilterBox] = useState(false);

 
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilterBox(false); // Close dropdown
        setEmail(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Share via Email
  const shareViaEmail = () => {
    const subject = "Invoice"; // Subject for the email
    const body = "Check out this invoice!"; // Body content
    const mailto = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailto;
  };
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleToggleFilterBox = () => {
    setShowFilterBox((prev) => !prev);
  };
const [emailAddresses, setEmailAddresses] = useState("");
const [message, setMessage] = useState("");
  // const renderFilterBox = () => {
  //   if (!showFilterBox) return null;
  //  // Only render when `showFilterBox` is true

  
  //   // Generate PDF
  //   // const generatePdf = () => {
  //   //   const doc = new jsPDF();
  //   //   doc.text("Sales Invoice", 10, 10);
  //   //   // Add invoice details here
  //   //   doc.save("SalesInvoice.pdf");
  //   //   return doc.output("blob");
  //   // };
  
  
    
  // // const generatePdf = () => {
  // //     const input = document.getElementById("invoice-box"); // Your invoice container ID
  // //     html2canvas(input).then((canvas) => {
  // //       const pdf = new jsPDF();
  // //       const imgData = canvas.toDataURL("image/png");
  // //       pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size page
  // //       // pdf.save("invoice.pdf");
  // //       pdf.save("SalesInvoice.pdf");
  // //       // const pdfBlob = pdf.output("blob");
  // //       // return pdfBlob;
  // //       return pdf.output("blob");
  // //       // return pdf.output("blob");
  // //     });
  // //   };
  // // const generatePdf = () => {
  // //   return new Promise((resolve, reject) => {
  // //     const input = document.getElementById("invoice-box"); // Your invoice container ID
  // //     html2canvas(input)
  // //       .then((canvas) => {
  // //         const pdf = new jsPDF();
  // //         const imgData = canvas.toDataURL("image/png");
  // //         pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size page
  // //         const pdfBlob = pdf.output("blob");
  // //         resolve(pdfBlob); // Return the generated Blob
  // //       })
  // //       .catch((error) => reject(error));
  // //   });
  // // };
  
  // // const convertBlobToBase64 = (blob) => {
  // //   return new Promise((resolve, reject) => {
  // //     const reader = new FileReader();
  // //     reader.onloadend = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
  // //     reader.onerror = reject;
  // //     reader.readAsDataURL(blob);
  // //   });
  // // };
  // // const fetchCompanyName = async (userId, role) => {
    
  // //   try {
  // //     const response = await fetch(
  // //       `${config.base_url}api/get_company_name?user_id=${encodeURIComponent(userId)}&role=${encodeURIComponent(role)}`
  // //     );
      
  // //     // const response = await fetch(`http://127.0.0.1:8000/api/get_company_name?user_id=${userId}&role=${role}`);
  // //     if (!response.ok) throw new Error("Failed to fetch company name");
  // //     const data = await response.json();
  // //     return data.company_name;
  // //   } catch (error) {
  // //     console.error("Error fetching company name:", error);
  // //     return null;
  // //   }
  // // };
  // // const handleKeyDown = (e) => {
  // //   if ( e.key === ' ' ) {
  // //     e.stopPropagation(); // Prevent modal from closing
  // //   }
  // // };
  
  // // const handleSendEmail = async () => {
  // //   try {
  // //     const pdfBlob = await generatePdf(); // Wait for the Blob to be generated
  // //     const pdfBase64 = await convertBlobToBase64(pdfBlob); // Convert Blob to Base64
  // //     const userId = Cookies.get("user_id");
  // //     const role = Cookies.get("role");
  // //     const companyName = await fetchCompanyName(userId, role);
  // //     // if (!companyName) {
  // //     //   alert("Failed to fetch company name");
  // //     //   return;
  // //     // }
      
  // //     const emailData = {
  // //       to: emailAddresses.split(",").map((email) => email.trim()),
  // //       subject: "Sales Invoice",
  // //       message: `Hi,\n\nPlease find the attached SALES INVOICE - File-1.\n\n${message}\n\nRegards,\n${companyName}`,
  // //       attachment: pdfBase64, // Use Base64-encoded PDF
  // //       fileName: "sales_invoice.pdf",
  // //     };
      
  
  // //     console.log("Email Data:", emailData);
  
  // //     const response = await fetch(`${config.base_url}api/send_email`, {
  // //       method: "POST",
  // //       headers: {
  // //         "Content-Type": "application/json",
  // //       },
  // //       body: JSON.stringify(emailData),
  // //     });
  
  // //     if (!response.ok) throw new Error("Failed to send email");
  
  // //     alert("Email sent successfully!");
  // //     handleCloseModal();
  // //   } catch (error) {
  // //     console.error("Error sending email:", error);
  // //   }
  // // };
  
  //   // return (
     
  //   // );
  // };
  const generatePdf = () => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById("invoice-box"); // Your invoice container ID
     
      html2canvas(input, {
        scale: 2, // Increase resolution
        useCORS: true, // Handle cross-origin images
      })
        .then((canvas) => {
          const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait mode, A4 size
          const imgData = canvas.toDataURL("image/png");
  
          const imgWidth = 210; // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale height proportionally
            // Top margin in mm
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight); // Fit the image to A4 size
          const pdfBlob = pdf.output("blob");
          resolve(pdfBlob); // Return the generated Blob
        })
        .catch((error) => reject(error));
      
        // .catch((error) => reject(error));
    });
  };
//   const handleSendWhatsApp = async () => {
//     try {
//         setIsLoading(true); // Show loading state

//         // Step 1: Generate the PDF Blob
//         const pdfBlob = await generatePdf(); // Ensure `generatePdf` returns a valid Blob
        
//         // Step 2: Prepare FormData with the PDF
//         const formData = new FormData();
//         formData.append("pdf", pdfBlob, "sales_invoice.pdf"); // Key 'pdf' must match the Django view

//         // Step 3: Upload the PDF to the server
//         const response = await fetch(`${config.base_url}api/upload_pdf1`, {
//             method: "POST",
//             body: formData,
//         });

//         if (!response.ok) throw new Error("Failed to upload PDF");

//         const { pdfUrl } = await response.json(); // Expect server response to include the PDF URL
//         console.log("PDF URL:", pdfUrl);

//         // Step 4: Generate WhatsApp shareable link
//         const message = `Hi, please check out this invoice: ${pdfUrl}`;
//         const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
        
//         // Step 5: Open WhatsApp Web in a new tab
//         window.open(whatsappUrl, "_blank");
//     } catch (error) {
//         console.error("Error sending WhatsApp message:", error);
//         alert("Failed to send message via WhatsApp.");
//     } finally {
//         setIsLoading(false); // Hide loading state
//     }
// };
const handleWhatsAppShare = () => {
  // Replace this with your base URL for sales invoices
  const baseUrl = "http://localhost:3000/ViewSalesInvoice";

  // Construct the full URL for the invoice
  // const invoiceUrl = `${baseUrl}${invoiceData.invoice_no}/`;
  const invoiceUrl = `${window.location.origin}/ViewSalesInvoice/${invoiceData.invoice_no}`;
  // Encode the URL for WhatsApp
  const encodedMessage = encodeURIComponent(`Please check out this invoice: ${invoiceUrl}`);
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;

  // Open WhatsApp sharing link in a new tab
  window.open(whatsappUrl, "_blank");
};

// const shortUrl = await shortenUrl(pdfUrl); // Use a URL shortening service
// const message = `Check out this PDF: ${shortUrl}`;
// const generatePdf1 = () => {
//   return new Promise((resolve, reject) => {
//     // Use a library like jsPDF to generate the PDF
//     const doc = new jsPDF();
//     doc.text('Sales Invoice Content', 10, 10);
//     const input = document.getElementById("invoice-box"); 
//     const pdfBlob = doc.output('blob');
//     resolve(pdfBlob);
//   });
// };
const generatePdf1 = () => {
  return new Promise((resolve, reject) => {
    const input = document.getElementById("invoice-box"); // Your invoice container

    // Use html2canvas to capture the content of the input element
    html2canvas(input, {
      scale: 2, // Increase resolution for better quality
      useCORS: true, // Handle cross-origin images to avoid issues with external resources
      scrollX: 0,  // Remove any potential scrolling offsets
      scrollY: -window.scrollY, // Corrects vertical scroll position if needed
      width: input.offsetWidth,  // Set the width based on the element's content
      height: input.offsetHeight, // Set the height based on the element's content
    })
      .then((canvas) => {
        // Create a new jsPDF instance to generate the PDF
        const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait mode, A4 size
        const imgData = canvas.toDataURL("image/png"); // Convert the canvas to image data

        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale height proportionally to maintain aspect ratio
        
        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight); // Fit the image to A4 size

        // Output the PDF as a blob
        const pdfBlob = pdf.output("blob");
        resolve(pdfBlob); // Return the generated Blob (can be used for further processing)
      })
      .catch((error) => reject(error)); // Handle errors
  });
};
const handleSendPdfThroughWhatsapp = async () => {
  setIsLoading(true);
  try {
    // Step 1: Generate PDF Blob
    const pdfBlob = await generatePdf1();

    // Step 2: Upload PDF to the server
    const formData = new FormData();
    formData.append("pdf", pdfBlob, "invoice.pdf");

    const response = await fetch(`${config.base_url}api/upload_pdf1`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) throw new Error("Failed to upload PDF");

    const { pdfUrl } = await response.json();

    // Generate the WhatsApp URL
    const message = `Check out this PDF: ${pdfUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

    console.log("WhatsApp URL:", whatsappUrl);

    // Dynamically create a WhatsApp link
    const whatsappLink = document.createElement("a");
    whatsappLink.href = whatsappUrl;
    whatsappLink.target = "_blank";
    whatsappLink.textContent = "Click here to share via WhatsApp";
    whatsappLink.style.display = "none";

    document.body.appendChild(whatsappLink);
    whatsappLink.click(); // Trigger the click event
    document.body.removeChild(whatsappLink); // Clean up the DOM

    setIsLoading(false);
  } catch (error) {
    console.error("Error sending PDF via WhatsApp:", error);
    alert("Failed to send PDF through WhatsApp.");
    setIsLoading(false);
  }
};


// const handleSendPdfThroughWhatsapp = async () => {
//   setIsLoading(true);
//   try {
//     // Step 1: Generate PDF Blob (if dynamically generated)
//     const pdfBlob = await generatePdf1();

//     // Step 2: Prepare FormData with the PDF Blob
//     const formData = new FormData();
//     formData.append("pdf", pdfBlob, "invoice.pdf");
//     console.log("PDF IS", pdfBlob);

//     // Step 3: Upload the PDF to your server (Django backend in this case)
//     const response = await fetch(`${config.base_url}api/upload_pdf1`, {
//       method: "POST",
//       body: formData,
//     });

//     if (!response.ok) throw new Error("Failed to upload PDF");

//     // Get the URL of the uploaded PDF from the server response
//     const { pdfUrl } = await response.json();

//     // Generate the WhatsApp link
//     const message = `Check out this PDF: ${pdfUrl}`;
    
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

//     console.log("WhatsApp URL:", whatsappUrl);

//     // Open WhatsApp link directly
//     window.open(whatsappUrl, "_blank");

//     setIsLoading(false);
//   } catch (error) {
//     console.error("Error sending PDF via WhatsApp:", error);
//     alert("Failed to send PDF through WhatsApp.");

//     setIsLoading(false);
//   }
// };


// const handleSendPdfThroughWhatsapp = async () => {
//   setIsLoading(true);
//   try {
//     // Step 1: Generate PDF Blob (if dynamically generated)
//     const pdfBlob = await generatePdf1();

//     // Step 2: Prepare FormData with the PDF Blob
//     const formData = new FormData();
//     formData.append("pdf", pdfBlob, "invoice.pdf");
//     console.log("PDF IS",pdfBlob)
//     // Step 3: Upload the PDF to your server (Django backend in this case)
//     const response = await fetch(`${config.base_url}api/upload_pdf1`, {
//       method: "POST",
//       body: formData,
//     });

//     if (!response.ok) throw new Error("Failed to upload PDF");

//     // Get the URL of the uploaded PDF from the server response
//     const { pdfUrl } = await response.json();
    

// // Example usage
//     // const pdfUrl1 =pdfUrl;
//     // console.log(pdfUrl1);

//     const message = `Check out this PDF: ${pdfUrl}`;  // Simple message with PDF link
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

// // Open the WhatsApp link in a new window or tab
//     // window.open(whatsappUrl, "_blank");
//     openWhatsappLink(whatsappUrl);
//     // const newWindow = window.open("", "_blank");
//     // newWindow.location.href = whatsappUrl;
//     setIsLoading(false);
//   // const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

//     // Step 5: Open WhatsApp in a new window
//     // window.open(whatsappUrl, "_blank");
//     console.log("WhatsApp URL:", whatsappUrl);

//   } catch (error) {
//     console.error("Error sending PDF via WhatsApp:", error);
//     alert("Failed to send PDF through WhatsApp.");
//   }
// };
// const openWhatsappLink = (whatsappUrl) => {
//   window.open(whatsappUrl, "_blank");
// };

// const handleSendPdfThroughWhatsapp = async () => {
//   try {
//     // Step 1: Generate PDF Blob (if dynamically generated)
//     const pdfBlob = await generatePdf();

//     // Step 2: Prepare FormData with the PDF Blob
//     const formData = new FormData();
//     formData.append("pdf", pdfBlob, "invoice.pdf");

//     // Step 3: Upload the PDF to your server (Django backend in this case)
//     const response = await fetch('http://127.0.0.1:8000/api/upload_pdf1', {
//       method: "POST",
//       body: formData,
//     });

//     if (!response.ok) throw new Error("Failed to upload PDF");

//     // Get the URL of the uploaded PDF from the server response
//     const { pdfUrl } = await response.json();
//     console.log("PDF URL:", pdfUrl);

//     // Check if the PDF URL is valid and accessible
//     if (!pdfUrl || !pdfUrl.startsWith('http')) {
//       throw new Error('Invalid PDF URL');
//     }

//     // Step 4: Create WhatsApp shareable link with the fully qualified URL
//     const message = `Check out this PDF: ${pdfUrl}`;
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

//     // Step 5: Open WhatsApp in a new window
//     window.open(whatsappUrl, "_blank");
//     console.log("WhatsApp URL:", whatsappUrl);

//   } catch (error) {
//     console.error("Error sending PDF via WhatsApp:", error);
//     alert("Failed to send PDF through WhatsApp.");
//   }
// };


  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const fetchCompanyName = async (userId, role) => {
    
    try {
      const response = await fetch(
        `${config.base_url}api/get_company_name?user_id=${encodeURIComponent(userId)}&role=${encodeURIComponent(role)}`
      );
      
      // const response = await fetch(`http://127.0.0.1:8000/api/get_company_name?user_id=${userId}&role=${role}`);
      if (!response.ok) throw new Error("Failed to fetch company name");
      const data = await response.json();
      return data.company_name;
    } catch (error) {
      console.error("Error fetching company name:", error);
      return null;
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleSendEmail = async () => {
    try {
      // const loadingAlert = alert("Email is being sent. Please wait...");
      setIsLoading(true);
      const pdfBlob = await generatePdf(); // Wait for the Blob to be generated
      const pdfBase64 = await convertBlobToBase64(pdfBlob); // Convert Blob to Base64
      const userId = Cookies.get("user_id");
      const role = Cookies.get("role");
      const companyName = await fetchCompanyName(userId, role);
      if (!companyName) {
        alert("Failed to fetch company name");
        return;
      }
      
      const emailData = {
        to: emailAddresses.split(",").map((email) => email.trim()),
        subject: "Sales Invoice",
        message: `Hi,\n\nPlease find the attached SALES INVOICE - File-1.\n\n${message}\n\nRegards,\n${companyName}`,
        attachment: pdfBase64, // Use Base64-encoded PDF
        fileName: "sales_invoice.pdf",
      };
      
  
      console.log("Email Data:", emailData);
  
      const response = await fetch(`${config.base_url}api/send_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });
  
      if (!response.ok) throw new Error("Failed to send email");
      
      alert("Email sent successfully!");
      handleCloseModal();
      setEmailAddresses(""); // Clear the email addresses field
      setMessage("");
    } catch (error) {
      console.error("Error sending email:", error);
    }
    finally {
      setIsLoading(false); // Hide buffering alert
    }
  };
const generatePDFAndUpload = async () => {
    const input = document.getElementById("invoice-box"); // Your invoice container ID
    html2canvas(input).then(async (canvas) => {
      const pdf = new jsPDF();
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size page
  
      // Convert PDF to Blob
      const pdfBlob = pdf.output('blob');
      const formData = new FormData();
      formData.append("pdf", pdfBlob, "SalesInvoice.pdf");
      
      try {
        // Upload PDF to the server
        console.log(888)
        const response = await fetch('http://localhost:3000/upload_pdf', {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Failed to upload PDF');
        }
  
        const data = await response.json();
        const pdfUrl = data.pdfUrl; // Assuming the backend returns the URL of the uploaded PDF
        
        // Share the URL on WhatsApp
        shareWhatsApp(pdfUrl);
      } catch (error) {
        console.error('Error uploading PDF:', error);
        alert('There was an error uploading the PDF.');
      }
    });
  };
  
  // Function to share the PDF URL on WhatsApp
  const shareWhatsApp = (pdfUrl) => {
    const message = `Check out this invoice: ${pdfUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    console.log("WhatsApp URL:", whatsappUrl); // Log the WhatsApp URL
    window.open(whatsappUrl, '_blank'); // Open WhatsApp Web in a new tab
  };
  // const shareWhatsApp = async () => {
  //   const userConfirmation = window.confirm("Do you want to share this invoice on WhatsApp?");
  //   if (!userConfirmation) {
  //     return; // If the user cancels, stop further execution.
  //   }
  
  //   try {
  //     // Send a POST request to generate the PDF
  //     const response = await fetch(`${config.base_url}generate_and_share_pdf/`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //     });
  
  //     // Parse the JSON response
  //     if (!response.ok) {
  //       throw new Error('Failed to generate PDF');
  //     }
  
  //     const data = await response.json();
  //     console.log("Server response:", data); // Log the server response
  
  //     if (!data.pdf_url) {
  //       throw new Error('PDF URL not returned by the server');
  //     }
  
  //     // Call the function to share on WhatsApp
  //     handleShareWhatsApp(data.pdf_url);
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //     alert('There was an error generating the PDF. Please try again later.');
  //   }
  // };
  
  // const handleShareWhatsApp = (pdfUrl) => {
  //   const message = `${pdfUrl}`;
  //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  //   console.log("WhatsApp URL:", whatsappUrl); // Log the WhatsApp URL
  //   window.open(whatsappUrl, '_blank'); // Open WhatsApp Web in a new tab
  // };
  
  // Function to generate PDF from the invoice
  // const generatePDFShare = () => {
  //   const input = document.getElementById("invoice-box"); // Your invoice container ID
  //   html2canvas(input).then((canvas) => {
  //     const pdf = new jsPDF();
  //     const imgData = canvas.toDataURL("image/png");
  //     pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size page
  
  //     // Get the PDF output as a binary string
  //     const pdfOutput = pdf.output('blob'); // Use 'blob' to send the file
  
  //     // Create a FormData object to send the file via POST request
  //     const formData = new FormData();
  //     formData.append('file', pdfOutput, 'SalesInvoice.pdf');
  
  //     // Send the file to your backend server to be stored and accessible via a link
  //     fetch('/upload_pdf', { // Update this with your backend URL
  //       method: 'POST',
  //       body: formData,
  //     })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // If the upload is successful, the backend should return a URL for the PDF
  //       if (data.pdf_url) {
  //         // Share the PDF URL via WhatsApp
  //         shareWhatsApp(data.pdf_url);
  //       } else {
  //         alert('Error uploading PDF');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error uploading PDF:', error);
  //       alert('Error uploading PDF. Please try again.');
  //     });
  //   });
  // };
  
  // // Function to share the generated PDF on WhatsApp
  // const shareWhatsApp = (pdfUrl) => {
  //   const message = `Check out this invoice: ${pdfUrl}`;
  //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  //   console.log("WhatsApp URL:", whatsappUrl); // Log the WhatsApp URL
  //   window.open(whatsappUrl, '_blank'); // Open WhatsApp Web in a new tab
  // };
  
  
  return (
    <div className="container-scroller" style={{ border: "none" ,background: "#636e99" }}>
      <Navbar /> 
      <div className="container-fluid page-body-wrapper d-flex" style={{ border: "none" ,background: "#636e99" }}>
        <Sidebar />
        <div className="main-panel container-fluid "  style={{ border: "none" ,background: "#636e99" }}>
          <div className=" page-content" style={{
    backgroundColor: "#3d4465", // Correcting the background property
    color: "white",
    border: "none"
  }}>
            <div className="card radius-15 details-div" style={{background:"#3d4465",color:"white"}}>
            <div className="card-body">
                <div className="row mt-3">
                  <div className="col-md-8" style={{ position: 'relative', bottom: '10px' }}>
                  
              <h2 style={{background:"#3d4465",color:"white"}}>Sales Invoice Details</h2>
              </div>
              <div className="col-md" style={{ color: '#3d4465', position: 'relative', bottom: '10px',marginLeft:"10px" }}>
                <button className="btn btn-light bg-white m-2 " style={{color:"#3d4465"}} 
               onClick={handlePrint} >
                  <i className="fas fa-print" style={{color:"#3d4465"}}></i> Print
                </button>
                <button className="btn btn-light bg-white m-2" style={{color:"#3d4465"}}
                onClick={generatePDF}>
                  <i className="fas fa-file-pdf"></i> PDF
                </button>
                <button className="btn btn-light bg-white m-2" style={{color:"#3d4465"}}
              onClick={()=>setEmail(true)}
                >
                  <i className="fas fa-share"></i> Share
                  {/* {renderFilterBox()} */}
                </button>
                <div
        className={email?'filter-box':'d-none'}
        style={{
          position: "absolute",
          zIndex: 100,
          background: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px", 
          right:"100px"
        }}
        // onClick={(e) => e.stopPropagation()} 
        ref={dropdownRef}// Prevent outside clicks from closing the box
      >
        <div className="share-options  d-flex flex-column align-items-start">
          <a
            // href="https://api.whatsapp.com/send/?text=Check+out+this+content%21&type=custom_url&app_absent=0"
            // href="https://api.whatsapp.com/send/?text=Check+out+this+content%21&type=custom_url&app_absent=0"
            // onClick={handleWhatsAppShare}
            onClick={handleSendPdfThroughWhatsapp}
            target="_blank"
            rel="noopener noreferrer"
            // className="btn btn-light btn-sm me-2 text-dark w-100"
            className={`btn btn-light btn-sm me-2 text-dark w-100 ${
              isLoading ? "disabled" : ""
            }`}
          >
           {/* WhatsApp */} {isLoading ? "Sending..." : "WhatsApp"}
          </a>
         
          <a
            // href="mailto:?subject=Filter%20Information&body=Check%20out%20this%20content!"
            className="btn btn-light btn-sm text-dark w-100"
            onClick={handleShowModal}
          >
           Email
          </a>
          {showModal && (
  <div
    className="modal fade show " 
    tabIndex="-1"
    style={{ display: "block" }}
    aria-labelledby="emailModalLabel"
    aria-hidden="false"
   
  >
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header" style={{background:"#3d4465",color:"white"}}>
          <h5 className="modal-title text-light" id="emailModalLabel">
           SHARE SALES INVOICE VIA EMAIL
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={handleCloseModal}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body " style={{background:"#3d4465"}}>
          <div style={{background:"white"}}>
          <form>
            <div className="row p-4 mt-2">
              <div className="col-12 mt-3">
                <label htmlFor="emailSubject" className="form-label pl-3">
                  Email ID's<br/><br/><br/><br/><br/>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="emailSubject"
                  placeholder="Multiple Emails can be added by separating with a comma(,)"
                  onChange={(e) => setEmailAddresses(e.target.value)}
                  value={emailAddresses}
                 
                  // onKeyDown={handleKeyDown}
                  >
                  </textarea>
              </div>
            </div>
            <div className="row p-4">
              <div className="col-12">
                <label htmlFor="emailBody" className="form-label pl-3">
                  Message (optional)<br/><br/><br/><br/><br/><br/><br/><br/>
                </label>
                <textarea
                  className="form-control"
                  id="emailBody"
                  rows="4"
                  placeholder="This message will be sent along with Bill details."  
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}    
                  // onKeyDown={handleKeyDown}            
                ></textarea>
              </div>
            </div>
          </form>
          </div>
        </div>
        <div className="modal-footer" style={{background:"#3d4465",color:"white"}}>
          {/* <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Close
          </button> */}
          <div>
  {isLoading && (
    <div className="buffering-alert">
      Sending email... Please wait.
    </div>
  )}
  <button onClick={handleSendEmail}>Send Email</button>
</div>

          {/* <button type="button" className="btn btn-light bg-light text-dark" onClick={handleSendEmail}>
            Send Email
          </button> */}
        </div>
      </div>
    </div>
  </div>
)}

        </div>
      </div>
                {/* <div className="dropdown-content" id="myDropdown">
                      <ul style={{ marginBottom: '-1px' }}>
                        <li>
                          <div className="whatsapp-this">
                            <a
                              href="(link unavailable)"
                              className=""
                              target="_blank"
                            >
                              WhatsApp
                            </a>
                          </div>
                        </li>
                        <a onClick={() => console.log('Email clicked!')}>Email</a>
                      </ul>
                    </div> */}

                    <a
                      className="ml-1 text-white"
                      href="/show_invoice"
                      style={{
                        textAlign: 'right',
                        fontSize: 'large',
                        color: 'black',
                      }}
                    >
                      <i className="fa fa-times mt-2" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                {/* <i class="fas fa-times" style={{marginLeft:"15px"}}></i> */}
                {/* <Link to="/show_invoice">
                <i class="fas fa-times" style={{marginLeft:"15px",color:"white"}}></i>
    {/* <i className="fas fa-eye" style={{ color: 'green' }}></i> View */}
 
 <br />
             
           </div>
            <div className="sales-invoice-content " style={{background:"#3d4465",color:"white"}}>
              </div>
              <div className="template-header">
                {/* Tab buttons */}
                <button
          className={`tab-btn ${activeTab === 'template' ? 'active' : ''}`}
          onClick={() => setActiveTab('template')}
          style={{
            width: "570px",
            height: "100px",border:"none",
            fontSize:"20px",
            marginLeft:"5px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            background: activeTab === 'template' ?  'white' :'#3d4465' , // Blue background for active template tab
            color: activeTab === 'template' ? 'black':'black' , // White text for active template tab
          }}
        >
         <b>Template</b> 
        </button>
        <button
          className={`tab-btn ${activeTab === 'slip' ? 'active' : ''}`}
          onClick={() => setActiveTab('slip')}
          style={{
            width: "550px",
            height: "100px",border:"none",
            marginRight:"5px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "0px",
            fontSize:"20px",
            borderBottomRightRadius: "0px",
            background: activeTab === 'slip' ?  'white':'#3d4465' , // Blue background for active slip tab
            color: activeTab === 'slip' ? 'black': 'black' , // White text for active slip tab
          }}
        >
          <b> Slip</b> 
        </button>
              </div>
              {/* </div> */}
              {/* Conditional content based on the active tab */}
              {activeTab === 'template' ? (
             <div className="template-content">
                {/* Invoice Section */}
               {activeTemplate === "template1" && (
<div style={{overflow:"auto"}}>
<div
          id="invoice-box" ref={invoiceRef}
          // className="card overflow-auto  m-4 " 
          className="a4-page"
          style={{
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            border: "none",
            boxShadow: "0px 10px 10px rgba(0.2, 0.2, 0.2, 0.2)",
            // width: "100%",
            // maxWidth: "100%",
            
           
          }}  >
          {/* Invoice Header */}
          <div className='PrintPage '  >
          <div style={{ background: "#3d4465", color: "white", padding: "10px", textAlign: "center" }}>
            <h2 style={{ margin: "10px", background: "#3d4465", color: "white" }}>Sales Invoice</h2>
          </div>

          {/* Invoice Details */}
          <div style={{ marginBottom: "20px" ,marginTop:"20px" }}>
            <p>
              <strong>Invoice No:</strong>{invoiceData.invoice_no}
              {/* {" "}
              {[...new Map(
                invoices.map((invoice) => [
                  invoice.invoiceNo,
                  { invoiceNo: invoice.invoiceNo },
                ])
              ).values()].map((company, index) => (
                <div key={index}>
                  <p>{company.invoiceNo}</p>
                </div>
              ))} */}
            </p>
            <p>
              <strong>Invoice Date:</strong>{invoiceData.date}
              {/* {" "}
              {[...new Map(
                invoices.map((invoice) => [
                  invoice.date,
                  { date: invoice.date },
                ])
              ).values()].map((company, index) => (
                <div key={index}>
                  <p>{company.date}</p>
                </div>
              ))} */}
            </p>
          </div>

          {/* "From" and "To" sections with Flexbox */}
          <div
            className="invoice-address"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: '45%', minWidth: "200px" }}>
              <h4>From,</h4>
              {[...new Map(
                invoices.map((invoice) => [
                  invoice.company_name,
                  { company_name: invoice.company_name, company_address: invoice.company_address, company_contact: invoice.company_contact },
                ])
              ).values()].map((company, index) => (
                <div key={index}>
                  <p>{company.company_name}</p>
                  <p>{company.company_address}</p>
                  <p>{company.company_contact}</p>
                </div>
              ))}
            </div>

            <div style={{ width: '45%', minWidth: "200px" }}>
              <h4>To,</h4>
              {/* {[...new Map(
                invoices.map((invoice) => [
                  invoice.party_name,
                  { party_name: invoice.party_name, party_address: invoice.party_address, party_phone: invoice.party_phone },
                ])
              ).values()].map((party, index) => ( */}
                {/* <div key={index}> */}
                  <p>{invoiceData.party_name}</p>
                  <p>{invoiceData.address}</p>
                  <p>{invoiceData.contact}</p>
                {/* </div> */}
              {/* ))} */}
            </div>
          </div>

          {/* Table of Items */}
          <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Items</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>HSN</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Price</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Quantity</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Tax</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Discount</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Total</th>
              </tr>
            </thead>
            <tbody style={{ color: "black" }}>
              {invoices.map((invoice, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.item_name}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.hsn ? invoice.hsn : invoice.sac}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.rate}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.quantity}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.tax}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.discount}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.totalamount}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Footer Section */}
          {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
            <table style={{ width: "50%", borderCollapse: "collapse", textAlign: "right" }}>
              <tbody>
                <tr>
                  <td style={{ padding: "10px",color:"black" }}>Sub Total:</td>
                  <td style={{ padding: "10px" ,color:"black"}}>{subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" ,color:"black"}}>CGST:</td>
                  <td style={{ padding: "10px" ,color:"black"}}>{totaltax.toFixed(2) / 2}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" ,color:"black"}}>SGST:</td>
                  <td style={{ padding: "10px" ,color:"black"}}>{totaltax.toFixed(2) / 2}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" ,color:"black" }}>Tax Amount:</td>
                  <td style={{ padding: "10px" ,color:"black"}}>{totaltax.toFixed(2)}</td>
                  
                </tr>
               
                <tr style={{ fontWeight: "bold", borderTop: "2px solid #000" }}></tr>
                <tr style={{ fontWeight: "bold" }}>
                  <td style={{ padding: "10px" ,color:"black" }}>Grand Total:</td>
                  <td style={{ padding: "10px" ,color:"black"}}>{subtotal + parseFloat(totaltax.toFixed(2))}</td>
                </tr>
              </tbody>
            </table>
          </div> */}
           <section className="balance-info mt-5" style={{ marginTop: '-3vh' }}>
                <div className="row">
                  <div className="col-md-7"></div>
                  <div className="col-md-4">
                    <br />
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td style={{ color: "black" }}>Sub Total :</td>
                          <td style={{ color: "black" }}>{subtotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", color: "black" }}>CGST :</td>
                          <td style={{ padding: "10px", color: "black" }}>{totaltax.toFixed(2) / 2}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", color: "black" }}>SGST :</td>
                          <td style={{ padding: "10px", color: "black" }}>{totaltax.toFixed(2) / 2}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", color: "black" }}>Tax Amount :</td>
                          <td style={{ padding: "10px", color: "black" }}>{totaltax.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ backgroundColor: '#000' }} />
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th style={{ color: '#000',fontSize:"20px" }} >Grand Total &nbsp;&nbsp;&nbsp;&nbsp;</th>
                          <th style={{ color: '#000',fontSize:"20px"  }}>:</th>
                          <th style={{ color: '#000', textAlign: 'right',fontSize:"20px"  }}>
                            {subtotal + parseFloat(totaltax.toFixed(2))}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
          </div>
        </div>
        </div>
      )}
           {activeTemplate === "template3" && (
          
          <div style={{overflow:"auto"}}>
            <div id="invoice-box" 
             className="a4-page"
            // className="printTemplates template3 overflow-auto" 
            style={{
              border: "none",
              boxShadow: "0px 10px 10px rgba(0.2, 0.2, 0.2, 0.2)",
              paddingLeft: "18px",
              paddingRight:"18px", 
              paddingTop:"25px" // Added padding to ensure no content overlaps the box-shadow
          }} ref={invoiceRef}>
              {/* <div className='PrintPage ' > */}
            {/* <div className="mb-5 page" > */}
              <div id="printdiv3">
                <div className="row">
                 
                  <div className="col-sm-12" style={{ backgroundColor: '#3d4465', color: 'white', padding: '10px 0' }}>
                    <br />
                    <p style={{ fontSize: '4vh', textAlign: 'center', color: 'white' }}>SALES INVOICE</p>
                    <p style={{ textAlign: 'center', color: 'white' }}>
  {[...new Map(invoices.map((invoice) => [
    invoice.company_name,
    {
      company_name: invoice.company_name,
      company_address: invoice.company_address,
      company_contact: invoice.company_contact
    },
  ])).values()].map((company, index) => (
    <div key={index}>
      <p style={{ marginBottom: '5px' }}>{company.company_name}</p>
      <p style={{ marginBottom: '5px' }}>{company.company_address}</p>
      <p style={{ marginBottom: '5px' }}>{company.company_contact}</p>
    </div>
  ))}
</p>

                  </div>
                </div>
                <div className="row col-12" style={{ marginTop: '-7vh' }}>
                  <div className="col-md-1"></div>
                  <div className="col-5">
                    <br /><br /><br />
                    <p style={{ color: 'black' }}>
  {[...new Map(invoices.map((invoice) => [
    invoice.party_name,
    {
      party_name: invoice.party_name,
      party_address: invoice.party_address,
      party_phone: invoice.party_phone
    },
  ])).values()].map((party, index) => (
    <div key={index}>
      <p style={{ marginBottom: '5px' }}>{party.party_name}</p>
      <p style={{ marginBottom: '5px' }}>{party.party_address}</p>
      <p style={{ marginBottom: '5px' }}>{party.party_phone}</p>
    </div>
  ))}
</p>

                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-4">
                    <br /><br /><br />
                   
                    <table className="table table-borderless w-100" style={{ marginTop: '-5vh', background: "white", }}>
                      <tbody>
                        <tr>
                          <td style={{ color: '#000' ,fontSize:"15px"}}>Invoice No:</td>
                          <td style={{ color: '#000', textAlign: 'left' ,fontSize:"15px"}}>
                            <b>{invoices[0]?.invoiceNo}</b>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ color: '#000' ,fontSize:"15px"}}>Invoice Date:</td>
                          <td style={{ color: '#000', textAlign: 'left' ,fontSize:"15px"}}>
                            <b>{invoices[0]?.date}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  {/* </div> */}
                </div>
                <div className="col-md-1"></div>
                {/* <div className="col-md-10 " > */}
                  <br />
                  <div
  className="col-md-10 d-flex justify-content-center" // Added Bootstrap classes for centering
  style={{ margin: "auto", textAlign: "center" }} // Added custom styles for further centering
>
  {/* <br /> */}
          {/* <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}> */}
                  {/* <table className="table table-hover table-bordered template3table ml-2 pl-5 bg-white " id="table3" style={{width:"100%", border: '1px solid black', marginTop: '-4vh' }}>
                    <thead>
                      <tr className="template3tablehead" style={{ backgroundColor: 'white' }}>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>Items</th>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>HSN</th>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>Quantity</th>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>Rate</th>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>Tax</th>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>Discount</th>
                        <th className="text-center" style={{ color: 'rgb(0, 0, 0)' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: "black" }}>
                      {invoices.map((invoice, index) => (
                        <tr key={index}>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.item_name}</td>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.hsn}</td>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.rate}</td>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.quantity}</td>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.tax}</td>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.discount}</td>
                          <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.totalamount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                     {/* <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Items</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>HSN</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Price</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Quantity</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Tax</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Discount</th>
                <th style={{ padding: "10px", border: "1px solid #ddd", textAlign: "left", color: "black" }}>Total</th>
              </tr>
            </thead>
            <tbody style={{ color: "black" }}>
              {invoices.map((invoice, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.item_name}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.hsn}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.rate}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.quantity}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.tax}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.discount}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.totalamount}</td>
                </tr>
              ))}
            </tbody>
          </table> */}
          {/* <div className='table'> */}
          <table className="table table-bordered mt-4">
  <thead>
    <tr className="bg-light">
      <th>Items</th>
      <th>HSN</th>
      <th>Price</th>
      <th>Quantity</th>
      <th>Tax</th>
      <th>Discount</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    {invoices.map((invoice, index) => (
      <tr key={index}>
        <td>{invoice.item_name}</td>
        <td>{invoice.hsn}</td>
        <td>{invoice.rate}</td>
        <td>{invoice.quantity}</td>
        <td>{invoice.tax}</td>
        <td>{invoice.discount}</td>
        <td>{invoice.totalamount}</td>
      </tr>
    ))}
  </tbody>
</table>
{/* <div> */}
                </div>
                {/* </div> */}
              </div>
              <section className="balance-info" style={{ marginTop: '-3vh' }}>
                <div className="row">
                  <div className="col-md-7"></div>
                  <div className="col-md-4">
                    <br />
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td style={{ color: "black" }}>Sub Total :</td>
                          <td style={{ color: "black" }}>{subtotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", color: "black" }}>CGST :</td>
                          <td style={{ padding: "10px", color: "black" }}>{totaltax.toFixed(2) / 2}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", color: "black" }}>SGST :</td>
                          <td style={{ padding: "10px", color: "black" }}>{totaltax.toFixed(2) / 2}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", color: "black" }}>Tax Amount :</td>
                          <td style={{ padding: "10px", color: "black" }}>{totaltax.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ backgroundColor: '#000' }} />
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th style={{ color: '#000',fontSize:"20px" }} >Grand Total &nbsp;&nbsp;&nbsp;&nbsp;</th>
                          <th style={{ color: '#000',fontSize:"20px"  }}>:</th>
                          <th style={{ color: '#000', textAlign: 'right',fontSize:"20px"  }}>
                            {subtotal + parseFloat(totaltax.toFixed(2))}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
         </div>
          // </div>
          
           )}
            {activeTemplate === "template2" && (
              <div style={{overflow:"auto"}}>
        <div id="invoice-box" 
         className="a4-page"
        // className="printTemplates template2 overflow-auto" 
        style={{ display: 'block' , fontFamily: "Arial, sans-serif",
          border: "none",
          boxShadow: "0px 10px 10px rgba(0.2, 0.2, 0.2, 0.2)",  }} ref={invoiceRef}>
            <div className='PrintPage' 
          style={{textAlign:"center",marginBottom:"10px"}}
          >
        {/* <div className="my-5 page"  style={{
            // padding: "20px",

            // width: "100%",
            // maxWidth: "100%",
         
           size:'A4'
          }}> */}
          <div id="printdiv2" style={{textAlign:"center"}}>
          <h3
              className=" text-light "
              style={{
                backgroundColor: "#3d4465",
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                marginTop:"20px",
                position: "relative",
                // paddingTop:"25px"
                // marginBottom:"10px"
              }}
            >
              <span className="ml-5 pl-5" style={{ flex: 1,  fontWeight: "bold" }}>
              <b  style={{textAlign:"center"}}>SALES INVOICE</b>
              </span>
              <div className="col-md-4 d-flex justify-content-end">
  <div className="text-white">
    <p className="mb-0 mt-2">
      Invoice No: <b>{invoiceData.invoice_no}</b>
    </p>
    <p className="mb-0 mt-2">
      Invoice Date: <b>{invoiceData.date}</b>
    </p>
  </div>
</div>
            </h3>
            {/* Header Section */}
            {/* <div className="row px-5 py-4" style={{ backgroundColor: '#3d4465',boxShadow: "0px 10px 10px rgba(0.2, 0.2, 0.2, 0.2)" }}>
              <div className="col-md-4"></div>
              <div className="col-md-4 d-flex justify-content-center">
                <h3 className="text-white">
                  <b>SALES INVOICE</b>
                </h3>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
  <div className="text-white">
    <p className="mb-0 mt-2">
      Invoice No: <b>{invoiceData.invoice_no}</b>
    </p>
    <p className="mb-0 mt-2">
      Invoice Date: <b>{invoiceData.date}</b>
    </p>
  </div>
</div>

            </div> */}
  
            {/* Address Section */}
            <div className="px-5 py-1">
            {/* <section className="invoice-header text-center mb-3">
        <h2><b>SALES INVOICE</b></h2>
      </section> */}

      {/* Address Section */}
      <section className="store-user">
        <div className="col-12">
          <div className="row pb-2">
            {/* Billing Address */}
            <div className="col-4 pl-0">
              <label
                className="text-white w-100 p-1 mb-0"
                style={{
                  backgroundColor: '#3d4465',
                  borderTopRightRadius: '4vh',
                  borderBottomRightRadius: '4vh'
                }}
              >
                <b>BILLING ADDRESS</b>
              </label><br/><br/><br/><br/><br/><br/>
              {[...new Map(
                invoices.map((invoice) => [
                  invoice.company_name,
                  {
                    company_name: invoice.company_name,
                    company_address: invoice.company_address,
                    company_contact: invoice.company_contact
                  },
                ])
              ).values()].map((company, index) => (
                <div key={index}>
                  <p style={{ marginBottom: '5px' }}>{company.company_name}</p>
                  <p style={{ marginBottom: '5px' }}>{company.company_address}</p>
                  <p style={{ marginBottom: '5px' }}>{company.company_contact}</p>
                </div>
              ))}
            </div>

            <div className="col-4"></div>

            {/* Shipping Address */}
            <div className="col-4 pr-0">
              <label
                className="text-white w-100 p-1 "
                style={{
                  backgroundColor: '#3d4465',
                  borderTopRightRadius: '4vh',
                  borderBottomRightRadius: '4vh'
                }}
              >
                <b>SHIPPING ADDRESS</b>
              </label><br/><br/><br/><br/><br/><br/>
              {[...new Map(
                invoices.map((invoice) => [
                  invoice.party_name,
                  {
                    party_name: invoice.party_name,
                    party_address: invoice.party_address,
                    party_phone: invoice.party_phone
                  },
                ])
              ).values()].map((party, index) => (
                <div key={index}>
                  <p style={{ marginBottom: '5px' }}>{party.party_name}</p>
                  <p style={{ marginBottom: '5px' }}>{party.party_address}</p>
                  <p style={{ marginBottom: '5px' }}>{party.party_phone}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
  
              {/* Products Table Section */}
              <section className="product-area mt-2">
                <table className="table table-hover table-bordered" id="table2">
                  <thead style={{ backgroundColor: '#3e23b9e1' }}>
                    <tr className="template3tablehead">
                      {['Items', 'HSN', 'Quantity', 'Rate', 'Tax', 'Discount', 'Total'].map((header) => (
                        <th key={header} className="text-center" style={{ background:"white",color: '#000' }}>
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ color: "black" }}>
              {invoices.map((invoice, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.item_name}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.hsn}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.rate}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.quantity}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.tax}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.discount}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{invoice.totalamount}</td>
                </tr>
              ))}
            </tbody>

                </table>
              </section>
  
              {/* Balance Info Section */}
              <section className="balance-info">
                <div className="row mt-3">
                  <div className="col-4"></div>
                  <div className="col-4"></div>
                  <div className="col-4">
                    <br /><br />
                    <table className="table table-borderless">
                    <tbody>
  <tr>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>Sub Total:</td>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>{subtotal.toFixed(2)}</td>
  </tr>
  <tr>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>CGST :</td>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>{(totaltax / 2).toFixed(2)}</td>
  </tr>
  <tr>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>SGST :</td>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>{(totaltax / 2).toFixed(2)}</td>
  </tr>
  <tr>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>Tax :</td>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>{totaltax.toFixed(2)}</td>
  </tr>
  <tr style={{ fontWeight: "bold", borderTop: "2px solid #000" }}>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>GrandTotal:</td>
    <td style={{ padding: "10px", color: "black", textAlign: "right" }}>
      {(subtotal + parseFloat(totaltax.toFixed(2))).toFixed(2)}
    </td>
  </tr>
</tbody>

                    </table>
                    {/* <hr style={{ backgroundColor: '#000' }} />
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <th style={{ color: '#000',fontSize:"20px" }}>Grand Total &nbsp;&nbsp;&nbsp;&nbsp;</th>
                          <th style={{ color: '#000',fontSize:"20px"  }}>:</th>
                          <th style={{ color: '#000', textAlign: 'right',fontSize:"20px"  }}>{subtotal + parseFloat(totaltax.toFixed(2))}</th>
                        </tr>
                      </tbody>
                    </table> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        </div>
   </div>
      )}
             <div style={{ marginTop: "20px", textAlign: "center" }}>
        <button
          style={{ margin: "10px", padding: "10px 20px", cursor: "pointer", background: "#3d4465", color: "#fff", border: "none" }}
          onClick={() => setActiveTemplate("template1")}
        >
           1
        </button>
        <button
          style={{ margin: "10px", padding: "10px 20px", cursor: "pointer", background: "#3d4465", color: "#fff", border: "none" }}
          onClick={() => setActiveTemplate("template2")}
        >
          2
        </button>
        <button
          style={{ margin: "10px", padding: "10px 20px", cursor: "pointer", background: "#3d4465", color: "#fff", border: "none" }}
          onClick={() => setActiveTemplate("template3")}
        >
           3
        </button>
           </div>
           
         </div>
             
              ) : (
                <div id="invoice-box"  className='d-flex justify-content-center align-items-center vh-100 ' style={{  display: 'block' ,background:"white", justifyContent: 'center', alignItems: 'center'  }} >
      <div className="my-5 page justify-content-center align-items-center" style={{ width: 'fit-content' }} >
        <div className="row ml-1" style={{ marginTop: '20%', width: '98%' }} id="printslip"  >
          <div className="col-md-3"></div>
<div className='PrintPage1'  >
          <div
            className="card bg-white"
            style={{
              border: '1px dotted black',
              boxShadow: '2px 2px 10px 3px rgba(0, 0, 0, 0.397),', 
            }}  ref={invoiceRef}
          >
            <div className="mt-2" style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '1vh', color: 'black' }}>
                <b> {[...new Map(
                invoices.map((invoice) => [
                  invoice.company_name,
                  { company_name: invoice.company_name, company_address: invoice.company_address, company_contact: invoice.company_contact },
                ])
              ).values()].map((company, index) => (
                <div key={index}>
                  <p style={{color:"black"}}>{company.company_name}</p>
                  <p style={{color:"black"}}>{company.company_address}</p>
                  <p style={{color:"black"}}>{company.company_contact}</p>
                </div>
              ))}</b>
              </div>
              <span style={{ fontSize: 'x-small', color: 'black' }}>
             
              </span>
            </div>

            <div className="ml-4 mt-2" style={{ color: 'black' }}>
              <div className="row" style={{ borderRight: '3px solid transparent' }}>
                <div className="col-md-6 text-left">Invoice No:</div>
                <div className="col-md-5 text-right">{invoices[0]?.invoiceNo}</div>
              </div>
              <div className="row" style={{ borderRight: '3px solid transparent' }}>
                <div className="col-md-6 text-left">Customer Name:</div>
                <div className="col-md-5 text-right"> {[...new Map(
                invoices.map((invoice) => [
                  invoice.party_name,
                  {
                    party_name: invoice.party_name,
                    party_address: invoice.party_address,
                    party_phone: invoice.party_phone
                  },
                ])
              ).values()].map((party, index) => (
                <div key={index}>
                  <p style={{ marginBottom: '5px',color:"black" }}>{party.party_name}</p>
                 
                </div>
              ))}</div>
              </div>
              <div className="row" style={{ borderRight: '3px solid transparent' }}>
                <div className="col-md-6 text-left">Invoice Date:</div>
                <div className="col-md-5 text-right">{invoices[0]?.date}</div>
              </div>
            </div>

            <table style={{ width: "80%", marginTop: "20px", fontSize: "10px"  }}>
            <thead>
              <tr>
                <th style={{ padding: "10px",  textAlign: "left", color: "black",borderBottom: "1px solid gray"  }}> Items </th>
                <th style={{ padding: "10px", borderBottom: "1px solid gray", textAlign: "left", color: "black" }}>HSN</th>
                <th style={{ padding: "10px", borderBottom: "1px solid gray", textAlign: "left", color: "black" }}>Price</th>
                <th style={{ padding: "10px", borderBottom: "1px solid gray", textAlign: "left", color: "black" }}>Quantity</th>
                <th style={{ padding: "10px", borderBottom: "1px solid gray", textAlign: "left", color: "black" }}>Tax</th>
                {/* <th style={{ padding: "10px", borderBottom: "1px solid gray", textAlign: "left", color: "black" }}>Discount</th> */}
                <th style={{ padding: "10px", borderBottom: "1px solid gray", textAlign: "left", color: "black" }}>Total</th>
              </tr>
            </thead>
            <tbody style={{ color: "black" }}>
              {invoices.map((invoice, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px" }}>{invoice.item_name}</td>
                  <td style={{ padding: "10px"  }}>{invoice.hsn}</td>
                  <td style={{ padding: "10px" }}>{invoice.rate}</td>
                  <td style={{ padding: "10px" }}>{invoice.quantity}</td>
                  <td style={{ padding: "10px" }}>{invoice.tax}</td>
                  {/* <td style={{ padding: "10px"}}>{invoice.discount}</td> */}
                  <td style={{ padding: "10px"}}>{invoice.totalamount}</td>
                </tr>
              ))}
            </tbody>
          </table>

            <div>
              <br />
              <div className="row ml-1">
                <div className="col-md-5 text-left" style={{ color: 'black' }}>Subtotal</div>
                <div className="col-md-6 text-right" style={{ color: 'black' }}>{subtotal.toFixed(2)}</div>
              </div>
              <div className="row ml-1">
                <div className="col-md-5 text-left" style={{ color: 'black' }}>CGST</div>
                <div className="col-md-6 text-right" style={{ color: 'black' }}>{(totaltax / 2).toFixed(2)}</div>
              </div>
              <div className="row ml-1">
                <div className="col-md-5 text-left" style={{ color: 'black' }}>SGST</div>
                <div className="col-md-6 text-right" style={{ color: 'black' }}>{(totaltax / 2).toFixed(2)}</div>
              </div>
              <hr className="ml-2 mr-2" style={{ color: 'black', borderBottom: '1px solid black' }} />
            </div>

            <div className="mb-2">
              <div className="row">
                <div className="col-md-5 text-left ml-2" style={{ fontSize: 'large', color: 'black', fontWeight: 'bold' }}>Total</div>
                <div className="col-md-6 text-right" style={{ fontSize: 'large', color: 'black', fontWeight: 'bold' }}>{subtotal + parseFloat(totaltax.toFixed(2))}</div>
              </div>
            </div>

            <div className="ml-2 mt-2" style={{color:"black"}}>
              <p  style={{color:"black"}}>
                Created by:{invoices[0]?.company_name}
                <br /> Printed by: {invoices[0]?.company_name} On {formattedDateTime}
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
              )}
            </div>
          </div>
        </div>
     </div>
     </div>
    // </div>
  );
}


