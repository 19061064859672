import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "./SaleInvoice.css"; // Import the CSS file here
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../functions/config";
export default function SaleInvoice() {
  const location = useLocation();
  const invoiceData = location.state?.invoice;
  const [transactionData, setTransactionData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
    // `${config.base_url}/api/sales-invoice-history/`
      .get( `${config.base_url}api/sales-invoice-history/`)
      .then((response) => {
        // Filter data based on invoice ID
        console.log("+++", invoiceData.id)
        const filteredData = response.data.filter(
          (invoice) => invoice.salesinvoice.id === invoiceData.id
        );
        setTransactionData(filteredData);
        console.log("Filtered Data:", filteredData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel container-fluid  pl-2 pr-2" >
          <div style={{ background:"#3d4465" }}>
          <div className="table-container " >
            {/* Header Section */}
            <div className="table-header d-flext flex-wrap" >
             <div><h2>Transaction History</h2></div> 
             <div>  <a href="#" className="back-button" onClick={handleBack}>
                &larr; Back
              </a></div> 
            </div>

            {/* Table Section */}
            <div className="table-responsive" >
            <table className="table " style={{background:"white",width: "100%"}}>
              <thead >
                <tr >
                  <th >#</th>
                  <th>Date</th>
                  <th >Invoice Number</th>
                  <th >Name</th>
                  <th>Amount</th>
                  <th>By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {transactionData.length > 0 ? (
                  transactionData.map((row, index) => (
                    <tr key={row.id}>
                      <td>{index + 1}</td>
                      <td>{row.date}</td>
                      <td>{row.salesinvoice.invoice_no}</td>
                      <td> {(row.salesinvoice.party_name && row.salesinvoice.party_name.toLowerCase() !== "null") ? row.salesinvoice.party_name : ""}
                      </td>
                      <td>{row.salesinvoice.grandtotal}</td>
                      {/* <td>{row.company_name || "None None"}</td> */}
                      <td>{row.staff_name ? row.staff_name : row.company_name || "None None"}</td>

                      <td>
                        <span
                          className={`status ${
                            row.action === "CREATED" ? "created" : "updated"
                          }`} style={{color:"blue"}}
                        >
                          {row.action}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
