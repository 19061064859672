import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import "./styles/Sidebar.css";
import Cookies from "js-cookie";
import config from "../../functions/config";
import axios from "axios";

const Sidebar = () => {
  const [menuState, setMenuState] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const ID = Cookies.get("user_id");

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }

  const toggleMenuState = (menu) => {
    setMenuState((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[menu] = !prevState[menu];
      return newState;
    });
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});

    const dropdownPaths = [
      { path: "/company_home", state: "appsMenuOpen" },
      
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevState) => ({ ...prevState, [obj.state]: true }));
      }
    });
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [location]);

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const fetchParties = async () => {
    try {
      const response = await axios.get(`${config.base_url}/fetch_parties/${ID}/`);
      const parties = response.data.parties;
      if (parties.length > 0) {
        const firstPartyId = parties[0].id;
        navigate(`/view_party/${firstPartyId }`); 
      } else {
        console.warn("No parties found.");
        navigate("/default_party"); 
      }
    } catch (error) {
      console.error("Error fetching parties:", error);
     
    }
  };
  

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <br></br>
      <ul className="nav">
        <li
          className={
            isPathActive("/company_home") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/company_home">
            <span className="menu-title" style={{ color: 'white' }}>
              <Trans>Dashboard</Trans>
            </span>
            <i className="mdi mdi-home menu-icon mb-0" style={{ color: 'white' }}></i>
          </Link>
        </li>


        <li
          className={
           isPathActive("") ? "nav-item active" : "nav-item"
          }>
         <div className="nav-link d-flex justify-content-between">
        <span className="menu-title" style={{ color: "white" }}>
        <Trans>

          <Link className="text-white"
           onClick={fetchParties}
              >
         Parties
          </Link>

      </Trans>
    </span>
    <Link to={"/add_party"}>
      <i className="mdi mdi-plus menu-icon mb-0" style={{ color: "white" }}> </i>
    </Link>
  </div>
</li>

        <li
          className={
            isPathActive("") ? "nav-item active" : "nav-item"
          }
        >
          <div className="nav-link d-flex justify-content-between" >
            <span className="menu-title" style={{ color: 'white' }}>
              <Trans><Link className="text-white" to="/item-list">Items</Link></Trans>
            </span>
            <Link  to={'/add-item'}><i  className="mdi mdi-plus  menu-icon mb-0" style={{ color: 'white' }}> </i></Link>
          </div>
        </li>





          <li
            className={
              isPathActive("/customers") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.customerMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("customerMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title" style={{ color: 'white' }}>
                <Trans>Sales</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="menu-icon mb-0" style={{ color: 'white' }}></i>
            </div>
            
            {/* <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/invoice") ? "nav-link active" : "nav-link" 
                    }
                    to="/invoice" style={{ color: 'white' }}
                  >
                    <Trans>Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse> */}
             <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={isPathActive("/show_invoice") ? "nav-link active" : "nav-link"}
                    to="/show_invoice"
                    style={{ color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <span style={{ flex: 1 }}><Trans>Invoice</Trans></span>
                    {/* The + sign positioned at the right */}
                    <Link
                      to="/invoice"
                      style={{
                        color: 'white', 
                        fontSize: '20px',  // Adjust the size of the + sign
                        marginLeft: '10px', // Space between the text and the + sign
                      }}
                    >
                      +
                    </Link>
                  </Link>
                </li>
              </ul>
            </Collapse>

            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={isPathActive("/credit_note") ? "nav-link active" : "nav-link"}
                    to="/credit_note"
                    style={{ color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <span style={{ flex: 1 }}><Trans>Sales<br></br> Return/Credit Note</Trans></span>
                    {/* The + sign positioned at the right */}
                    <Link
                      to="/add_credit_note"
                      style={{
                        color: 'white', 
                        fontSize: '20px',  // Adjust the size of the + sign
                        marginLeft: '10px', // Space between the text and the + sign
                      }}
                    >
                      +
                    </Link>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>


          <li
            className={
              isPathActive("/vendor") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.VendorMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("VendorMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title" style={{ color: 'white' }}>
                <Trans>Purchase</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className=" menu-icon mb-0" style={{ color: 'white' }}></i>
            </div>
            
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item d-flex align-items-center justify-content-between">
                  <Link
                    className={
                      isPathActive("/bill") ? "nav-link active" : "nav-link"
                    }
                    to="/bill" style={{ color: 'white' }}
                  >
                    <Trans>Purchase Bill</Trans>
                  </Link>
                  <Link
                    to="/add_purchasebill"
                    style={{ marginLeft: 'auto' }}
                  >
                    <i
                      className="mdi mdi-plus menu-icon mb-0"
                      style={{ color: 'white' }}
                    ></i>
                  </Link>
                </li>
              </ul>
            </Collapse>

          <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item d-flex align-items-center justify-content-between">
                  <Link
                    className={
                      isPathActive("/debitnote") ? "nav-link active" : "nav-link"
                    }
                    to="/debitnote" style={{ color: 'white' }}
                  >
                    <Trans>Purchase <br />Return/Dr note</Trans>
                  </Link>
                  <Link
                    to="/adddebitnote"
                    style={{ marginLeft: 'auto' }}
                  >
                    <i
                      className="mdi mdi-plus menu-icon mb-0"
                      style={{ color: 'white' }}
                    ></i>
                  </Link>
                </li>
              </ul>
            </Collapse>


            </li>


        <li
          className={
            isPathActive("") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/stock_report">
            <span className="menu-title" style={{ color: 'white' }}>
              <Trans>Stock Report</Trans>
            </span>
            <i className="mdi mdi-file menu-icon mb-0" style={{ color: 'white' }}></i>
          </Link>
        </li>


        <li
          className={
            isPathActive("/company_sales") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/company_sales">
            <span className="menu-title" style={{ color: 'white' }}>
              <Trans>Sales Report</Trans>
            </span>
            <i className="mdi mdi-file menu-icon mb-0" style={{ color: 'white' }}></i>
          </Link>
        </li>


        <li
          className={
            isPathActive("") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/Purchasereport">
            <span className="menu-title" style={{ color: 'white' }}>
              <Trans>Purchase Report</Trans>
            </span>
            <i className="mdi mdi-file menu-icon mb-0" style={{ color: 'white' }}></i>
          </Link>
        </li>

      </ul>

    </nav>
  );
};

export default Sidebar;




