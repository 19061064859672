import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Stock_report.css";
import config from '../../../functions/config';
import pdf from "../../../assets/images/faces/pdf_icon.jpg"
import excel from "../../../assets/images/faces/excel.png"
import share from "../../../assets/images/faces/share.png"
import printer from "../../../assets/images/faces/printer.jpeg"
import axios from 'axios';
import Cookies from "js-cookie";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";

function StockReport() {

  const ID = Cookies.get("user_id");

  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [report, setReport] = useState("");

  const fetchStockreport = () => {
    axios
      .get(`${config.base_url}/stockReport/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchStockreport();
  }, []);

  function exportToExcel() {
    const Table = document.getElementById("expenseTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "stock_report.xlsx");
  }
  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("expenseTable");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Stock_report" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function printSection() {
    var divToPrint = document.getElementById("expenseTable");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }
  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  function searchTable(){
    var rows = document.querySelectorAll('#expenseTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }

  const handleCustomize = async (startDate, endDate) => {
    const det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
    };
  
    try {
      const res = await axios.post(`${config.base_url}/stockReportCustomized/`, det);
      if (res.data.status) {
        // Filter out rows with both Quantity In and Quantity Out as 0
        const filteredData = res.data.reportData.filter(
          (item) =>
            new Date(item.date) >= new Date(startDate) &&
            new Date(item.date) <= new Date(endDate)
        );

  
        setReportData(filteredData); // Update the state with filtered data
        setOtherDetails(res.data.otherDetails);
        setStartDate(res.data.startDate);
        setEndDate(res.data.endDate);
      }
    } catch (err) {
      console.error("ERROR=", err);
      Swal.fire({
        icon: "error",
        title: `${err.response?.data?.message || "An error occurred"}`,
      });
    }
  };
  
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          
          var em = {
            Id: ID,
            start: st,
            end: end,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/Share_Stock_Report/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  // function toggleContent() {
  //   var contentDiv = document.getElementById("contentDiv");
    
  //   if (contentDiv.style.display === "block") {
  //     contentDiv.style.display = "none";
  //   } else {
  //     contentDiv.style.display = "block";
   
  //   }
    
  // }
  const [activeFilter, setActiveFilter] = useState(null);
  const toggleContent = (filterType) => {
    setActiveFilter((prevFilter) => (prevFilter === filterType ? null : filterType));
  };
  
  const applyFilter = (key, value) => {
    const filtered = reportData.filter((item) => 
      item[key]?.toString().trim().toLowerCase() === value.trim().toLowerCase()
    );
    setReportData(filtered);
    setActiveFilter(null);
  };
  

  return (
    <div className="container-scroller" >
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#8290c6", maxWidth: "100vw" }}
        >
          <div className="content-wrapper mt-3 " >
            <div className="body-wrapper p-3" >
              <div className="container-fluid">
                <div className="page-content" >
                  <div className="cards radius-15 h-20" >
                    <div
                      className="card-body"
                      style={{ background: "#3d4465", borderRadius: "15px"}}
                    >
                  <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row">
                            
                            <div className="date-container mb-3">
                            <labels className="date-field">From Date:</labels>
                            <input type="date" className="form-controls" 
                              name="start_date"
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(e.target.value); // Update the state
                              handleCustomize(e.target.value, endDate); // Trigger filtering
                            }}
                            />
                            </div>

                           
                            <div className="date-container mb-3">
                            <labels className="date-field">To Date:</labels>
                            <input type="date" className="form-controls" 
                            value={endDate}
                             name="end_date"
                            onChange={(e) => {
                              setEndDate(e.target.value); // Update the state
                              handleCustomize(startDate, e.target.value); // Trigger filtering
                            }}
                            />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 d-flex justify-content-end">
                              <button className="btn d-flex flex-column align-items-center mr-2" onClick={exportToExcel}>
                                <img src={excel} alt="excel_icon" style={{ width: 20 }} />
                                <span className="btn-text mt-1">Export</span>
                              </button>
                              <button className="btn d-flex flex-column align-items-center mr-2" onClick={reportPDF}>
                                <img src={pdf} alt="pdf_icon" style={{ width: 20 }} />
                                <span className="btn-text mt-1">PDF</span>
                              </button>
                              <div className="dropdown">
                                <button
                                  className="btn d-flex flex-column align-items-center  mr-2"
                                  type="button"
                                  id="shareBtn"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src={share} alt="share_icon" style={{ width: 20 }} />
                                  <span className="btn-text mt-1">Share</span>
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="shareBtn"
                                  style={{ backgroundColor: "white" }}
                                >
                                  <a
                                    href={shareUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="dropdown-item text-center"
                                    style={{ color: "black" }}
                                  >
                                    WhatsApp
                                  </a>
                                  <button
                                    className="dropdown-item text-center"
                                    style={{ color: "black" }}
                                    data-toggle="modal"
                                    data-target="#shareToEmail"
                                  >
                                    Email
                                  </button>
                                </div>
                              </div>
                              <button className="btn d-flex flex-column align-items-center" onClick={printSection}>
                                <img src={printer} alt="print_icon" style={{ width: 20 }} />
                                <span className="btn-text mt-1">Print</span>
                              </button>
                            </div>
                            </div>
                        </div>

                        </div>
                        <div className="stock  mt-3 p-0 "  style={{backgroundColor: "#3d4465",width:"100%"}}>
              <div className="card-body" >
                <div className="container-fluid" >
                    <div className="col-sm-12 col-lg-3" style={{marginLeft:"5vh"}}>
                    <h3 className="tb fw-bolder pt-2" style={{color:"white"}}>Stock report</h3>
                    </div>
                  <div className="row">
                    <div className="col-md-4 col-12 mb-2">
                      <div className="d-flex flex-column flex-md-row">
                       
                        <input
                          type="text"
                          id="search"
                          className="form-control mb-2 mb-md-0"
                          placeholder="Search..."
                          autoComplete="off"
                          onKeyUp={searchTable}
                        />
                        
                   </div>
                   </div>
                   </div>
                </div>
              </div>
              </div>

              <div className="table-responsive" >
                <table
                  className="table table-responsive-md  "
                  id="expenseTable"
                  style={{ textAlign: "center" }}
                  
                >
                  <thead>
                    <tr >
                      
                      <th style={{backgroundColor:"#3d4465",color:"white"}}>DATE
                        <i className="fa fa-filter ml-2"      onClick={() => toggleContent("date")}
                           aria-hidden="true"
                          />
                        {activeFilter === "date" && (
                          <div
                            id="contentDiv"
                            className="journalcontent"
                            style={{ display: "block" }}
                          >
                            <h6>Equal To</h6>
                            <form
                              className="form reportCustomizeform px-1"
                              onSubmit={(e) => {
                                e.preventDefault();
                                const filterValue = e.target.elements.filterInput.value;
                                applyFilter("date", filterValue); // Pass the key ('date') and the value to filter
                              }}
                            >
                              <div className="px-2 w-100">
                                <input
                                  className="input date form-control"
                                  type="date"
                                  name="filterInput"
                                />
                              </div>
                              <div className="d-flex px-2 mt-3 mb-4 w-100">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary w-100"
                                  onClick={() => {
                                    fetchStockreport(); // Reset data
                                    setActiveFilter(null); // Close the dropdown
                                  }}
                                  
                               
                                >
                                  Clear
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-outline-secondary ml-1 w-100"
                                >
                                  Apply
                                </button>
                              </div>
                            </form>
                          </div>
                        )}

                      </th>
                      <th style={{ backgroundColor: "#3d4465", color: "white" }}>
                ITEM NAME
                <i
                  className="fa fa-filter ml-2"
                  onClick={() => toggleContent("itemName")}
                />
                {activeFilter === "itemName" && (
                  <div
                    id="contentDiv"
                    className="journalcontent"
                    style={{ display: "block" }}
                  >
                    <h6>Equal To</h6>
                    <form
                      className="form reportCustomizeform px-1"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const filterValue = e.target.elements.filterInput.value;
                        applyFilter("name", filterValue); // Pass the key ('date') and the value to filter
                      }}
                    >
                      <div className="px-2 w-100">
                        <input
                          className="input date form-control"
                          type="text"
                          name="filterInput"
                        
                        />
                      </div>
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                          onClick={() => {
                            fetchStockreport(); // Reset data
                            setActiveFilter(null); // Close the dropdown
                          }}
                          
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-secondary ml-1 w-100"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </th>

              <th style={{ backgroundColor: "#3d4465", color: "white" }}>
                HSN/SAC
                <i
                  className="fa fa-filter ml-2"
                  onClick={() => toggleContent("hsn")}
                />
                {activeFilter === "hsn" && (
                  <div id="itemDiv" className="journalcontent" style={{ display: "block" }}>
                    <h6>Equal To</h6>
                    <form
                      className="form reportCustomizeform px-1"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const filterValue = e.target.elements.filterInput.value;
                        applyFilter("hsn", filterValue); // Pass the key ('date') and the value to filter
                      }}
                    >
                      <div className="px-2 w-100">
                        <input
                          className="input date form-control"
                          type="text"
                          name="filterInput"
                        
                        />
                      </div>
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                          onClick={() => {
                            fetchStockreport(); // Reset data
                            setActiveFilter(null); // Close the dropdown
                          }}
                          
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-secondary ml-1 w-100"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </th>
                      <th style={{backgroundColor:"#3d4465",color:"white"}}>QUANTITY IN
                      <i className="fa fa-filter ml-2"   onClick={() => toggleContent("quantityin")}/>
                      {activeFilter === "quantityin" && (
                  <div
                    id="contentDiv"
                    className="journalcontent"
                    style={{ display: "block" }}
                  >
                    <h6>Equal To</h6>
                    <form
                      className="form reportCustomizeform px-1"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const filterValue = e.target.elements.filterInput.value;
                        applyFilter("qtyIn", filterValue); // Pass the key ('date') and the value to filter
                      }}
                    >
                      <div className="px-2 w-100">
                        <input
                          className="input date form-control"
                          type="text"
                          name="filterInput"
                          
                        />
                      </div>
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                          onClick={() => {
                            fetchStockreport(); // Reset data
                            setActiveFilter(null); // Close the dropdown
                          }}
                          
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-secondary ml-1 w-100"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                      </th>
                      <th style={{backgroundColor:"#3d4465",color:"white"}}>QUANTITY OUT
                      <i className="fa fa-filter ml-2"  onClick={() => toggleContent("quantityout")}/>
                      {activeFilter === "quantityout" && (
                  <div
                    id="contentDiv"
                    className="journalcontent"
                    style={{ display: "block" }}
                  >
                    <h6>Equal To</h6>
                    <form
                      className="form reportCustomizeform px-1"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const filterValue = e.target.elements.filterInput.value;
                        applyFilter("qtyOut", filterValue); // Pass the key ('date') and the value to filter
                      }}
                    >
                      <div className="px-2 w-100">
                        <input
                          className="input date form-control"
                          type="text"
                          name="filterInput"
                        
                        />
                      </div>
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                          onClick={() => {
                            fetchStockreport(); // Reset data
                            setActiveFilter(null); // Close the dropdown
                          }}
                          
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-secondary ml-1 w-100"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                      </th>
                      <th style={{backgroundColor:"#3d4465",color:"white"}}>STOCK IN HAND
                       <i className="fa fa-filter ml-2" onClick={() => toggleContent("stockinhand")}/>
                       {activeFilter === "stockinhand" && (
                  <div
                    id="contentDiv"
                    className="journalcontent"
                    style={{ display: "block" }}
                  >
                    <h6>Equal To</h6>
                    <form
                      className="form reportCustomizeform px-1"
                      onSubmit={(e) => {
                        e.preventDefault();
                        const filterValue = e.target.elements.filterInput.value;
                        applyFilter("cQty", filterValue); // Pass the key ('date') and the value to filter
                      }}
                    >
                      <div className="px-2 w-100">
                        <input
                          className="input date form-control"
                          type="text"
                          name="filterInput"
                        
                        />
                      </div>
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                          onClick={() => {
                            fetchStockreport(); // Reset data
                            setActiveFilter(null); // Close the dropdown
                          }}
                          
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-secondary ml-1 w-100"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody>
                  {reportData.length > 0 &&
                                reportData.map((i) => (
                                  <tr>
                                      <td className="text-center">{i.date}</td>
                                      <td className="text-center">{i.name}</td>
                                      <td className="text-center">{i.hsn}</td>
                                    
                                      <td className="text-center">{i.qtyIn}</td>
                                    
                                      <td className="text-center">{i.qtyOut}</td>
                                  
                                      <td className="text-center">{i.cQty}</td>

                               
                                  </tr>
                                ))}
                  </tbody>
                </table>
              
            
                     
                      
                         
                        
                    


                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
              <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{backgroundColor:"#3d4465"}}>
                  <div className="modal-header">
                    <h5 className="m-3 text-white">Share Report</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      onSubmit={handleShareEmail}
                      className="needs-validation px-1"
                      id="share_to_email_form"
                    >
                      <div className="card p-3 w-100">
                        <div className="form-group">
                          <labels for="emailIds">Email IDs</labels>
                          <textarea
                            className="form-control"
                            name="email_ids"
                            id="emailIds"
                            rows="3"
                            placeholder="Multiple emails can be added by separating with a comma(,)."
                            value={emailIds}
                            onChange={(e) => setEmailIds(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group mt-2">
                          <labels for="item_unitname">Message(optional)</labels>
                          <textarea
                            name="email_message"
                            id="email_message"
                            className="form-control"
                            cols=""
                            rows="4"
                            value={emailMessage}
                            onChange={(e) => setEmailMessage(e.target.value)}
                            placeholder="This message will be sent along with Report details."
                          />
                        </div>
                      </div>
                      <div
                        className="modal-footer d-flex justify-content-center w-100"
                        style={{ borderTop: "1px solid #ffffff" }}
                      >
                        <button
                          type="submit"
                          id="share_with_email"
                          className="submitShareEmailBtn w-50 text-uppercase text-secondary"
                        
                        >
                          SEND MAIL
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              </div>
    </div>

    
  );
}

export default StockReport;
