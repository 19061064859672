import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import config from "../../functions/config";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal';
import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 


function AddCreditNote() {
  const navigate = useNavigate();
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");
  const [cardWidth, setCardWidth] = useState(window.innerWidth > 768 ? "50%" : "90%");
  
  const [NewItems, setNewItems] = useState([]);
    const [partyName, setPartyName] = useState('');
    const [selectedParty, setSelectedParty] = useState('');
    const [partyPhone, setPartyPhone] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [openingBalance, setOpeningBalance] = useState(0);
    const [invoiceNumbers, setInvoiceNumbers] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState('');
    const [tableData, setTableData] = useState([]); 
    const [allItems, setAllItems] = useState([]);
    const [returnNumber, setReturnNumber] = useState(0);
    const [creditNoteData, setCreditNoteData] = useState({
      returnNumber: "",
      invoiceNumber: "",
      invoiceDate: "",
      stateOfSupply: "",
      // date: "",
      date: getTodaysDate(),
      partyName: "",
      partyPhone: "",
      billingAddress: "",
      party: "",
    });
    function getTodaysDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const [invoices, setInvoices] = useState([]);

    const ID = Cookies.get("user_id");
    const [parties, setParties] = useState([]);
    
    
    
    useEffect(() => {
      const fetchParties = async () => {
        try {
          const response = await axios.get(`${config.base_url}/fetch_parties`, {
            params: { id: ID }
          });
      
          // Check if the response is successful and contains the expected data
          if (response.status === 200) {  // Axios uses response.status to check success
            setParties(response.data.party_data);  // Set the party data
            setReturnNumber(response.data.returnNumber);  // Set the return number
            const items = response.data.party_data.flatMap(party => party.items);
            setAllItems(items);
          } else {
            console.error('Failed to fetch parties');
          }
        } catch (error) {
          console.error('Error fetching parties:', error);
        }
      };
      
    
      fetchParties();
    }, [ID]);


    const handlePartyChange = (event) => {
      const selectedPartyId = event.target.value;
      // setSelectedParty(selectedPartyId);
      setCreditNoteData({ ...creditNoteData, party: selectedPartyId });
    
      // Find the selected party in the parties array
      const selectedPartyDetails = parties.find((party) => party.id === parseInt(selectedPartyId));
      
      // If party exists, update the party details like phone number and billing address
      if (selectedPartyDetails) {
        setPartyPhone(selectedPartyDetails.phone_number);
        setBillingAddress(selectedPartyDetails.billing_address);
        setInvoiceNumbers(selectedPartyDetails.invoice_info);
        setOpeningBalance(selectedPartyDetails.opening_balance);
        
         
      }
    };
    const handleInvoiceChange = (event) => {
      const selectedInvoiceNo = event.target.value;
      // setCreditNoteData({ ...creditNoteData, invoiceNumber: selectedInvoiceNo });
    
      // Find the invoice in the invoiceNumbers array
     
      const selectedInvoice = invoiceNumbers.find((invoice) => invoice.invoice_no === parseInt(selectedInvoiceNo, 10));
      
      if (selectedInvoice) {
        setCreditNoteData({ ...creditNoteData,  invoiceNumber: selectedInvoiceNo, invoiceDate: selectedInvoice.invoice_date });  // Set invoice date
    
        // Assuming `selectedInvoice` contains product details
        const products = selectedInvoice.items.map((item, index) => ({
          sn: index + 1,  
          product: item.item_name,  
          hsnSac: item.hsn,  
          qty: item.quantity,
          price: item.rate,
          tax: item.tax, 
          igst:item.item_igst,
          gst:item.item_gst,
          discount: item.discount,  
          total: item.total_amount,  
          taxamount:item.taxamount,
          adjust:item.adjust,
          invoice_igst:item.invoice_igst,
          invoice_cgst:item.invoice_cgst,
          invoice_sgst:item.invoice_sgst,
        }));
    
        // Populate the table with products
        setTableData(products);
      }
    };
    
    
    const uniqueItems = [
      ...new Map(
        [...allItems, ...invoiceNumbers.flatMap((invoice) => invoice.items)].map(item => [item.item_name, item])
      ).values(),
      ...NewItems
    ];
    
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setCreditNoteData({ ...creditNoteData, [name]: value });
    };
    
    const handleTableChange = (e, index, field) => {
      const value = e.target.value;
      const updatedTableData = [...tableData];
    
      updatedTableData[index][field] = value;
    
      // If the selected item is from the dropdown, set its details in the table
      if (field === "product") {
        const selectedItem = uniqueItems.find(item => item.item_name === value);
    
        if (selectedItem) {
          updatedTableData[index].hsnSac = selectedItem.item_hsn || '0';  // Use 0 if not found
          updatedTableData[index].price = selectedItem.item_sale_price || '0';
          updatedTableData[index].tax = selectedItem.item_gst || '0';
          updatedTableData[index].gst = selectedItem.item_gst || '0';
          updatedTableData[index].discount = '0';  // Default discount as 0
          updatedTableData[index].igst = selectedItem.item_igst || 0; 
          updatedTableData[index].adjust = selectedItem.adjust || 0;
          updatedTableData[index].taxamount = selectedItem.taxamount || 0;
          updatedTableData[index].invoice_cgst = selectedItem.invoice_cgst || 0; 
          updatedTableData[index].invoice_igst = selectedItem.invoice_igst || 0; 
          updatedTableData[index].invoice_sgst = selectedItem.invoice_sgst || 0; 
        } else {
          // If no item is selected, set default values (0 or empty)
          updatedTableData[index].hsnSac = '0';
          updatedTableData[index].price = '0';
          updatedTableData[index].tax = '0';
          updatedTableData[index].gst = '0';
          updatedTableData[index].discount = '0';
          updatedTableData[index].item_igst = 0; 
          updatedTableData[index].item_gst = 0;
          updatedTableData[index].igst = 0; 
          updatedTableData[index].adjust = 0; 
          updatedTableData[index].taxamount = 0;
          updatedTableData[index].invoice_cgst = 0;
          updatedTableData[index].invoice_igst = 0;
          updatedTableData[index].invoice_sgst = 0; 
        }
      }
    
      // Recalculate the total after any field changes (Qty, Price, Tax, Discount)
      if (['qty', 'price', 'tax', 'discount','igst','total'].includes(field)) {
        updatedTableData[index].total = calculateTotal(updatedTableData[index]); // Recalculate total
      }
    
      // Set the updated table data
      setTableData(updatedTableData);
    };
    
  
    const calculateTotal = (row) => {
      const qty = parseFloat(row.qty) || 0;
      const price = parseFloat(row.price) || 0;
      const tax = parseFloat(row.tax) || 0;
      const discount = parseFloat(row.discount) || 0;
      const igst = parseFloat(row.igst) || 0;
    
      // Calculate IGST amount based on quantity and price
      const igstAmount = (qty * price) * (igst / 100);  // IGST is a percentage of (qty * price)
    
      // Return the total amount after adding IGST and subtracting discount
      return (qty * price) + tax - discount + igstAmount;
    };
    const calculateSubtotal = () => {
      return tableData.reduce((accum, row) => accum + parseFloat(row.total || 0), 0);
    };

    const calculateIGST = () => {
      return tableData.reduce((accum, row) => {
        // Exclude newly added rows (those with empty or default values)
        const price = parseFloat(row.price) || 0;
        const qty = parseFloat(row.qty) || 0;
        const invoice_igst = parseFloat(row.invoice_igst) || 0;
    
        // Skip rows where price, qty, or invoice_igst are not valid (default values)
        if (price === 0 || qty === 0 || invoice_igst === 0) {
          return accum;  // Skip this row
        }
    
        // Calculate IGST for valid rows
        const igstAmount = (qty * price) * (invoice_igst / 100);
    
        // Accumulate IGST
        return accum + igstAmount;
      }, 0).toFixed(2);  // Return total IGST rounded to two decimal places
    };
    const calculateCGST = () => {
      return tableData.reduce((accum, row) => {
        const price = parseFloat(row.price) || 0;
        const qty = parseFloat(row.qty) || 0;
        const invoice_cgst = parseFloat(row.invoice_cgst) || 0; // CGST rate from each row
        
        if (price === 0 || qty === 0 || invoice_cgst === 0) {
          return accum;  // Skip this row
        }
        const cgstAmount = (qty * price) * (invoice_cgst / 100);
        
        // Accumulate CGST
        return parseFloat(cgstAmount.toFixed(2));
      }, 0);
    };
    const calculateSGST = () => {
      return tableData.reduce((accum, row) => {
        const price = parseFloat(row.price) || 0;
        const qty = parseFloat(row.qty) || 0;
        const invoice_sgst = parseFloat(row.invoice_sgst) || 0; // SGST rate from each row
        
        if (price === 0 || qty === 0 || invoice_sgst === 0) {
          return accum;  // Skip this row
        }
        const sgstAmount = (qty * price) * (invoice_sgst / 100);
        
        // Accumulate SGST
        return parseFloat(sgstAmount.toFixed(2));
      }, 0);
     
    };



    const calculateAdjust = () => {
      return tableData.reduce((accum, row) => accum + parseFloat(row.adjust || 0), 0);
    };
    const calculateTaxamount = () => {
      return tableData.reduce((accum, row) => accum + parseFloat(row.taxamount || 0), 0);
    };
    
    const calculateGrandTotal = () => {
      const subtotal = parseFloat(calculateSubtotal()) || 0; // Ensure it's a number
      const taxAmount = creditNoteData.stateOfSupply === "Other State" 
        ? parseFloat(calculateIGST()) || 0
        : creditNoteData.stateOfSupply === "State" 
          ? (parseFloat(calculateCGST()) || 0) + (parseFloat(calculateSGST()) || 0)
          : 0;
      const adjustment = parseFloat(creditNoteData.adjustment) || 0; // Ensure adjustment is a number
    
      // Sum the values and round to 2 decimal places
      const grandTotal = (subtotal + taxAmount + adjustment).toFixed(2); 
    
      return parseFloat(grandTotal); // Return the rounded result as a float
    };


    
    
  
  
  const [isPartySelected, setIsPartySelected] = useState(false);
  

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [partyModalData, setPartyModalData] = useState({
    partyname: "",
    gstin: "",
    mobile: "",
    gst: "",
    address: "",
    credit: "",
    balance: "",
  });

  // const handleModalChange = (e) => {
  //   const { name, value } = e.target;
  //   setPartyModalData({
  //     ...partyModalData,
  //     [name]: value,
  //   });
  // };
  const [showGST, setShowGST] = useState(true); // Show GST and Address section first by default


// In the section where you toggle visibility
const handleGSTSectionToggle = () => {
  setShowGST(!showGST); // This will toggle the visibility of the GST section
};


  const handleAddPartyName = () => {
    // Open modal to add party details
    setIsModalOpen(true);
  };
  const handleSubmitModal = async () => {
    const userID = Cookies.get("user_id");  // Retrieve user_id from cookies
  
  if (!userID) {
    alert("User ID is missing. Please log in again.");
    return;  // If user_id is missing, stop the function.
  }
    const data = {
      party_name: partyModalData.partyname,
      phone_number: partyModalData.mobile,
      gstin: partyModalData.gstin,
      gst_type: partyModalData.gstType,
      billing_address: partyModalData.billingAddress,
      state: partyModalData.supplyState,
      email: partyModalData.email,
      opening_balance: partyModalData.openBalance,
      credit_limit: partyModalData.creditLimit,
      to_pay: partyModalData.toPay,
      to_recieve: partyModalData.toRecieve,
      date: partyModalData.date,
      company: creditNoteData.company,
      staff: creditNoteData.staff,
      user_id: userID,
    };

    console.log('Sending data to API:', data);

    try {
      // const response = await fetch('http://localhost:8000/api/parties/', {
      const response = await fetch(`${config.base_url}/api/parties/`, {  
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        alert('Party added successfully!');
        setIsModalOpen(false); // Close the modal on success
        window.location.reload();
      } else {
        const error = await response.json();
        alert('Error: ' + error.message);
        console.log('Error from API:', error);
      }
    } catch (error) {
      alert('Error: ' + error.message);
      console.log('Error:', error);
    }
};
const [gstinError, setGstinError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setPartyModalData({
      ...partyModalData,
      [name]: value,
    });
     // Validate GSTIN
     if (name === 'gstin') {
      const gstinPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
      if (value && !gstinPattern.test(value)) {
        setGstinError('Invalid GSTIN. Please follow the format: 29APPCK7465F1Z1');
      } else {
        setGstinError('');
      }
    }
    if (name === 'mobile') {
      const mobilePattern = /^[0-9]{10}$/;
      if (value && !mobilePattern.test(value)) {
        setMobileError('Invalid mobile number. Please enter exactly 10 digits.');
      } else {
        setMobileError('');
      }
    }
  };

  const userID = Cookies.get("user_id");
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });



  const handlePartyToggle = () => {
    setIsPartySelected(!isPartySelected);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      invoicecredit: {
        retrn_no: returnNumber,
        party: creditNoteData.party,
        partymob: partyPhone,
        invoiceno: creditNoteData.invoiceNumber,
        supplyplace: creditNoteData.stateOfSupply,
        subtotal: calculateSubtotal(),
        igst: calculateIGST(),
        cgst: calculateCGST(),
        sgst: calculateSGST(),
        adjust: creditNoteData.adjustment || 0,
        grandtotal: calculateGrandTotal(),
        invoice_date: creditNoteData.invoiceDate,
        date: creditNoteData.date,
      },
      items: tableData.map((row) => ({
        product: row.product,
        qty: row.qty,
        total: row.total,
        tax: row.gst || row.igst,
        discount: row.discount,
      }))
    };
  
    try {
      // Send the POST request with `user_id` as a query parameter
      const response = await fetch(`${config.base_url}/create-creditnote/?user_id=${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),  // Sending the form data in the body
      });
  
      const data = await response.json();
      if (response.ok) {
        alert("Credit Note Created Successfully")
        navigate("/credit_note")
        console.log('Credit Note Created Successfully', data);
      } else {
        // Handle errors
        console.error('Error creating Credit note:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  
  

  
  const addRow = () => {
    const newRow = {
      sn: tableData.length + 1,  // Automatically increment the SN based on current table length
      product: "",
      hsnSac: "",
      qty: "",
      price: "",
      tax: "",
      discount: "",
      total: "",
    };
    setTableData([...tableData, newRow]);
  };
  const removeRow = (index) => {
    const updatedTableData = tableData.filter((_, rowIndex) => rowIndex !== index);
    setTableData(updatedTableData);
  };



  const [isItemModalOpen,setIsItemModalOpen]=useState(false);
  const [itemModalData,setItemModalData]=useState({
   itemName:"",
   itemUnit:"",
   itemHsn:""
  })
  
  const handleAddItem=() =>{
    setIsItemModalOpen(true)
  }
  const [isTaxable, setIsTaxable] = useState(true);
  const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);

  const [newUnit, setNewUnit] = useState('');
  const [errorFeedback, setErrorFeedback] = useState('');
  const handleUnitSubmit = async (e) => {
    e.preventDefault();

    // Validate input
    if (!newUnit.trim()) {
      setErrorFeedback('Please enter a valid unit.');
      return;
    }

    try {
      const userID = Cookies.get("user_id"); // Retrieve the user ID from cookies


      
      const response = await fetch(`${config.base_url}/create-unit/?user_id=${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ unit_name: newUnit }),
      });

      const data = await response.json();

      if (response.ok) {
        alert('Unit added successfully!');
        setIsAddUnitModalOpen(false);
        setUnits((prevUnits) => [
          ...prevUnits,
          { id: data.newUnitId, unit_name: newUnit }, // Assuming the backend sends back the new unit's ID
        ]);

        setNewUnit(''); // Clear input field
        setIsItemModalOpen(true);
      } else {
        setErrorFeedback(data.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error adding unit:', error);
      setErrorFeedback('An error occurred while submitting the form.');
    }
  };
  
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await fetch(`${config.base_url}/get-units/?user_id=${userID}`);

        const data = await response.json();
        setUnits(data.units || []);
      } catch (error) {
        console.error('Error fetching units:', error);
      }
    };

    fetchUnits();
  }, []);


  const [itemData, setItemData] = useState({
    item_name: '',
    item_hsn: '',
    item_unit: '',
    item_type: '',
    item_taxable: '',
    item_gst: '',
    item_igst: '',
    item_sale_price: '',
    item_purchase_price: '',
    item_opening_stock: '',
    item_current_stock: '',
    item_stock_in_hand: '',
    item_at_price: '',
    item_date: '',
    item_min_stock_maintain: '',
    barcode: '',
  });
  
  const [itemHsnError, setItemHsnError] = useState('');
  
  
  const handleItemModalChange = (e) => {
    const { name, value } = e.target;
    setItemData({ ...itemData, [name]: value });
    if (name === 'item_hsn') {
      const hsnPattern = /^\d{6}$/; // Regex for exactly 6 digits
      if (value && !hsnPattern.test(value)) {
        setItemHsnError('Invalid HSN. Please enter exactly 6 digits.');
      } else {
        setItemHsnError('');
      }
    }
  };
 
  const handleItemSubmitModal = async () => {
    const userID = Cookies.get("user_id");
  
    if (!userID) {
      alert("User ID is missing. Please log in again.");
      return;
    }
  
    // Ensure the required fields are set
    if (!itemData.item_name || !itemData.item_hsn || !itemData.item_unit || !itemData.item_type) {
      alert('Please fill out all required fields.');
      return;
    }
  
    const data = {
      item_name: itemData.item_name,
      item_hsn: itemData.item_hsn,
      item_unit: itemData.item_unit,
      item_type: itemData.item_type,
      item_taxable: itemData.item_taxable,
      item_gst: itemData.item_gst,
      item_igst: itemData.item_igst,
      item_sale_price: itemData.item_sale_price,
      item_purchase_price: itemData.item_purchase_price,
      item_opening_stock: itemData.item_opening_stock,
      
      item_at_price: itemData.item_at_price,
      item_date: itemData.item_date,
      item_min_stock_maintain: itemData.item_min_stock_maintain,
      company: creditNoteData.company,
      staff: creditNoteData.staff,
      user_id: userID,
    };
  
    console.log('Sending data to API:', data);
  
    try {
      // const response = await fetch('http://localhost:8000/api/add-item/', {
      const response = await fetch(`${config.base_url}/api/add-item/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const error = await response.json();
        console.log('Error response:', error);  // Log the error response
        alert('Error: ' + (error.message || 'Unknown error occurred.'));
      } else {
        alert('Item added successfully!');
        setIsItemModalOpen(false); // Close the modal on success
        setItemData({
          item_name: data.item_name, 
          item_hsn: data.item_hsn,
          item_unit: data.item_unit,
          item_type: data.item_type,
          item_taxable: data.item_taxable,
          item_gst: data.item_gst,
          item_igst: data.item_igst,
          item_sale_price: data.item_sale_price,
          item_purchase_price: data.item_purchase_price,
          item_opening_stock: data.item_opening_stock,
          item_at_price: data.item_at_price,
          item_date: data.item_date,
          item_min_stock_maintain: data.item_min_stock_maintain
        });
        setNewItems((prevItems) => [
          ...prevItems,
          { item_id: data.item_id, item_name: data.item_name, item_hsn: data.item_hsn, item_sale_price: data.item_sale_price,item_igst: data.item_igst,  // Include IGST
            item_gst: data.item_gst,  },  // Assuming the backend returns the item_id
        ]);
      }
    } catch (error) {
      alert('Error: ' + error.message);
      console.log('Error:', error);
    }
  };
  

  // Styles object for AddCreditNote component
  const styles = {
    outerContainer: {
      backgroundColor: "#8290c7",
      minHeight: "100vh",
    },
    container: {
      backgroundColor: "#8290c7",
    },
    mainPanel: {
      background: "#fff",
    },
    card: {
      boxShadow: "none",
      width: '90%', // Default width for mobile
      margin: '0 auto', // Center the card
    },
    cardBody: {
      backgroundColor: "white",
      padding: "30px",
    },
    heading: {
      backgroundColor: '#3d4465', // Background color for the heading
      color: 'white', // Text color for the heading (ensures text is white)
      padding: '15px', // Padding inside the heading
      margin: '0', // Remove default margin
      textAlign: 'left', // Align the heading text to the left
      width: '100%', // Full width
      zIndex: '1',
      marginBottom: '40px', // Space below the heading
      display: 'flex', // To allow both heading and button in the same row
      justifyContent: 'space-between', // Pushes the button to the right
      alignItems: 'center', // Vertically center the items
      fontWeight: 'bold', // Ensures the heading text is bold
    },
    headingText: {
      color: 'white', // Explicitly set text color to white
      margin: 0, // Remove margin
    },
    backButton: {
      backgroundColor: 'white', // White background for the button
      color: 'black', // Black text color for the button
      border: 'none', // Remove the border
      padding: '8px 16px', // Button padding
      cursor: 'pointer', // Pointer cursor on hover
      borderRadius: '4px', // Rounded corners for the button
      fontSize: '14px', // Font size for the button text
    },
    formSection: {
      marginTop: "40px",
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    formGroup: {
      flex: "3",
      marginBottom: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "2px",
      display: "block",
      textAlign: "left",
      position: "relative",
      zIndex: "3",
    },
    modallabel: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "2px",
      display: "block",
      textAlign: "left",
      position: "relative",
      zIndex: "3",
      marginTop: '1px',
    },
    input: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "45px",
      borderRadius: "5px",
      outline: "none",
    },
    select: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "55px",
      borderRadius: "5px",
      outline: "none",
    },
    centerButton: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    button: {
      backgroundColor: "rgb(218, 164, 48)",
      color: "white",
      padding: "10px 20px",
      borderRadius: "20px",
    },
    partySection: {
      display: "flex",
      alignItems: "center", 
      marginTop: "20px",
      marginBottom: "-5%",
    },
    partyLabel: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#333",
      marginRight: "10px", 
    },
    switchContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: '3%',
    },
    toggleSwitch: {
      width: "50px",
      height: "25px",
      position: "relative",
    },
    toggleInput: {
      opacity: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      cursor: "pointer",
    },
    toggleSlider: {
      position: "absolute",
      cursor: "pointer",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "#ccc",
      transition: "0.4s",
      borderRadius: "34px",
    },
    toggleSliderChecked: {
      backgroundColor: "#4c87af",
    },
    toggleSliderBefore: {
      content: '""',
      position: "absolute",
      height: "15px",
      width: "15px",
      borderRadius: "50%",
      left: "4px",
      bottom: "4px",
      backgroundColor: "white",
      transition: "0.4s",
    },
    toggleSliderBeforeChecked: {
      transform: "translateX(24px)",
    },
    partyFieldsRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    partyField: {
      flex: "3",
      marginBottom: "2px",
    },
    inputContainer: {
      display: "flex",
      alignItems: "center", // Vertically center the input and button
      gap: "8px", // Space between input and button
    },
    input: {
      height: "40px",
      fontSize: "14px",
      padding: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxSizing: "border-box",
      marginTop: "45px",
      borderRadius: "5px",
      outline: "none",
    },
   
    plusButton: {
      padding: "6px 12px", // Button padding
      backgroundColor: "#007bff", // Button background color
      color: "#fff", // Button text color
      border: "none", // No border for the button
      borderRadius: "4px", // Rounded corners for the button
      cursor: "pointer", // Pointer cursor on hover
      fontSize: "18px", // Font size of the plus sign
      display: "inline-flex", // Align button horizontally with the input
      alignItems: "center", // Vertically center the plus sign inside button
      justifyContent: "center", // Ensure plus sign is centered
      marginTop: "5px",
    },
    table: {
      width: "100%",
      marginTop: "30px",
      borderCollapse: "collapse",
    },
    th: {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "center",
      backgroundColor: "#3d4465",
      color: "white", 
    },
    tableinput: {
      width: "90%", // Input width is 90% of the cell width
      padding: "6px", // Add padding inside the input field
      margin: "0 auto", // Center the input within the cell
      border: "1px solid #ddd", // Border around the input
      borderRadius: "4px", // Rounded corners for the input
      boxSizing: "border-box", // Ensures padding is included within the width/height
      textAlign: "center", // Center-align the input text
      fontSize: "14px", // Set font size for inputs
    },
    td: {
      border: "1px solid #ddd", // Border for table cells
      padding: "8px", // Add padding inside cells
      textAlign: "center", // Center-align the cell content
      verticalAlign: "middle", // Vertically center-align the content
      boxSizing: "border-box", // Ensures padding does not affect the total width
    },
    addButton: {
      width: '40px',           // Set the width and height to make it square
      height: '40px',          // Equal to width for square shape
      borderRadius: '5px',     // Optional: Rounded corners, you can remove it if you want sharp corners
      backgroundColor: '#3d4465', // Your choice of background color
      color: 'white',          // Text color (for the plus sign)
      fontSize: '20px',        // Font size for the plus sign
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',      // Change the cursor to pointer on hover
      border: 'none',          // Remove border for a cleaner look
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
      marginTop: '2%',
    },
    plusSign: {
      fontSize: '24px',         // Ensure the plus sign fits well within the square
    },
    removeButton: {
      color: 'red',
      cursor: 'pointer',
      fontSize: '20px',
      textAlign: 'center',
      padding: '5px 10px',
    },
    
    rectangularButton: {
      padding: '10px 20px', // Padding inside each button
      border: 'none', // Remove default border
      borderRadius: '4px', // Optional: rounded corners for the buttons
      color: '#fff', // Text color for the buttons
      cursor: 'pointer', // Change cursor to pointer on hover
      fontSize: '16px', // Font size for better readability
      marginTop: '2%',
    },
    
    button: {
      // Your other button styles
      fontWeight: 'bold',
      padding: '10px 20px',
      marginRight: '10px',
    },
    plusButton: {
      padding: '6px 12px', // Button padding
      backgroundColor: '#3d4465', // Button background color
      color: '#fff', // Button text color
      border: 'none', // No border for the button
      borderRadius: '4px', // Rounded corners for the button
      cursor: 'pointer', // Pointer cursor on hover
      fontSize: '18px', // Font size of the plus sign
      display: 'inline-flex', // Align button horizontally with the input
      alignItems: 'center', // Vertically center the plus sign inside button
      justifyContent: 'center', // Ensure plus sign is centered
      
    },
    
  };
 
  const cardStyles = {
    backgroundColor: '#3d4465',  // Background color for the card (same as table heading)
    padding: '20px',
    width: cardWidth, 
    marginTop: '2%',
    borderRadius: '8px',  // Optional: Rounded corners for the card
    
  };
  
  const labelStyles = {
    color: 'white',  // White color for the label text
    textAlign: 'left',  // Align the label text to the left
    fontSize: '14px',  // Font size for the label text
    marginLeft: '2%',
    
  };
  
  const inputStyles = {
    textAlign: 'right',  // Align the input text to the right
    width: '70%',  // Input takes 70% of the width
    padding: '8px',  // Adjust padding for input fields
    borderRadius: '4px',  // Rounded corners for input fields
    border: '1px solid #ccc',  // Subtle border color
    fontSize: '14px',  // Font size for the input text
    marginLeft: '40%',
  };
  
  const formRowStyles = {
    display: 'flex',  // Use flexbox for horizontal alignment
    alignItems: 'center',  // Center the items vertically
    marginBottom: '10px',  // Space between rows
    
  };
  
  const formGroupStyles = {
    display: 'flex',  // Align label and input on the same row
    width: '100%',  // Full width for form group
    justifyContent: 'flex-start',  // Align items to the left
    marginBottom: '15px',  // Space between form fields
  };
  
  const textAreaStyles = {
    textAlign: 'right',  // Align the textarea text to the right
    width: '100%',  // Ensure textarea takes full width
    padding: '8px',  // Padding inside the textarea
    borderRadius: '4px',  // Rounded corners for textarea
    border: '1px solid #ccc',  // Subtle border
    fontSize: '14px',  // Font size for the text inside the textarea
  };
  const handleBackClick = () => {
    // Navigate to the credit_note page when the back button is clicked
    navigate('/credit_note');
  };
  
  useEffect(() => {
    const handleResize = () => {
      // Adjust panel width based on screen size
      setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");

      // Adjust card width based on screen size (desktop vs mobile)
      if (window.innerWidth > 768) {
        setCardWidth("50%");  // For desktop
      } else {
        setCardWidth("105%");  // For mobile
      }
    };

    // Initial resize handling
    handleResize();

    // Add event listener to handle resizing
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);  


  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", width: panelWidth }}
        >
          <div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>
          <div className="card radius-15" >
          <div className="card-body" >
                
                  {/* <h3 style={styles.heading}>
                    <b>Add Credit Note</b>
                  </h3> */}
                       <div style={styles.heading}>
          {/* Heading with explicitly white text color */}
          <h3 style={styles.headingText}>
            Add Credit Note
          </h3>
          <button
            style={styles.backButton}
            onClick={handleBackClick}
          >
            {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
            <i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: '8px' }}></i> 
            Back
          </button>
        </div>

                  {/* Party Section */}
                  <div style={styles.partySection}>
                    <span style={styles.partyLabel}>Party</span>
                    <div style={styles.switchContainer}>
                      <label style={styles.toggleSwitch}>
                        <input
                          type="checkbox"
                          style={styles.toggleInput}
                          checked={isPartySelected}
                          onChange={handlePartyToggle}
                        />
                        <span
                          style={{
                            ...styles.toggleSlider,
                            ...(isPartySelected && styles.toggleSliderChecked),
                          }}
                        >
                          <span
                            style={{
                              ...styles.toggleSliderBefore,
                              ...(isPartySelected && styles.toggleSliderBeforeChecked),
                            }}
                          ></span>
                        </span>
                      </label>
                    </div>
                  </div><br></br>

                  {/* Form Section */}
                  <form onSubmit={handleSubmit}>
                  <div className="my-5" style={styles.formSection}>
                    
                      {isPartySelected && (
                        <div className="form-row" >
                          <div className="form-group col-12 col-md-4" >

                            {/* <label htmlFor="partyName" style={styles.label}> */}
                              <span style={{ fontWeight: '500' }}> Party Name</span>
                            {/* </label> */}

                            <div style={styles.inputContainer}>
                            <select
                                  id="partyName"
                                  name="partyName"
                                  className="form-control"
                                  // style={styles.input}
                                  onChange={handlePartyChange}
                                  // value={selectedParty}
                                  value={creditNoteData.party}
                                  required
                                >
                                  <option value="">Select Party Name</option>
                                  {parties.map((party) => (
                                    <option key={party.id} value={party.id}>
                                      {party.party_name}
                                    </option>
                                  ))}
                            </select>
                            
                            {/* + Button */}
                            <button
                              type="button"
                              onClick={handleAddPartyName}
                              style={styles.plusButton}
                            >
                              +
                            </button>
                          </div>
                            
                          </div>
                          <div className="form-group col-12 col-md-4" >

                            {/* <label htmlFor="partyPhone" style={styles.label}> */}
                            <span style={{ fontWeight: '500' }}>Party Phone</span>
 
                            {/* </label> */}
                            <input
                              type="text"
                              id="partyPhone"
                              name="partyPhone"
                              className="form-control"
                              // style={styles.input}
                              value={partyPhone}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="form-group col-12 col-md-4" >
                          <span style={{ fontWeight: '500' }}> Billing Address</span>
   

                            {/* <label htmlFor="billingAddress" style={styles.label}>
                              Billing Address
                            </label> */}
                            <input
                              type="text"
                              id="billingAddress"
                              name="billingAddress"
                              className="form-control"
                              // style={styles.input}
                              value={billingAddress}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          {creditNoteData.party && (
  <div className="form-group col-12" style={{ display: 'flex', alignItems: 'center' ,marginBottom:'20px'}}>
    <span style={{ fontWeight: '400', marginRight: '10px' }}>Available Balance:</span>
    <div style={{ fontWeight: '500', color: 'red' }}>{openingBalance}</div>  {/* Show the opening balance of the selected party */}
  </div>
)}

                        </div>
                      )}
                 

                      {/* Input Fields for Return No, Invoice No, Invoice Date */}
                      <div className="form-row">
                        <div className="form-group col-12 col-md-4" >

                          {/* <label htmlFor="returnNumber" style={styles.label}> */}
                            <span style={{ fontWeight: '500' }}>Return No</span>
                          {/* </label> */}
                          <input
                            type="text"
                            id="returnNumber"
                            name="returnNumber"
                            className="form-control"
                            // style={styles.input}
                            value={returnNumber}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="form-group col-12 col-md-4">

                          {/* <label htmlFor="invoiceNumber" style={styles.label}> */}
                             <span style={{ fontWeight: '500' }}>Invoice No</span>
                          {/* </label> */}
                          <select
                                id="invoiceNumber"
                                name="invoiceNumber"
                                className="form-control"
                                // style={styles.input}
                                value={creditNoteData.invoiceNumber}
                                onChange={handleInvoiceChange} 
                                required
                              >
                               <option value="">Select Invoice No</option>
                                  {invoiceNumbers.map((invoice) => (
                                    <option key={invoice.invoice_no} value={invoice.invoice_no}>
                                      {invoice.invoice_no}
                                    </option>
                                  ))}
                              </select>

                        </div>

                        <div className="form-group col-12 col-md-4">

                          {/* <label htmlFor="invoiceDate" style={styles.label}> */}
                           <span style={{  fontWeight: '500' }}> Invoice Date </span>
                          {/* </label> */}
                          <input
                            type="date"
                            id="invoiceDate"
                            name="invoiceDate"
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.invoiceDate}
                            readOnly // Make it read-only as it's dynamically set
                          />
                        </div>
                      </div>

                      {/* Date and State of Supply */}
                      <div className="form-row" >
                        <div className="form-group col-12 col-md-6">

                          {/* <label htmlFor="date" style={styles.label}> */}
                            <span style={{  fontWeight: '500' }}>  Date </span>
                          {/* </label> */}
                          <input
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.date}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="form-group col-12 col-md-6">

                          {/* <label htmlFor="stateOfSupply" style={styles.label}> */}
                          <span style={{ fontWeight: '500' }}>State of Supply</span>

                          {/* </label> */}
                          <select
                            id="stateOfSupply"
                            name="stateOfSupply"
                            className="form-control"
                            // style={styles.input}
                            value={creditNoteData.stateOfSupply}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="select">select</option>
                            <option value="State">State</option>
                            <option value="Other State">Other State</option>
                          </select>
                        </div>
                      </div>

                      
                    
                  {/* </div> */}

                  {/* Table for Product Data */}
                  <div className="table-responsive " style={{ marginTop: "0px" }}>
                                        <table style={styles.table}>
                                                  <thead>
                                                    <tr>
                                                      <th style={styles.th}>#</th>
                                                      <th style={styles.th}>Product</th>
                                                      <th style={styles.th}>HSN/SAC</th>
                                                      <th style={styles.th}>Qty</th>
                                                      <th style={styles.th}>Price</th>
                                                      <th style={styles.th}>Tax</th>
                                                      <th style={styles.th}>Discount</th>
                                                      <th style={styles.th}>Total</th>
                                                      <th style={styles.th}>Remove</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {tableData.map((row, index) => (
                                                      <tr key={index}>
                                                        <td style={styles.td}>{row.sn}</td>
                                                        <td style={{ ...styles.td, display: 'flex', alignItems: 'center' }}>
                                          <select
                                            value={row.product}
                                            onChange={(e) => handleTableChange(e, index, "product")}
                                          >
                                            <option value="">Select Product</option>
                                            {/* Show unique items */}
                                            {uniqueItems.map((item) => (
                                              <option key={item.item_id} value={item.item_name}>
                                                {item.item_name}
                                              </option>
                                            ))}
                                          </select>
                                          <button
    type="button"
    onClick={handleAddItem}
    style={{ ...styles.plusButton, marginLeft: '10px',marginTop:"0px"}} // Add margin to create space between the select and button
  >
    +
  </button>

                                        </td>


                                      <td style={styles.td}>
                                        <input
                                          type="text"
                                          value={row.hsnSac}
                                          onChange={(e) => handleTableChange(e, index, "hsnSac")} style={styles.tableinput}
                                        />
                                      </td>
                                      <td style={styles.td}>
                                        <input
                                          type="number"
                                          value={row.qty}
                                          onChange={(e) => handleTableChange(e, index, "qty")} style={styles.tableinput}
                                        />
                                      </td>
                                      <td style={styles.td}>
                                        <input
                                          type="number"
                                          value={row.price}
                                          onChange={(e) => handleTableChange(e, index, "price")} style={styles.tableinput}
                                        />
                                      </td>
                                      {/* <td style={styles.td}>
                                        <input
                                          type="text"
                                          value={row.tax}
                                          onChange={(e) => handleTableChange(e, index, "tax")} style={styles.tableinput}
                                        />
                                        <input type="hidden" value={row.igst}  onChange={(e) => handleTableChange(e, index, "igst")} style={styles.tableinput} />
                                      </td> */}

                      <td style={styles.td}>
                                {/* Conditionally show CGST/SGST or IGST based on stateOfSupply */}
                                {creditNoteData.stateOfSupply === "State" ? (
                                  <>
                                    <input
                                      type="text"
                                      value={row.gst}
                                      onChange={(e) => handleTableChange(e, index, "gst")}
                                      style={styles.tableinput}
                                    />
                                    <input
                                      type="hidden"
                                      value={row.invoice_sgst}
                                      onChange={(e) => handleTableChange(e, index, "invoice_sgst")}
                                      style={styles.tableinput}
                                    />
                                    <input
                                      type="hidden"
                                      value={row.invoice_cgst}
                                      onChange={(e) => handleTableChange(e, index, "invoice_cgst")}
                                      style={styles.tableinput}
                                    />
                                    
                                  </>
                                ) : (
                                  <>
                                  <input
                                    type="text"
                                    value={row.igst}
                                    onChange={(e) => handleTableChange(e, index, "igst")}
                                    style={styles.tableinput}
                                  />
                                  <input
                                      type="hidden"
                                      value={row.invoice_igst}
                                      onChange={(e) => handleTableChange(e, index, "invoice_igst")}
                                      style={styles.tableinput}
                                    />
                                  </>
                                )}
                              </td>


                                      <td style={styles.td}>
                                        <input
                                          type="number"
                                          value={row.discount}
                                          onChange={(e) => handleTableChange(e, index, "discount")} style={styles.tableinput}
                                        />
                                        <input type="hidden" value={row.adjust}  onChange={(e) => handleTableChange(e, index, "adjust")} style={styles.tableinput} />
                                      </td>
                                      <td style={styles.td}><input
                                          type="number"
                                          value={row.total}
                                          onChange={(e) => handleTableChange(e, index, "total")} style={styles.tableinput}
                                        />
                                        <input type="hidden" value={row.taxamount}  onChange={(e) => handleTableChange(e, index, "taxamount")} style={styles.tableinput} />
                                        {/* <input type="hidden" value={row.grandtotal}  onChange={(e) => handleTableChange(e, index, "grandtotal")} style={styles.tableinput} /> */}
                                        </td>
                                      <td style={styles.td}>
                                        <span
                                          style={styles.removeButton}
                                          onClick={() => removeRow(index)}
                                        >
                                          X
                                        </span>
                                      </td>
                                      
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              </div>


                                        <button  type="button" onClick={addRow} style={styles.addButton}>
                                          <span style={styles.plusSign}>+</span>
                                        </button>

                                        <div style={cardStyles}>
                        

                                          {/* First Row */}
                                          <div className="form-row" style={formRowStyles}>
                                            <div className="form-group" style={formGroupStyles}>
                                              <label htmlFor="subtotal" style={labelStyles}>Subtotal:</label>
                                              <input
                                                type="number"
                                                id="subtotal"
                                                name="subtotal"
                                                className="form-control"
                                                style={{ ...inputStyles, textAlign: 'left' }} 
                                                value={calculateSubtotal()} // Call function to calculate subtotal
                                                onChange={handleInputChange}
                                                readOnly // Optionally, make it read-only if it's auto-calculated
                                              />
                                            </div>
                                          </div>


                                          {/* Second Row */}
                                          
                                          {creditNoteData.stateOfSupply === "Other State" && (
                                              <div className="form-row" style={formRowStyles}>
                                                <div className="form-group" style={formGroupStyles}>
                                                  <label htmlFor="totalIGST" style={labelStyles}>Total IGST:</label>
                                                  <input
                                                    type="number"
                                                    id="totalIGST"
                                                    name="totalIGST"
                                                    className="form-control"
                                                    style={{ ...inputStyles, textAlign: 'left' }}
                                                    value={calculateIGST()} 
                                                    onChange={handleInputChange}// Call the function to get the total IGST
                                                    readOnly
                                                  />
                                                </div>
                                              </div>
                                            )}

                                                      {creditNoteData.stateOfSupply === "State" && (
                                              <>
                                                <div className="form-row" style={formRowStyles}>
                                                  <div className="form-group" style={formGroupStyles}>
                                                    <label htmlFor="cgst" style={labelStyles}>CGST:</label>
                                                    <input
                                                      type="number"
                                                      id="cgst"
                                                      name="cgst"
                                                      className="form-control"
                                                      style={{ ...inputStyles, textAlign: 'left' }}
                                                    value={calculateCGST()}
                                                      onChange={handleInputChange}
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-row" style={formRowStyles}>
                                                  <div className="form-group" style={formGroupStyles}>
                                                    <label htmlFor="sgst" style={labelStyles}>SGST:</label>
                                                    <input
                                                      type="number"
                                                      id="sgst"
                                                      name="sgst"
                                                      className="form-control"
                                                      style={{ ...inputStyles, textAlign: 'left' }}
                                                        value={calculateSGST()}
                                                      onChange={handleInputChange}
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )}



                                          {/* Third Row */}
                                        

                                          {/* Fourth Row */}
                                          <div className="form-row" style={formRowStyles}>
                                            <div className="form-group" style={formGroupStyles}>
                                              <label htmlFor="taxAmount" style={labelStyles}>Tax Amount:</label>
                                              <input
                                                type="number"
                                                id="taxAmount"
                                                name="taxAmount"
                                                className="form-control"
                                                style={{ ...inputStyles, textAlign: 'left' }}
                                                value={
                                                  creditNoteData.stateOfSupply === "Other State" 
                                                    ? calculateIGST()  // IGST if "Other State"
                                                    : creditNoteData.stateOfSupply === "State" 
                                                    ? (calculateCGST() + calculateSGST())  // Sum of CGST + SGST if "State"
                                                    : 0 // Default to 0 if no tax applicable
                                                }
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                          </div>


                                          {/* Fifth Row */}
                                          <div className="form-row" style={formRowStyles}>
                                            <div className="form-group" style={formGroupStyles}>
                                              <label htmlFor="adjustment" style={labelStyles}>Adjustment:</label>
                                              <input
                                                type="number"
                                                id="adjustment"
                                                name="adjustment"
                                                className="form-control"
                                                style={{ ...inputStyles, textAlign: 'left' }}
                                                value={creditNoteData.adjustment || 0}  // Ensure the adjustment value is correctly bound
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                          </div>


                                          {/* Sixth Row */}
                                          <div className="form-row" style={formRowStyles}>
                                            <div className="form-group" style={formGroupStyles}>
                                              <label htmlFor="grandTotal" style={labelStyles}>Grand Total:</label>
                                              <input
                                                type="number"
                                                id="grandTotal"
                                                name="grandTotal"
                                                className="form-control"
                                                style={{ ...inputStyles, textAlign: 'left' }}
                                                value={calculateGrandTotal()} // Use the function to calculate grand total
                                                onChange={handleInputChange} // If you want to allow manual edit
                                                readOnly // Optionally make it read-only since it's auto-calculated
                                              />
                                            </div>
                                          </div>



                                          {/* Textarea Row */}
                                          {/* <div className="form-row" style={formRowStyles}>
                                            <div className="form-group" style={formGroupStyles}>
                                              <label htmlFor="description" style={labelStyles}>Description:</label>
                                              <input
                                                type="text"
                                                id="description"
                                                name="description"
                                                className="form-control"
                                                style={inputStyles}
                                                value={creditNoteData.grandTotal}
                                                onChange={handleInputChange}
                                              />
                                            
                                            </div>
                                          </div> */}
                                        </div>

                                        <div style={styles.rectangularButtonContainer}>
                        <button   type="submit"
                          style={{ 
                            ...styles.button, 
                            backgroundColor: styles.th.backgroundColor, 
                            ...styles.rectangularButton  ,width: '150px',
                          }}
                        >
                          Save
                        </button>
                        <button
    onClick={() => window.location.reload()} // Add this to reload the page
    style={{
      ...styles.button,
      backgroundColor: styles.th.backgroundColor,
      ...styles.rectangularButton, width: '150px',
    }}
  >
    Save & Next
  </button>

                      </div>
                      
                      </div>
                      </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
  isOpen={isModalOpen}
  onRequestClose={() => setIsModalOpen(false)}
  style={{
    content: {
      padding: '20px',
      marginLeft: '15%',
      borderRadius: '8px',
      backgroundColor: 'rgba(61, 68, 101)', // Modal content background color
      color: 'white', // Text color in modal
      zIndex: '9999', // Ensure it stays on top of other content
      marginTop: '10vh',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background overlay color to darken the page behind the modal
      zIndex: '999', // Ensure the overlay stays below the modal
    },
  }}
> <style>
    {`
      /* Default margin for larger screens */
      @media (max-width: 768px) {
        .ReactModal__Content {
          margin-left: 1% !important; /* Reduced left margin for mobile */
        }
      }

      /* Style for mobile views */
      @media (max-width: 767px) {
        .modal-content {
          padding: 10px;
        }

        /* Adjust form elements */
        .form-control {
          width: 100% !important;
        }

        /* Adjust radio buttons layout */
        .radio-group {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .radio-group label {
          flex: 1 0 45%; /* Make the radio buttons responsive */
        }

        .modal-header h3 {
          font-size: 18px;
        }

        .modal-footer {
          text-align: center;
        }
        .save-button, .previous-button {
          width: 100% !important; /* Full width buttons on mobile */
          padding: 12px 0 !important; /* Adjust button padding */
          margin-bottom: 10px; /* Add some space between buttons */
        }
      }
    `}
  </style>

  <div className="modal-header" style={{ color: 'white' }}>
    <h3 className="m-3" style={{ color: 'white' }}>ADD PARTY</h3>
    <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} />
  </div>

  <div className="bg-light bs p-4 rounded mb-5">
    <form>
      <div className="row" style={{ marginLeft: '-1vh' }}>
        <div className="col-md-4">
          <span style={{ color: 'black' }}>Party Name</span>
          <input
            type="text"
            className="form-control border-secondary"
            id="partyname"
            name="partyname"
            value={partyModalData.partyname}
            onChange={handleModalChange}
            style={{ color: 'black' }}
            required
          />
        </div>
        {/* <div className="col-md-4">
          <span style={{ color: 'black' }}>GSTIN</span>
          <input
            type="text"
            className="form-control border-secondary"
            id="gstin"
            name="gstin"
            value={partyModalData.gstin}
            onChange={handleModalChange}
            placeholder="29APPCK7465F1Z1"
            style={{ color: 'black' }}
            required
          />
        </div> */}
        <div className="col-md-4">
          <span style={{ color: 'black' }}>GSTIN</span>
          <input
            type="text"
            className="form-control border-secondary"
            id="gstin"
            name="gstin"
            value={partyModalData.gstin}
            onChange={handleModalChange}
            placeholder="29APPCK7465F1Z1"
            style={{ color: 'black' }}
            required
          />
           {gstinError && <small style={{ color: 'red' }}>{gstinError}</small>}
        </div>
        <div className="col-md-4">
          <span style={{ color: 'black' }}>Mobile</span>
          <input
            type="number"
            className="form-control border-secondary"
            id="partyphno"
            name="mobile"
            value={partyModalData.mobile}
            onChange={handleModalChange}
            style={{ color: 'black' }}
            required
          />
             {mobileError && <small style={{ color: 'red' }}>{mobileError}</small>}
        </div>
      
      </div>

      <div style={{ marginTop: '20px' }} />
      <div style={{ backgroundColor: 'rgba(130, 144, 199)', padding: '10px', borderRadius: '8px' }}>
        <div className="row pb-0 mb-0">
          <div className="col text-center">
            <button
              type="button"
              style={{
                color: showGST ? 'black' : 'white', // Change text color based on selection
                fontWeight: 'bold',
                textDecoration: showGST ? 'underline' : 'none', // Underline for selected button
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => setShowGST(true)}
            >
              GST & ADDRESS
            </button>
          </div>
          <div className="col text-center">
            <button
              type="button"
              style={{
                color: !showGST ? 'black' : 'white', // Change text color based on selection
                fontWeight: 'bold',
                textDecoration: !showGST ? 'underline' : 'none', // Underline for selected button
                border: 'none',
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
              onClick={() => setShowGST(false)}
            >
              CREDIT & BALANCE
            </button>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }} />

      {showGST ? (
        <div id="gsttemp">
          <div className="row">
            {/* GST Type */}
            <div className="col-md-6">
              <span style={{ color: 'black' }}>GST Type</span>
              <select
                className="form-control border-secondary"
                id="gstType"
                name="gstType"
                value={partyModalData.gstType}
                onChange={handleModalChange}
                style={{ color: 'black', backgroundColor: 'white' }}
              >
                <option value="" hidden>Select</option>
                <option value="Registered Party">Registered Party</option>
                <option value="Unregistered or Consumer">Unregistered or Consumer</option>
                <option value="Registered Business or Combosision">Registered Business or Combosision</option>
              </select>
            </div>

            {/* Supply State */}
            <div className="col-md-6">
                  <span style={{ color: 'black' }}>Supply State</span>
                  <select
                    className="form-control border-secondary"
                    name="supplyState"
                    id="supplyState"
                    value={partyModalData.supplyState}
                    onChange={handleModalChange}
                    style={{ color: 'black', backgroundColor: 'white' }}
                  >
                    <option value="" hidden>Select</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Puducherry">Puducherry</option>
                  </select>
                </div>
          </div>

          <div className="row">
            {/* Email Field */}
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Email</span>
              <input
                type="email"
                className="form-control border-secondary"
                id="email"
                name="email"
                value={partyModalData.email}
                onChange={handleModalChange}
                style={{ color: 'black', backgroundColor: 'white' }}
                required
              />
            </div>

            {/* Billing Address (Right side) */}
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Billing Address</span>
              <textarea
                className="form-control border-secondary"
                id="billingAddress"
                name="billingAddress"
                value={partyModalData.billingAddress}
                onChange={handleModalChange}
                rows="4"
                style={{ color: 'black', backgroundColor: 'white' }}
              ></textarea>
            </div>
          </div>
        </div>
      ) : (
        <div id="credittemp" style={{ backgroundColor: 'rgba(144, 211, 117, 0.098)' }}>
          <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column align-items-start">
               
            {/* <div className="col-md-6 d-flex " style={{ whiteSpace: 'nowrap', justifyContent: 'flex-start' }}> */}
            <div className="d-flex flex-wrap align-items-center" style={{ whiteSpace: 'nowrap', justifyContent: 'flex-start' }}>
                  <span style={{ color: 'black', marginRight: '10px', marginLeft: '1px', textAlign: 'left' }}>Opening Balance</span> -
                  <div className="d-flex align-items-center ms-2 me-4" style={{ marginRight: '10px' }}>
                    <input
                      type="radio"
                      id="taxable"
                      name="item_taxable"
                      // checked={itemData.item_taxable === 'Taxable'}
                      // onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Taxable' } })}
                    />
                    <span style={{ color: 'black', marginLeft: '8px' }}>To Pay</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="nonTaxable"
                      name="item_taxable"
                      // checked={itemData.item_taxable === 'Non-Taxable'}
                      // onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Non-Taxable' } })}
                    />
                    <span style={{ color: 'black', marginLeft: '8px' }}>To Receive</span>
                  </div>
              </div>




              
              <input
                type="number"
                className="form-control border-secondary"
                id="openbalance"
                name="openBalance"
                value={partyModalData.openBalance}
                onChange={handleModalChange}
                style={{ color: 'black', backgroundColor: 'white' }}
              />
            </div>
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Credit Limit</span>
              <input
                type="number"
                className="form-control border-secondary"
                id="creditLimit"
                name="creditLimit"
                value={partyModalData.creditLimit}
                onChange={handleModalChange}
                style={{ color: 'black', backgroundColor: 'white' }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Date</span>
              <input
                type="date"
                className="form-control border-secondary"
                id="partydate"
                name="date"
                value={partyModalData.date}
                onChange={handleModalChange}
                style={{ color: 'black', backgroundColor: 'white' }}
              />
            </div>
          </div>
        </div>
      )}

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {showGST ? (
          <button
            type="button"
            style={{
              backgroundColor: 'rgb(61, 68, 101)',
              color: 'white',
              width: '150px',
              padding: '10px 20px',
              borderRadius: '5px',
            }}
            onClick={() => setShowGST(false)}
          >
            NEXT
          </button>
        ) : (
          <>
            <button
              type="button"
              className="previous-button"
              style={{
                backgroundColor: 'rgb(61, 68, 101)',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                width: '150px',
                marginRight: '10px',
              }}
              onClick={() => setShowGST(true)}
            >
              PREVIOUS
            </button>
            <button
              type="button"
               className="save-button"
              style={{
                backgroundColor: 'rgb(61, 68, 101)',
                color: 'white',
                padding: '10px 20px',
                width: '150px',
                borderRadius: '5px',
              }}
              onClick={handleSubmitModal}
            >
              SAVE
            </button>
          </>
          
        )}
      </div>
    </form>
  </div>
</Modal>



<Modal
  isOpen={isItemModalOpen}
  onRequestClose={() => setIsItemModalOpen(false)}
  style={{
    content: {
      padding: '20px',
      marginLeft: '15%',
      borderRadius: '8px',
      backgroundColor: 'rgba(61, 68, 101)', // Modal content background color
      color: 'white', // Text color in modal
      zIndex: '9999', // Ensure it stays on top of other content
      marginTop: '10vh',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background overlay color to darken the page behind the modal
      zIndex: '999', // Ensure the overlay stays below the modal
    },
  }}
>
  <style>
    {`
      /* Default behavior for desktop view */
      @media (min-width: 768px) {
        .modal-content {
          max-width: 80vw;
          margin: auto;
        }
      }

      /* Full-width modal for mobile view */
      @media (max-width: 767px) {
        .modal-content {
          max-width: 100vw;
          margin: 0;
          padding: 10px;
        }
          .ReactModal__Content {
          margin-left: 1% !important; /* Reduce left margin for mobile view */
        }
           .save-button, .previous-button {
          width: 100% !important; /* Full width buttons on mobile */
          padding: 12px 0 !important; /* Adjust button padding */
          margin-bottom: 10px; /* Add some space between buttons */
        }
      }
    `}
  </style>
  <div className="modal-header" style={{ color: 'white' }}>
    <h3 className="m-3" style={{ color: 'white' }}>ADD ITEM</h3>
    <button type="button" className="btn-close" onClick={() => setIsItemModalOpen(false)} />
  </div>

  <div className="bg-light bs p-4 rounded mb-5">
    <form>
      <div className="row" style={{ marginLeft: '-1vh' }}>
        <div className="col-md-3">
          <span style={{ color: 'black' }}>Item Type</span>
          <select
            style={{ color: 'black' }}
            className="form-control border-secondary"
            name="item_type"
            value={itemData.item_type}
            onChange={handleItemModalChange}
            required
          >
            <option value="" hidden>Select Type</option>
            <option value="Goods">Products</option>
            <option value="Services">Services</option>
          </select>
        </div>
        <div className="col-md-3">
          <span style={{ color: 'black' }}>Item Name:</span>
          <input
            type="text"
            className="form-control border-secondary"
            name="item_name"
            value={itemData.item_name}
            onChange={handleItemModalChange}
            placeholder="Item Name"
            style={{ color: 'black' }}
            required
          />
        </div>
        <div className="col-md-3">
          <span style={{ color: 'black' }}>Item HSN</span>
          <input
            type="text"
            className="form-control border-secondary"
            name="item_hsn"
            value={itemData.item_hsn}
            onChange={handleItemModalChange}
            placeholder="ITEM HSN"
            style={{ color: 'black' }}
            required
          />
           {itemHsnError && <small style={{ color: 'red' }}>{itemHsnError}</small>}
        </div>
        <div className="col-md-3">
          <span style={{ color: 'black' }}>Unit</span>
          <div className="d-flex align-items-center">
            <select
              style={{ color: 'black' }}
              className="form-control border-secondary"
              name="item_unit"
              value={itemData.item_unit}
              onChange={handleItemModalChange}
              required
            >
              <option value="" hidden>Select Unit</option>
              {units.map((unit) => (
                <option key={unit.id} value={unit.unit_name}>
                  {unit.unit_name}
                </option>
              ))}
            </select>
            <button
              type="button"
              className="btn"
              name="unit-btn"
              onClick={() => {
                setIsAddUnitModalOpen(true);
                setIsItemModalOpen(false);
              }}
              style={{
                backgroundColor: 'rgb(60, 61, 101)',
                marginLeft: '10px', // space between select and button
                color: 'white',
              }}
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '20px' }} />
      <div style={{ backgroundColor: 'rgba(130, 144, 199)', padding: '10px', borderRadius: '8px' }}>
        <div className="row pb-0 mb-0">
          <div className="col text-center">
            <button
              type="button"
              style={{
                color: showGST ? 'black' : 'white', // Change text color based on selection
                fontWeight: 'bold',
                textDecoration: showGST ? 'underline' : 'none', // Underline for selected button
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => setShowGST(true)}
            >
              PRICING
            </button>
          </div>
          <div className="col text-center">
            <button
              type="button"
              style={{
                color: !showGST ? 'black' : 'white', // Change text color based on selection
                fontWeight: 'bold',
                textDecoration: !showGST ? 'underline' : 'none', // Underline for selected button
                border: 'none',
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
              onClick={() => setShowGST(false)}
            >
              STOCKS
            </button>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }} />

      {showGST ? (
        <div className="row">
          <div className="col-md-12 d-flex align-items-center">
            <div className="d-flex align-items-center me-4">
              <input
                type="radio"
                id="taxable"
                name="item_taxable"
                checked={itemData.item_taxable === 'Taxable'}
                onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Taxable' } })}
              />
              <span style={{ color: 'black', marginLeft: '8px' }}>Taxable</span>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="radio"
                id="nonTaxable"
                name="item_taxable"
                checked={itemData.item_taxable === 'Non-Taxable'}
                onChange={() => handleItemModalChange({ target: { name: 'item_taxable', value: 'Non-Taxable' } })}
                style={{ marginLeft: '10px' }}
              />
              <span style={{ color: 'black', marginLeft: '8px' }}>NonTaxable</span>
            </div>
          </div>
          {itemData.item_taxable === 'Taxable' ? (
            <>
              <div className="col-md-6 mb-5">
                <span style={{ color: 'black' }}>IGST</span>
                <select
                  className="form-control border-secondary"
                  name="item_igst"
                  value={itemData.item_igst}
                  onChange={handleItemModalChange}
                >
                  <option value="IGST0[0%]">IGST0[0%]</option>
                  <option value="IGST3[3%]">IGST3[3%]</option>
                  <option value="IGST5[5%]">IGST5[5%]</option>
                  <option value="IGST12[12%]">IGST12[12%]</option>
                  <option value="IGST18[18%]">IGST18[18%]</option>
                  <option value="IGST28[28%]">IGST28[28%]</option>
                </select>
              </div>
              <div className="col-md-6 mb-5">
                <span style={{ color: 'black' }}>GST</span>
                <select
                  className="form-control border-secondary"
                  name="item_gst"
                  value={itemData.item_gst}
                  onChange={handleItemModalChange}
                >
                  <option value="GST0[0%]">GST0[0%]</option>
                  <option value="GST3[3%]">GST3[3%]</option>
                  <option value="GST5[5%]">GST5[5%]</option>
                  <option value="GST12[12%]">GST12[12%]</option>
                  <option value="GST18[18%]">GST18[18%]</option>
                  <option value="GST28[28%]">GST28[28%]</option>
                </select>
              </div>
              <div className="col-md-6">
                <span style={{ color: 'black' }}>Sales Price</span>
                <input
                  type="number"
                  className="form-control border-secondary"
                  name="item_sale_price"
                  value={itemData.item_sale_price}
                  onChange={handleItemModalChange}
                />
              </div>
              <div className="col-md-6">
                <span style={{ color: 'black' }}>Purchase Price</span>
                <input
                  type="number"
                  className="form-control border-secondary"
                  name="item_purchase_price"
                  value={itemData.item_purchase_price}
                  onChange={handleItemModalChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <span style={{ color: 'black' }}>Sales Price</span>
                <input
                  type="number"
                  className="form-control border-secondary"
                  name="item_sale_price"
                  value={itemData.item_sale_price}
                  onChange={handleItemModalChange}
                />
              </div>
              <div className="col-md-6">
                <span style={{ color: 'black' }}>Purchase Price</span>
                <input
                  type="number"
                  className="form-control border-secondary"
                  name="item_purchase_price"
                  value={itemData.item_purchase_price}
                  onChange={handleItemModalChange}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div id="credittemp">
          <div className="row">
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Opening Stock</span>
              <input
                type="number"
                className="form-control border-secondary"
                name="item_opening_stock"
                value={itemData.item_opening_stock}
                onChange={handleItemModalChange}
              />
            </div>
            <div className="col-md-6">
              <span style={{ color: 'black' }}>At Price</span>
              <input
                type="number"
                className="form-control border-secondary"
                name="item_at_price"
                value={itemData.item_at_price}
                onChange={handleItemModalChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Date</span>
              <input
                type="date"
                className="form-control border-secondary"
                name="item_date"
                value={itemData.item_date}
                onChange={handleItemModalChange}
              />
            </div>
            <div className="col-md-6">
              <span style={{ color: 'black' }}>Minimum Stocks to Maintain</span>
              <input
                type="text"
                className="form-control border-secondary"
                name="item_min_stock_maintain"
                value={itemData.item_min_stock_maintain}
                onChange={handleItemModalChange}
              />
            </div>
          </div>
        </div>
      )}

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {showGST ? (
          <button
            type="button"
            style={{
              backgroundColor: 'rgb(61, 68, 101)',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '5px',
            }}
            onClick={() => setShowGST(false)}
          >
            NEXT
          </button>
        ) : (
          <>
            <button
              type="button"
              className="previous-button"
              style={{
                backgroundColor: 'rgb(61, 68, 101)',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                marginRight: '10px',
                width: '150px',
              }}
              onClick={() => setShowGST(true)}
            >
              PREVIOUS
            </button>
            <button
              type="button"
               className="save-button"
              style={{
                backgroundColor: 'rgb(61, 68, 101)',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                width: '150px',
              }}
              onClick={handleItemSubmitModal}
            >
              SAVE
            </button>
          </>
        )}
      </div>
    </form>
  </div>
</Modal>

<Modal
  isOpen={isAddUnitModalOpen}
  onRequestClose={() => setIsAddUnitModalOpen(false)}
  style={{
    content: {
      maxWidth: '50%', // Increase the width for a larger modal
      width: '500px', // Default width
      height: '280px', // Reduced height of the modal
      padding: '20px', // Adjust padding for smaller modal
      margin: '10vh auto', // Center horizontally and vertically
      borderRadius: '8px',
      backgroundColor: 'white',
      color: 'black',
      zIndex: '9999',
      overflow: 'hidden', // Prevent overflow of the modal itself
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: '999',
    },
  }}
>
  <style>
    {`
      /* Default behavior for desktop view */
      @media (min-width: 768px) {
        .modal-content {
          max-width: 80vw;
          margin: auto;
        }
      }

      /* Full-width modal for mobile view */
      @media (max-width: 767px) {
        .modal-content {
          max-width: 100vw;
          margin: 0;
          padding: 10px;
        }
      }
    `}
  </style>

  <div className="modal-header" style={{ color: 'white' }}>
    <h3 className="m-2" style={{ color: 'rgb(60, 61, 101)' }}>ADD UNIT</h3>
    <button type="button" onClick={() => setIsAddUnitModalOpen(false)}>
      <i className="bi bi-x"></i>
    </button>
  </div>

  <div className="bs p-4 rounded" style={{ paddingBottom: '10px', overflow: 'auto' }}> 
    <form id="addUnitForm" onSubmit={handleUnitSubmit} style={{ maxHeight: 'calc(100% - 60px)', overflowY: 'auto' }}>
      <span style={{ color: 'black' }}>New Unit</span>
      <input
        type="text"
        className="form-control"
        name="newUnit"
        id="newUnit"
        placeholder="Enter new unit"
        value={newUnit}
        onChange={(e) => {
          setNewUnit(e.target.value);
          setErrorFeedback(''); // Clear error on change
        }}
        required
        style={{ marginBottom: '10px' }} // Add a small margin between input and button
      />
      {errorFeedback && (
        <div className="text-danger m-2" id="errorFeedback">
          {errorFeedback}
        </div>
      )}
      <div className="mt-3" style={{ marginTop: '10px' }}>
        <button
          type="submit"
          className="btn"
          style={{
            backgroundColor: "rgb(60, 61, 101)",
            color: "white",
            padding: "8px 16px",
            borderRadius: "4px",
          }}
        >
          Add
        </button>
      </div>
    </form>
  </div>
</Modal>


    </div>
  );
}

export default AddCreditNote;
