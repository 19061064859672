import React from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/parties.css"

const Party_Default = () => {
  return (
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
      <div className="main-panel p-4 px-3 py-2" style={{ background: "#a9a9a961" }}>
        <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>
      <div className="container-fluid">
        <div className="row">
         
          <div className="col-md-6">
            <div className="card">
              <img
                style={{ mixBlendMode: "multiply" }}
                src={`${process.env.PUBLIC_URL}/static/assets/images/debit.jpg`}
                width="400"
                className="img-fluid"
                alt="Debit Illustration"
              />
            </div>
          </div>

        
          <div className="col-md-6 mt-5">
            <div className="card">
              <div className="stat-widget-two card-body">
                <div className="stat-content">
                  <h3>
                    <img
                      src="static/assets/images/logo3.png"
                      alt="Billing Software Logo"
                    />
                    MultiUser Billing Software
                  </h3>
                  <br />
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Aut quo, sit quaerat consectetur doloribus molestias dicta.
                    Deserunt officia libero fugiat accusamus. Repellat aperiam,
                    ipsa ad sed eum dolorem corrupti ipsam.
                  </p>
                  <div className="d-flex justify-content-center align-items-center">
                  <a href="/add_party" className="btn btn-primary"style={{backgroundColor: '#007bff', color: 'white',              
                      borderColor: '#007bff',      
                      textDecoration: 'none',     
                      }}>
                    Add A Party
                   </a>
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
     </div>
     </div>
     </div>
    
    

  );
};

export default Party_Default;
