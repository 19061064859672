import React from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import config from "../../../functions/config";
import { useNavigate } from 'react-router-dom';

// Register required components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// import 'chart.js/auto'; // Automatically registers all required Chart.js components

export default function SalesInvoiceGraph() {
  const [chartData, setChartData] = useState({
    labels: [], // X-axis labels (Months)
    datasets: [
      {
        label: 'Total Sales',
        data: [], // Sales data (Y-axis values)
        borderColor: '#4e73df',  // Line color
        backgroundColor: '#3d4465', // Adjusted transparency for better visibility
        fill: true, // This enables the area under the line to be filled
        lineTension: 0.3, // Smooth out the curve (optional)
        // pointRadius: 0, // Remove points (optional)
        borderWidth: 1,  },
    ],
  });
   const navigate = useNavigate();
  const handleClose = () => {
    navigate('/show_invoice'); // Redirect to show_invoice page
  };

  
  useEffect(() => {
    // Fetch sales data from Django API
   
    fetch(`${config.base_url}sales-data/`) // Adjust the URL to match your backend setup
      .then((response) => response.json())
      .then((data) => {
        const labels = data.map((entry) => entry.month); // Extract month names
        const sales = data.map((entry) => entry.total_sales); // Extract total sales for each month
        
        setChartData((prevData) => ({
          ...prevData,
          labels: labels,
          datasets: [
            {
              ...prevData.datasets[0],
              data: sales, // Update the sales data in the chart
            },
          ],
        }));
      });
  }, []);
  return (
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper">
      <Sidebar />
      {/* <div className="main-panel">
          <div className="content-wrapper">
            <h3>Sales Invoice Graph</h3>
            <div style={{ height: '400px', width: '100%' }}>
              <Line data={chartData} />
            </div>
          </div>
        </div> */}
        <div className="main-panel" style={{ height: '100vh', width: '100vw' }}> {/* Full viewport */}
  <div 
    className="content-wrapper" 
    style={{ height: '100%', width: '100%', padding: '20px' ,position: 'relative' }}
  >
     <button 
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '8px 12px',
            backgroundColor: 'transparent', // Red color
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
         <i class="fa fa-times-circle fs-6 tb close_pad mt-4 text-dark" aria-hidden="true"></i>
        </button>
    <h3>Sales Invoice Graph</h3>
    <div style={{ height: '100%', width: '100%' }}> {/* Fill parent */}
      <Line data={chartData} />
    </div>
  </div>
</div>

      </div>
    </div>
  )
}
