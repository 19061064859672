import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';

import config from "../../functions/config";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom'; 

const Creditnote = () => {
  const [creditNotes, setCreditNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false)
  const [dateFilterVisible, setDateFilterVisible] = useState(false);
  const [panelWidth] = useState("100%");
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); 
  const [creditNoteNoFilterVisible, setCreditNoteNoFilterVisible] = useState(false);  // Separate state for Credit Note No filter
  const [filterCreditNoteNo, setFilterCreditNoteNo] = useState('');
  const [filterDate, setFilterDate] = useState('');
const [creditNoteNoFilter, setCreditNoteNoFilter] = useState('');
const [filterPartyName, setFilterPartyName] = useState('');
const [filterType, setFilterType] = useState('');
const [filterTotal, setFilterTotal] = useState('');
const [filterAction, setFilterAction] = useState(''); 
const [filterSiNo, setFilterSiNo] = useState('');  // State for SI NO filter
const [siNoFilterVisible, setSiNoFilterVisible] = useState(false); // Toggle visibility for SI NO filter // Added state for search term

  // Fetch debit notes data from API
  useEffect(() => {
    const fetchCreditNotes = async () => {
      const userId = Cookies.get('user_id');  // Assuming 'userId' is stored in the cookies
      
      if (!userId) {
        console.error("User ID not found in cookies");
        return;
      }

      try {
        const response = await axios.get(`${config.base_url}/api/creditlist/`, {
          params: { userId: userId } 
        });
        
        
        setCreditNotes(response.data);
        setFilteredNotes(response.data); // Initially show all debit notes
      } catch (error) {
        console.error("Error fetching debit notes:", error);
      }
    };

    fetchCreditNotes();
  }, []);

  // Filter debit notes when fromDate, toDate, or searchTerm changes
  useEffect(() => {
    let filtered = creditNotes;

    if (fromDate) {
      filtered = filtered.filter(note => new Date(note.date) >= new Date(fromDate));
    }

    if (toDate) {
      filtered = filtered.filter(note => new Date(note.date) <= new Date(toDate));
    }

   if (searchTerm) {
      filtered = creditNotes.filter(note =>
        note.party_name.toLowerCase().includes(searchTerm.toLowerCase()) ||  // Search by party name
        (note.retrn_no && String(note.retrn_no).toLowerCase().includes(searchTerm.toLowerCase())) || // Search by return no
        (note.date && note.date.toLowerCase().includes(searchTerm.toLowerCase())) ||  // Search by date
        (note.grandtotal && String(note.grandtotal).toLowerCase().includes(searchTerm.toLowerCase()))||
        (note.last_action && String(note.last_action).toLowerCase().includes(searchTerm.toLowerCase()))
         // Search by total amount
      );
}

    setFilteredNotes(filtered);
  }, [fromDate, toDate, creditNotes, searchTerm]);  // Add searchTerm to dependency array

  // Handle actions like view, edit, history, delete
  const handleActionClick = (id, action) => {
    switch (action) {
      case "view":
        navigate(`/viewcreditnote/${id}`); 
        break;
      case "edit":
        navigate(`/editcreditnote/${id}`); 
        break;
      case "history":
        navigate(`/credithistory/${id}`);
       
        break;
      case "delete":
        alert(`Delete debit note ${id}`);
        break;
      default:
        break;
    }
  };

  const totalAmount = filteredNotes.reduce((total, note) => total + note.grandtotal, 0);
  function printSection() {
    var divToPrint = document.getElementById("reportTable1");
    var printWindow = window.open("", "", "height=700,width=1000");
  
    // Add CSS for hiding buttons on print
    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
      <style>
        body {
          display: flex;
          justify-content: center;
          height: 100vh;
          margin: 0;
        }
        .centered-content {
          text-align: center;
          width: 100%;
        }
         .table-bordered {
        border: 1px solid #ddd !important;
      }
      .table th, .table td {
        border: 1px solid #ddd !important;
        padding: 8px 12px;
        text-align: left;
      }
      .table th {
        background-color: #f8f9fa;
      }
      .table tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
        /* Ensure the no-print class is respected in print view */
        @media print {
          .no-print {
            display: none !important;
          }
        }
      </style>
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  const handleActionDelete = async (id, action) => {
    switch (action) {
      case "view":
        alert(`View credit note ${id}`);
        break;
      case "edit":
        navigate(`/editcreditnote/${id}`);
        break;
      case "history":
        alert(`/credithistory/${id}`);
        break;
      case "delete":
        if (window.confirm("Are you sure you want to delete this credit note?")) {
          try {
            // const url = `http://localhost:8000/api/credit-notes/${id}/delete/`;
            const url = `${config.base_url}/api/credit-notes/${id}/delete/`;
            console.log("Request URL:", url);  // Debugging output
  
            const response = await fetch(url, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
            });
  
            // Check if the response is valid and returns a successful status
            if (response.ok) {
              setFilteredNotes(filteredNotes.filter(note => note.id !== id));
              alert("Credit note deleted successfully.");
            } else {
              const errorResponse = await response.json();  // Attempt to parse the response as JSON
              alert(`Failed to delete credit note: ${errorResponse.message || 'Unknown error'}`);
            }
          } catch (error) {
            console.error('Error deleting credit note:', error);
            alert("Error deleting credit note.");
          }
        }
        break;
    }
  };

  function ExportToExcel() {
    var st = fromDate;
    var en = toDate;
    var date = "";
    if (st && en) {
        date = `_${fromDate}` + "_" + `${toDate}`;
    }

    const Table = document.getElementById("reportTable");

    // Clone the rows and remove the last column (button column) for export
    const rows = Array.from(Table.rows);
    rows.forEach(row => {
        // Remove the last cell (the column containing buttons)
        row.deleteCell(row.cells.length - 1);  // This removes the last column (buttons column)
    });

    // Convert the modified table (without the buttons column) to a worksheet
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();

    // Set column widths (you can adjust these as necessary)
    const colWidth = [{ wch: 20 }, { wch: 20 }, { wch: 30 }]; // Adjust widths as needed
    ws['!cols'] = colWidth;

    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the Excel file
    XLSX.writeFile(wb, "Credittnote" + date + ".xlsx");
}

const toggleDateFilterDropdown = () => {
  setDateFilterVisible(!dateFilterVisible); // Toggle Date filter visibility
  setCreditNoteNoFilterVisible(false); // Hide other filter dropdowns
  setPartyNameFilterVisible(false);
  setTypeFilterVisible(false);
  setTotalFilterVisible(false);
  setActionFilterVisible(false);
};

const toggleCreditNoteNoFilterDropdown = () => {
  setCreditNoteNoFilterVisible(!creditNoteNoFilterVisible); // Toggle Credit Note No filter visibility
  setDateFilterVisible(false); // Hide the Date filter dropdown
  setPartyNameFilterVisible(false);
  setTypeFilterVisible(false);
  setTotalFilterVisible(false);
  setActionFilterVisible(false);
  setSiNoFilterVisible(false);
};
const toggleSiNoFilterDropdown = () => {
  setSiNoFilterVisible(!siNoFilterVisible); // Toggle SI NO filter visibility
  // Hide other filters
  setDateFilterVisible(false);
  setCreditNoteNoFilterVisible(false);
  setPartyNameFilterVisible(false);
  setTypeFilterVisible(false);
  setTotalFilterVisible(false);
  setActionFilterVisible(false);
};


  const handleFilterDateChange = (e) => {
    setFilterDate(e.target.value);
  };
  
  const handleFilterCreditNoteNoChange = (e) => {
    setFilterCreditNoteNo(e.target.value);
  };

  const handleFilterPartyNameChange = (e) => {
    setFilterPartyName(e.target.value);
  };
  
  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };
  
  const handleFilterTotalChange = (e) => {
    setFilterTotal(e.target.value);
  };

  const handleFilterActionChange = (e) => {
    setFilterAction(e.target.value);
  };
  const handleFilterSiNoChange = (e) => {
    setFilterSiNo(e.target.value);
  };
  const [partyNameFilterVisible, setPartyNameFilterVisible] = useState(false);
const [typeFilterVisible, setTypeFilterVisible] = useState(false);
const [totalFilterVisible, setTotalFilterVisible] = useState(false);
const [actionFilterVisible, setActionFilterVisible] = useState(false);

const togglePartyNameFilterDropdown = () => {
  setPartyNameFilterVisible(!partyNameFilterVisible);
  setDateFilterVisible(false);
  setCreditNoteNoFilterVisible(false);
  setTypeFilterVisible(false);
  setTotalFilterVisible(false);
  setActionFilterVisible(false);
  setSiNoFilterVisible(false);
};

const toggleTypeFilterDropdown = () => {
  setTypeFilterVisible(!typeFilterVisible);
  setDateFilterVisible(false);
  setCreditNoteNoFilterVisible(false);
  setPartyNameFilterVisible(false);
  setTotalFilterVisible(false);
  setActionFilterVisible(false);
  setSiNoFilterVisible(false);
};
const toggleTotalFilterDropdown = () => {
  setTotalFilterVisible(!totalFilterVisible);
  setDateFilterVisible(false);
  setCreditNoteNoFilterVisible(false);
  setPartyNameFilterVisible(false);
  
  setActionFilterVisible(false);
  setSiNoFilterVisible(false);
};
const toggleActionFilterDropdown = () => {
  setActionFilterVisible(!actionFilterVisible);
  setDateFilterVisible(false);
  setCreditNoteNoFilterVisible(false);
  setPartyNameFilterVisible(false);
  setTotalFilterVisible(false);
  
  setSiNoFilterVisible(false);
};

  
  
  const handleApplyFilter = () => {
    let filtered = creditNotes;
  
    // Filter by Date if filterDate is set
    if (filterDate) {
      filtered = filtered.filter(note => {
        const noteDate = new Date(note.date).toISOString().split('T')[0];  // Format to 'YYYY-MM-DD'
        return noteDate === filterDate;
      });
    }
  
    if (filterCreditNoteNo) {
      console.log("Applying Credit Note No filter with value:", filterCreditNoteNo); // Debugging output
      filtered = filtered.filter(note => {
        const creditNoteNumber = note.retrn_no ? note.retrn_no.toString().trim().toLowerCase() : '';  // Ensure it's a string
        const filterValue = filterCreditNoteNo.trim().toLowerCase();  // Normalize filter input
  
        // Debugging logs to check both values
        console.log("Credit Note Number:", creditNoteNumber);
        console.log("Filter Value:", filterValue);
  
        return creditNoteNumber.includes(filterValue);  // Use includes for partial matching
      });
    }
    if (filterPartyName) {
      filtered = filtered.filter(note => {
        const partyName = note.party_name ? note.party_name.toString().trim().toLowerCase() : '';
        return partyName.includes(filterPartyName.toLowerCase());
      });
    }
  
    if (filterType) {
      filtered = filtered.filter(note => {
        const type = note.type ? note.type.toString().trim().toLowerCase() : '';
        return type.includes(filterType.toLowerCase());
      });
    }
    if (filterTotal) {
      filtered = filtered.filter(note => {
        const grandTotal = note.grandtotal ? note.grandtotal.toString().trim().toLowerCase() : '';
        return grandTotal.includes(filterTotal.toLowerCase());
      });
    }
    if (filterAction) {
      filtered = filtered.filter(note => {
        const last_action = note.last_action ? note.last_action.toString().trim().toLowerCase() : '';
        return last_action.includes(filterAction.toLowerCase());
      });
      
    }
    if (filterSiNo) {
      filtered = filtered.filter((note, index) => {
        return (index + 1).toString().includes(filterSiNo);  // Check if the index (SI NO) includes the filter value
      });
    }
    
    
    
  
    setFilteredNotes(filtered);
    setDateFilterVisible(false); // Close the filter dropdown after applying
    setCreditNoteNoFilterVisible(false); // Close the filter dropdown after applying
    setPartyNameFilterVisible(false);
  setTypeFilterVisible(false);
  setTotalFilterVisible(false);
  setActionFilterVisible(false);
  setSiNoFilterVisible(false);
  };
  

  const handleClearFilter = () => {
    setFilteredNotes(creditNotes); // Reset to show all data
    setDateFilterVisible(false); // Close the filter dropdown
    setCreditNoteNoFilterVisible(false); // Close the filter dropdown
    setPartyNameFilterVisible(false);
    setTypeFilterVisible(false);
    setTotalFilterVisible(false);
    setActionFilterVisible(false);
  };
  const dropdownStyle = {
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    marginTop: '5px',
    zIndex: 1050, // Higher z-index to prevent interference
    minWidth: '200px', // Ensures enough space for input fields
    maxWidth: '300px', // Prevents dropdown from being too wide
    overflow: 'auto', // Prevents content from spilling out
  };
  
  const btnStyle = {
    backgroundColor: "rgb(61, 68, 101)",
    padding: '6px 12px',
    color: 'white',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    textAlign: 'center',
  };
  
  








  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />

        <div
          className="main-panel p-3"
          style={{ background: "#8290c7", width: panelWidth }}
        >
          <div className="content-wrapper">
            <div
              className="body-wrapper p-1 p-sm-3"
              style={{ minHeight: "100vh" }}
            >



              {/* First Section */}
              <div
                className="card radius-15 pt-4 shadow-lg"
                style={{ background: "#3d4465" }}
              >
              

             

                <div className="row ">
                  <div className="col-12  flex justify-content-center">
                    <div className="container-fluid">
                      {/* First row with two columns: left for date inputs and right for images */}
                      <div className="row mb-4 align-items-center" style={{ marginTop: "20px", backgroundColor: "#3d4465", height: "auto", padding: "20px", }}>
              <div className="col-md-4 col-12 mb-3 d-flex flex-column align-items-start">
  <span className="text-white" style={{ marginBottom: "8px", fontSize: "14px" }}>From Date</span>
  <input
    type="date"
    id="fromDate"
    className="form-control"
    value={fromDate}
    onChange={(e) => setFromDate(e.target.value)}
    style={{ width: "100%" }}
    placeholder="Select From Date"
  />
</div>
<div className="col-md-4 col-12 mb-3 d-flex flex-column align-items-start">
  <span className="text-white" style={{ marginBottom: "8px", fontSize: "14px" }}>To Date</span>
  <input
    type="date"
    id="toDate"
    className="form-control"
    value={toDate}
    onChange={(e) => setToDate(e.target.value)}
    style={{ width: "100%" }}
    placeholder="Select To Date"
  />
</div>
             
<div className="col-md-4 col-12 text-md-right text-center mt-3 mt-md-0">
                  <button className="btn btn-light bg-white mb-2" onClick={ExportToExcel} style={{ marginRight: "10px" }}>
                    <i className="bi bi-file-earmark-excel mr-2" ></i> Excel
                  </button>
                  <button className="btn btn-light bg-white mb-2" onClick={printSection}>
                    <i className="bi bi-printer mr-2"></i> Print
                  </button>
                </div>
              </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* Second Section */}
              <div 
                className="card radius-15 mt-4 shadow-lg"
                style={{ background: "#3d4465" }}
              >
                <div className="card-body">
                  <div className="row ">
                    <div className="col">
                      <div className="container-fluid">
                      <div className="col-sm-12 col-lg-3"><h4 className="tb fw-bolder pt-55 text-white" >CREDIT NOTE</h4></div>
<div className="d-flex flex-column flex-md-row justify-content-between w-100 mb-3 no-print">
  {/* Search input */}
  <div className="mb-3 mb-md-0">
    <input 
      type="text" 
      className="form-control" 
      placeholder="Search Credit Note" 
      style={{ maxWidth: "300px" }} 
      value={searchTerm} 
      onChange={(e) => setSearchTerm(e.target.value)}  // Update search term as user types
    />
  </div>
 
  {/* Add Debit Note button */}
  <button className="btn btn-light bg-white mt-2 mt-md-0">
    <i className="bi bi-plus"></i> 
    <Link to="/add_credit_note" className="text-decoration-none text-dark">
    Add Credit Note
    </Link>
  </button>
</div>
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                </div>

                          {/* Fix table  */}
                          <div id="reportTable1"
                            className="table-container  "
                            style={{ position: "relative",background: '#ffffff' }}
                          >
                            <div className="table-responsive ">
                            <table className="table border-bottom"  id="reportTable" style={{width:"100%"}}>
                    <thead style={{ backgroundColor: "#3d4465", color: "white"}}>
                      <tr>
                      <th style={{backgroundColor:"#3d4465",color:"white"}}>
                        SI NO
                        <a href="#" onClick={toggleSiNoFilterDropdown}>
                        <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
                        </a>
                        {siNoFilterVisible && (
                          <div className="dropdown-content" style={dropdownStyle}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>
                              Equal To
                            </div>
                            <input
                              type="text"
                              value={filterSiNo}
                              onChange={handleFilterSiNoChange}
                              style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
                              placeholder="Enter SI NO"
                            />
                            <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                              <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
                              <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
                            </div>
                          </div>
                        )}
                      </th>
                        <th style={{backgroundColor:"#3d4465",color:"white"}}>
  DATE
  <a href="#" onClick={toggleDateFilterDropdown}>
    <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
  </a>
  {dateFilterVisible && (
    <div className="dropdown-content" style={dropdownStyle}>
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>Equal To</div>
      {/* <input type="date" id="filterInput0" style={{ width: '100%', padding: '5px', marginBottom: '10px' }} /> */}
      <input
  type="date"
  id="filterInput0"
  value={filterDate}
  onChange={handleFilterDateChange}
  style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
/>
      <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
        <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
      </div>
    </div>
  )}
</th>

                        
                       <th style={{backgroundColor:"#3d4465",color:"white"}}>
                       CREDIT NOTE NO
  <a href="#" onClick={toggleCreditNoteNoFilterDropdown}>
    <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
  </a>
  {creditNoteNoFilterVisible && (
    <div className="dropdown-content" style={dropdownStyle}>
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>Equal To</div>
      <input
  type="text"
  id="filterInput1"
  value={filterCreditNoteNo}
  onChange={handleFilterCreditNoteNoChange}
  style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
  placeholder="Enter Credit Note No"
/>
      <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
        <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
      </div>
    </div>
  )}
</th>       
                        <th style={{backgroundColor:"#3d4465",color:"white"}}>
  PARTY NAME
  <a href="#" onClick={togglePartyNameFilterDropdown}>
    <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
  </a>
  {partyNameFilterVisible && (
    <div className="dropdown-content" style={dropdownStyle}>
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>Equal To</div>
      <input
        type="text"
        value={filterPartyName}
        onChange={handleFilterPartyNameChange}
        style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
        placeholder="Enter Party Name"
      />
      <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
        <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
      </div>
    </div>
  )}
</th>

<th style={{backgroundColor:"#3d4465",color:"white"}}>
  TYPE
  <a href="#" onClick={toggleTypeFilterDropdown}>
    <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
  </a>
  {typeFilterVisible && (
    <div className="dropdown-content" style={dropdownStyle}>
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>Equal To</div>
      <input
        type="text"
        value={filterType}
        onChange={handleFilterTypeChange}
        style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
        placeholder="Enter Type"
      />
      <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
        <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
      </div>
    </div>
  )}
</th>

<th style={{backgroundColor:"#3d4465",color:"white"}}>
  TOTAL
  <a href="#" onClick={toggleTotalFilterDropdown}>
    <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
  </a>
  {totalFilterVisible && (
    <div className="dropdown-content" style={dropdownStyle}>
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>Equal To</div>
      <input
        type="text"
        value={filterTotal}
        onChange={handleFilterTotalChange}
        style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
        placeholder="Enter total"
      />
      <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
        <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
      </div>
    </div>
  )}
</th>

<th style={{backgroundColor:"#3d4465",color:"white"}}>
  ACTION
  <a href="#" onClick={toggleActionFilterDropdown}>
    <i className="fa fa-filter" aria-hidden="true" style={{ color: 'white' }}></i>
  </a>
  {actionFilterVisible && (
   <div 
   className="dropdown-content" 
   style={{
     ...dropdownStyle, 
     left: '100', // Aligns dropdown to the left of the column
     transform: 'translateX(-50%)', // Centers it under the filter icon
   }}
 >
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginBottom: '10px' }}>Equal To</div>
      <input
        type="text"
        value={filterAction}
        onChange={handleFilterActionChange}
        style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
        placeholder="Enter Action"
      />
      <div className="button-row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <a className="btn" style={btnStyle} onClick={handleClearFilter}>Clear</a>
        <a className="btn ms-1" style={btnStyle} onClick={handleApplyFilter}>Apply</a>
      </div>
    </div>
  )}
</th>                        <th style={{backgroundColor:"#3d4465",color:"white"}}></th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }}>
                      {filteredNotes.map((note, index) => (
                        <tr
                        key={note.id}
                        
                      >
                          <td   onClick={() => navigate(`/viewcreditnote/${note.id}`)}   style={{ cursor: "pointer" }}>{index + 1}</td>
                          <td  onClick={() => navigate(`/viewcreditnote/${note.id}`)}   style={{ cursor: "pointer" }}>{note.date}</td>
                          <td  onClick={() => navigate(`/viewcreditnote/${note.id}`)}   style={{ cursor: "pointer" }}>{note.retrn_no}</td>
                          <td  onClick={() => navigate(`/viewcreditnote/${note.id}`)}   style={{ cursor: "pointer" }}>{note.party_name}</td>
                          <td  onClick={() => navigate(`/viewcreditnote/${note.id}`)}   style={{ cursor: "pointer" }}>Credit Note</td>
                          <td  onClick={() => navigate(`/viewcreditnote/${note.id}`)}   style={{ cursor: "pointer" }}>{note.grandtotal}</td>
                          <td
  onClick={() => navigate(`/viewcreditnote/${note.id}`)}
  style={{ 
    cursor: "pointer", 
    color: note.last_action === "Created" ? "red" : "green" 
  }}
>
  {note.last_action}
</td>
                          <td>
  <div className="dropdown">
    <button className="btn btn-link no-print" data-toggle="dropdown">
      <i className="bi bi-three-dots-vertical"></i>
    </button>
    <div className="dropdown-menu no-print" style={{position:"absolute",zIndex:"1100"}}>
      <button className="dropdown-item" onClick={() => handleActionClick(note.id, "view")}>
        <i className="bi bi-eye" style={{ marginRight: "8px", color: "green" }}></i> View
      </button>
      <button className="dropdown-item" onClick={() => handleActionClick(note.id, "edit")}>
        <i className="bi bi-pencil-square" style={{ marginRight: "8px", color: "yellow" }}></i> Edit
      </button>
      <button className="dropdown-item" onClick={() => handleActionClick(note.id, "history")}>
        <i className="bi bi-clock-history" style={{ marginRight: "8px", color: "darkblue" }}></i> History
      </button>
      <button className="dropdown-item" onClick={() => handleActionDelete(note.id, "delete")}>
        <i className="bi bi-trash" style={{ marginRight: "8px", color: "red" }}></i> Delete
      </button>
    </div>
  </div>
</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  
                            </div>
                           
                            <p
                              className="text-dark "
                              style={{ fontSize: "1.5rem",
                               
                                padding:'15px',
                            fontWeight: 500,
                
                               }}
                            >
                              Total Amount :  {totalAmount.toFixed(2)}
                            </p>
                          </div>

                        </div>
              

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Creditnote;
